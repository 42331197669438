import dayjs from 'dayjs';
import { or, and, where, Timestamp } from 'firebase/firestore';
import { ShipmentStatus, EventPhaseKey, ParcelEventAnalyticsType } from '../interfaces';
import { orderedLastEventKeys, pendingStatuses } from './shipments.constants';

// Reference: https://docs.google.com/document/d/1sRGWro1XGUvHaAXCvCV9ChLlndRzeGuOMsVnQ8xT7eU/edit#heading=h.q5yswhm0sph
const LAST_VALID_EVENT_TYPE_PATH = 'parcel.last_valid_event.event_type_master_data';
const LAST_VALID_EVENT_PHASE_KEY = `${LAST_VALID_EVENT_TYPE_PATH}.event_phase.key`;
const LAST_VALID_EVENT_IS_FINAL_EVENT = `${LAST_VALID_EVENT_TYPE_PATH}.is_final_event`;
const LAST_VALID_EVENT_IS_FIXED_ADDRESS = `${LAST_VALID_EVENT_TYPE_PATH}.is_fixed_address`;
const LAST_EVENT_TYPE_PATH = 'parcel.last_event.event_type_master_data';
const LAST_EVENT_ANALYTICS = `${LAST_EVENT_TYPE_PATH}.analytics`;
const LAST_EVENT_IS_FINAL_EVENT = `${LAST_EVENT_TYPE_PATH}.is_final_event`;
const LAST_EVENT_PHASE_KEY = `${LAST_EVENT_TYPE_PATH}.event_phase.key`;

export const EXCLUDE_DELETED_SHIPMENTS_QUERY = where('is_deleted', '==', false);

export const PENDING_SHIPMENTS_QUERY = or(
  where('shipment_status', '==', ShipmentStatus.Pending),
  where('search_status', 'in', pendingStatuses),
);

// Ordered & Cancelled shipment queries must use last_event, because last_valid_event ignores
// event that are in "Others" phase
export const ORDERED_SHIPMENTS_QUERY = or(
  and(
    where(LAST_EVENT_PHASE_KEY, 'in', orderedLastEventKeys),
    // to filter out Cancelled shipments
    where(LAST_EVENT_ANALYTICS, 'in', [ParcelEventAnalyticsType.Regular, ParcelEventAnalyticsType.Delay]),
  ),
  // open shipments
  where('parcel.parcel_id', '==', null),
);

export const CANCELLED_SHIPMENTS_QUERY = and(
  where(LAST_EVENT_ANALYTICS, '==', ParcelEventAnalyticsType.Exception),
  where(LAST_EVENT_IS_FINAL_EVENT, '==', true),
);

export const IN_TRANSIT_SHIPMENTS_QUERY = where(LAST_VALID_EVENT_PHASE_KEY, 'in', [
  EventPhaseKey.Pickup,
  EventPhaseKey.Customs,
  EventPhaseKey.Transit,
  EventPhaseKey.Others,
]);

export const OUT_FOR_DELIVERY_SHIPMENTS_QUERY = or(
  and(
    where(LAST_VALID_EVENT_PHASE_KEY, '==', EventPhaseKey.OutForDelivery),
    where(LAST_VALID_EVENT_IS_FIXED_ADDRESS, '==', false),
  ),
  and(
    where(LAST_VALID_EVENT_PHASE_KEY, '==', EventPhaseKey.Delivery),
    where(LAST_VALID_EVENT_IS_FINAL_EVENT, '==', false),
    where(LAST_VALID_EVENT_IS_FIXED_ADDRESS, '==', false),
  ),
);

export const TO_COLLECT_SHIPMENTS_QUERY = or(
  and(
    where(LAST_VALID_EVENT_PHASE_KEY, '==', EventPhaseKey.OutForDelivery),
    where(LAST_VALID_EVENT_IS_FIXED_ADDRESS, '==', true),
  ),
  and(
    where(LAST_VALID_EVENT_PHASE_KEY, '==', EventPhaseKey.Delivery),
    where(LAST_VALID_EVENT_IS_FINAL_EVENT, '==', false),
    where(LAST_VALID_EVENT_IS_FIXED_ADDRESS, '==', true),
  ),
);

// Delivered includes Delivered and Collected Shipments
export const DELIVERED_SHIPMENTS_QUERY = or(
  // Delivered
  and(
    where(LAST_VALID_EVENT_PHASE_KEY, '==', EventPhaseKey.Delivery),
    where(LAST_VALID_EVENT_IS_FINAL_EVENT, '==', true),
    where(LAST_VALID_EVENT_IS_FIXED_ADDRESS, '==', false),
  ),
  // Collected
  and(
    where(LAST_VALID_EVENT_PHASE_KEY, '==', EventPhaseKey.Delivery),
    where(LAST_VALID_EVENT_IS_FIXED_ADDRESS, '==', true),
  ),
);

export const RETURNED_SHIPMENTS_QUERY = where(LAST_VALID_EVENT_PHASE_KEY, '==', EventPhaseKey.ReturnToSender);

export const EDD_DATE_RANGE_QUERY = or(
  // Start of today
  where('estimated_delivery_date', '>=', Timestamp.fromDate(dayjs().startOf('day').toDate())),
  where('estimated_delivery_date', '==', ''),
);

export const EDD_DELIVERED_SHIPMENTS_QUERY = or(DELIVERED_SHIPMENTS_QUERY, RETURNED_SHIPMENTS_QUERY);

const notDeliveryOrReturnToSender = Object.values(EventPhaseKey).filter(
  (key) => ![EventPhaseKey.Delivery, EventPhaseKey.ReturnToSender].includes(key),
);

// Must exclude "Delivery" or "Return to Sender" shipments because EDD_DELIVERED_SHIPMENTS_QUERY
// already fetched those. Doing the same thing again will cause overlap in Delivered Shipments dropdown
// and Pending Delivery Date section
export const EDD_ALL_SHIPMENTS_QUERY = or(
  where(LAST_VALID_EVENT_PHASE_KEY, 'in', notDeliveryOrReturnToSender),
  where('parcel.parcel_id', '==', null),
);
