import { Breadcrumbs, Container, PageOverviewBanner, RightSection } from '@/components';
import { ContentTypeName } from '@/constants';
import { type IRetailer, Widget } from '@/interfaces';
import { capitalize } from '@/utils';
import SectionListRenderer from '../SectionListRenderer';
import { LeftSection } from '../SectionListRenderer.styles';
import { CustomSectionListWrapper } from './RetailerPageRenderer.styles';

const FULL_WIDTH_SECTION_LIST = [ContentTypeName.SeoSection, ContentTypeName.LeadsWrapper];

interface IRetailerPageRendererProps {
  pageTitle?: IRetailer['name'];
  sectionList?: IRetailer['sectionList'];
  rightSectionList: IRetailer['rightSectionList'];
  breadcrumbsTheme?: IRetailer['breadcrumbsTheme'];
}

const RetailerPageRenderer: React.FC<IRetailerPageRendererProps> = ({
  pageTitle = '',
  sectionList = [],
  rightSectionList,
  breadcrumbsTheme,
}) => {
  const foundPageOverviewBanner = sectionList.find(
    (section: any) => capitalize(section.__typename) === ContentTypeName.PageOverviewBanner,
  );
  const isBannerWithInfoBoard = foundPageOverviewBanner?.widget === Widget.InfoBoard;

  const sections = sectionList?.reduce<{
    fullWidthSectionList: any[];
    leftSectionList: any[];
  }>(
    (result, section) => {
      const contentType = capitalize(section.__typename) as ContentTypeName;
      if (section === foundPageOverviewBanner) return result;

      const isFullWidthSection = FULL_WIDTH_SECTION_LIST.includes(contentType);

      if (isFullWidthSection) {
        result.fullWidthSectionList.push(section);
      } else {
        result.leftSectionList.push(section);
      }

      return result;
    },
    {
      fullWidthSectionList: [],
      leftSectionList: [],
    },
  );

  return (
    <>
      {foundPageOverviewBanner && (
        <>
          <PageOverviewBanner priority {...foundPageOverviewBanner} />
          <Breadcrumbs title={pageTitle} breadcrumbsTheme={breadcrumbsTheme} hasContainer isFixed />
        </>
      )}
      <Container>
        <CustomSectionListWrapper hideSectionBorderTop={isBannerWithInfoBoard}>
          <LeftSection>
            {!foundPageOverviewBanner && <Breadcrumbs title={pageTitle} breadcrumbsTheme={breadcrumbsTheme} />}
            <SectionListRenderer sectionList={sections.leftSectionList} />
          </LeftSection>
          {rightSectionList && <RightSection rightSectionList={rightSectionList} />}
        </CustomSectionListWrapper>
      </Container>

      <SectionListRenderer sectionList={sections.fullWidthSectionList} />
    </>
  );
};

export default RetailerPageRenderer;
