import type { Asset, ButtonProps, SectionThemeKey, Widget } from '@/interfaces';

export interface IPageOverviewBanner {
  sys: { id: string };
  title: string;
  titleMobile: string;
  subTitle?: string;
  subText?: string;
  subTextMobile?: string;
  buttons?: ButtonProps[] | null;
  theme?: SectionThemeKey;
  image?: Asset;
  imageMobile?: Asset;
  loginTitle?: string;
  loginMobileTitle?: string;
  loginSubtext?: string;
  priority?: boolean;
  widget?: Widget;
}

export interface IBannerWithInfoBoard extends IPageOverviewBanner {
  logo?: string;
  description?: string;
  boardDetails?: IBoardDetail[];
}

export enum BoardDetailType {
  Text = 0,
  Link = 1,
}

export interface IBoardDetail {
  name: string;
  value: string;
  displayText?: string; // for BoardDetailType.Links
  type: BoardDetailType;
}
