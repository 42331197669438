import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useGlobalState } from '@/hooks';
import { useFetchShipments } from './useFetchShipments';
import {
  deleteLocalStorageByKey,
  getLocalStorageByKey,
  gtmParcelSearches,
  trackingParamExtractionModify,
} from '@/utils';
import { ParcelSearchesSource, TRACKING_PARAM_PARCEL_ID, TRACKING_PARAM_PARCEL_IDS } from '@/constants';
import { checkPmtEmailQueryValid, checkPmtSingleQueryParamValid } from '@/modules/pmt/utils';

export const useSearchShipments = () => {
  const { firestoreUuid } = useGlobalState();
  const { query: routerQuery } = useRouter();
  const { fetchShipments } = useFetchShipments();

  const inputParcelList = (parcelIdList: string[], source: ParcelSearchesSource) => {
    if (!parcelIdList.length || !firestoreUuid) return;

    // Trigger gtmParcelSearches when input parcels from AMP, PMC or PMT tracking widget
    if (source !== ParcelSearchesSource.EMAIL) {
      gtmParcelSearches(parcelIdList.length, source);
    }

    const parcelIdsToFind: string[] = [];

    parcelIdList.forEach((trackingId) => parcelIdsToFind.push(trackingId));

    fetchShipments(parcelIdsToFind);
    deleteLocalStorageByKey(TRACKING_PARAM_PARCEL_ID);
    deleteLocalStorageByKey(TRACKING_PARAM_PARCEL_IDS);
  };

  const { shipmentId = '' } = routerQuery;
  const modifiedRouterQuery = { ...routerQuery };
  const pParcelId = getLocalStorageByKey(TRACKING_PARAM_PARCEL_ID);
  const pParcelIds = getLocalStorageByKey(TRACKING_PARAM_PARCEL_IDS);
  if (pParcelId) {
    modifiedRouterQuery.pParcelId = pParcelId;
  }
  if (pParcelIds) {
    modifiedRouterQuery.pParcelIds = pParcelIds;
  }

  useEffect(() => {
    if ((!pParcelIds && !shipmentId && !pParcelId) || !firestoreUuid) return;

    switch (true) {
      case checkPmtEmailQueryValid(modifiedRouterQuery, shipmentId):
        inputParcelList([shipmentId as string], ParcelSearchesSource.EMAIL);
        break;
      case checkPmtSingleQueryParamValid(modifiedRouterQuery, pParcelIds):
        inputParcelList(trackingParamExtractionModify(pParcelIds as string), ParcelSearchesSource.AMP);
        break;
      case checkPmtSingleQueryParamValid(modifiedRouterQuery, pParcelId as string): {
        inputParcelList(trackingParamExtractionModify(pParcelId as string), ParcelSearchesSource.PMC);
        break;
      }
      default:
        break;
    }
  }, [firestoreUuid]);

  useEffect(() => {
    return () => {
      // Remove pParcelId, pParcelIds in localStorage when unmount
      deleteLocalStorageByKey(TRACKING_PARAM_PARCEL_ID);
      deleteLocalStorageByKey(TRACKING_PARAM_PARCEL_IDS);
    };
  }, []);

  return { inputParcelList };
};
