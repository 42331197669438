import { ButtonFunctionKey } from '@/interfaces';
import ArrowForward from '../public/images/icons/arrow_forward.svg';
import Download from '../public/images/icons/download.svg';

export const ButtonIconByFunction: {
  [key in ButtonFunctionKey]: any;
} = {
  [ButtonFunctionKey.Download]: Download,
  [ButtonFunctionKey.PopUp]: ArrowForward,
  [ButtonFunctionKey.Link]: null,
  [ButtonFunctionKey.Email]: null,
};
