import { LanguageCode } from '@/interfaces';
import type { IPmtPage } from '@/modules/pmt/interfaces';
import { sectionListModify } from '@/utils';
import callContentful from './base.fetchers';

type PmtPage = (args: {
  isPreviewMode: boolean;
  languageCode: LanguageCode;
}) => Promise<IPmtPage | null>;

export const getPmtPage: PmtPage = async ({ isPreviewMode, languageCode }) => {
  const query = `
    {
      pmtPageCollection(
        preview: ${isPreviewMode}
        where: {
          languageCode: "${languageCode}"
        }
        limit: 1
      ) {
        items {
          sys {
            id
          }
          sectionList: sectionListCollection(
            preview: ${isPreviewMode}
            limit: 1
          ) {
            items {
              __typename
              ... on SeoSection {
                title
                seoList
                width
                longForm {
                  json
                }
              }
            }
          }
          seo {
            title
            description
            ogType
            ogUrl
            ogImage {
              contentType
              url
            }
            ogTitle
            ogDescription
            robots
          }
        }
      }
    }
  `;

  const slug = '/' + `${languageCode === LanguageCode.English ? '' : '/'}` + 'track-my-parcel';
  const response = await callContentful(query, isPreviewMode, slug, 'pmtPage.fetchers.getPmtPage');
  const pmtPageData = response?.data?.pmtPageCollection?.items?.filter(Boolean)[0] || null;
  return pmtPageData ? sectionListModify(pmtPageData) : null;
};

export const getAllPmtPages = async (props: { isPreviewMode: boolean }) => {
  const { isPreviewMode } = props;
  const query = `
    {
      pmtPageCollection(
        preview: ${isPreviewMode}
      ) {
        items {
          sys {
            id
            publishedAt
          }
          languageCode
        }
      }
    }
  `;

  const response = await callContentful(
    query,
    isPreviewMode,
    '/track-my-parcel-sitemap.xml',
    'pmtPage.fetchers.getAllPmtPages',
  );
  return response?.data?.pmtPageCollection?.items?.filter(Boolean) || null;
};
