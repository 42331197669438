import styled, { css } from 'styled-components';
import { Typography } from '@/components';
import { LayoutHierarchy, TextStyles, theme } from '@/constants';
import { styledText } from '@/components/Typography/Typography.styles';
import ArrowDown from '../../public/images/icons/arrow_down.svg';

const INPUT_HEIGHT = 56;
const OPTION_HEIGHT = 44;
const MAX_OPTIONS_DISPLAYED = 5;
const MAX_DROPDOWN_HEIGHT = OPTION_HEIGHT * MAX_OPTIONS_DISPLAYED;

export const ArrowIcon = styled(ArrowDown).withConfig({
  shouldForwardProp: (props) => props !== 'show',
})`
  transition: 0.1s;

  ${({ show }) =>
    show &&
    css`
      transform: rotate(180deg);
      margin-right: -1px;
    `}

  path {
    fill: ${theme.colors.inactiveBlack};
  }
`;

export const SelectWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const InputWrapper = styled.div`
  cursor: text;
  border: 1px solid ${theme.colors.pressedBlack};
  height: ${INPUT_HEIGHT}px;
  padding: 0 ${theme.spacing[16]};
  overflow-x: hidden;
  overflow-x: visible;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
`;

export const Input = styled.input`
  border: none;
  outline: none;
  width: 100%;
  height: 100%;
  background: transparent;
  padding: 0;
  padding-right: ${theme.spacing[4]};
  color: ${theme.colors.highEmphasisBlack};
  ${styledText['Body Text Large']}

  :not(:focus) {
    ::placeholder {
      color: ${theme.colors.highEmphasisBlack};
    }
  }
`;

export const Option = styled.div<{ $selected: boolean }>`
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0 ${theme.spacing[16]};
  color: ${theme.colors.highEmphasisBlack};
  ${styledText['Body Text Small']}
  height: ${OPTION_HEIGHT}px;
  display: flex;
  align-items: center;

  :hover {
    background-color: ${theme.colors.surface02};
  }

  ${({ $selected }) => $selected && styledText['Heading 4']}
`;

export const Dropdown = styled.div`
  position: absolute;
  top: ${INPUT_HEIGHT}px;
  left: 0;
  background: ${theme.colors.highEmphasisWhite};
  border-radius: 4px;
  max-height: ${MAX_DROPDOWN_HEIGHT}px;
  overflow: auto;
  width: 100%;
  margin-top: ${theme.spacing[4]};
  box-shadow: ${theme.elevations[3]};
  z-index: ${LayoutHierarchy.Fifth};

  > ${Option}:not(:last-child) {
    border-bottom: 1px solid ${theme.colors.pressedBlack};
  }
`;

export const NoResultsFound = styled(Typography).attrs({
  variant: TextStyles['Body Text Small'],
})`
  text-align: center;
  padding: ${theme.spacing[12]};
  color: ${theme.colors.mediumEmphasisBlack};
`;
