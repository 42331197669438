export const rightSectionList = (isPreviewMode: boolean): string => {
  return `
    rightSectionList: rightSectionListCollection(limit: 7, preview: ${isPreviewMode}) {
      items {
        sys {
          id
        }
        title
        type
        button {
          primaryButtonText
          secondaryButtonText
          function
          color
          email
          buttonLink
          openInNewTab
          triggerPopUp
        }
        list: listCollection(limit: 5, preview: ${isPreviewMode}) {
          items {
            __typename
            ... on Article {
              title
              slug
              articleType
              date
              image {
                url(transform: {
                  width: 468,
                  resizeStrategy: FIT,
                })
                description
                contentType
              }
              seo {
                description
              }
            }
            ... on Carrier {
              carrierDisplayName
              slug
              featured
              carrierLogo {
                url(transform: {
                  width: 114,
                  resizeStrategy: FIT,
                })
                description
                contentType
              }
            }
            ... on LeadGenBanner {
              title
              image {
                contentType
                url(transform: {
                  width: 528,
                  resizeStrategy: FIT,
                })
                description
              }
              subCopy
              buttons: buttonsCollection(limit: 3, preview: ${isPreviewMode}) {
                items {
                  sys {
                    id
                  }
                  primaryButtonText
                  secondaryButtonText
                  function
                  color
                  email
                  buttonLink
                  buttonEventLabel
                  openInNewTab
                }
              }
              sectionTheme: theme
              isLinkedInBanner
            }
          }
        }
      }
    }
  `;
};

export const marketMinifiedQuery = (): string => {
  return `
    sys {
      id
    }
    slug
    marketName
    subTitle
    countryCode
    region
  `;
};

export const tagsAndMarketsQuery = (): string => {
  return `
    __typename
    ... on Tag {
      sys { id }
      title
      type
      slug
      category
    }
    ... on Market {
      ${marketMinifiedQuery()}
    }
  `;
};

export const thumbnailSectionQuery = (limit = 5): string => {
  return `
    mostRead
    itemType
    articleType
    containsMarketTag
    buttonLink
    tags: tagsCollection(limit: ${limit}) {
      items {
        __typename
        sys {
          id
        }
        title
        type
        slug
      }
    }
  `;
};
