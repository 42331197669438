import type React from 'react';
import { useState } from 'react';
import { PageWithGraphic } from '@/components';
import { getPmtLangHref, gtmClickUnsubAllConfirm } from '@/utils';
import { useGlobalState } from '@/hooks';
import { MasterTranslation } from '@/constants';
import { unsubscribeAllShipments } from '@/fetchers';
import { UnsubscribeAllWrapper, PageTitle, UnsubscribeButton, BackToMyParcelsButton } from './UnsubscribeAll.styles';

interface UnsubscribeAllProps {
  accessToken: string;
}

const UnsubscribeAll: React.FC<UnsubscribeAllProps> = ({ accessToken }) => {
  const { t, pmtCurrentLanguageCode, userInfo, setUserInfo } = useGlobalState();
  const unsubscribeTranslated = t(MasterTranslation.Unsubscribe);

  const [isConfirmed, setIsConfirmed] = useState<boolean>(false);

  const onUnsubscribeAllShipments = () => {
    gtmClickUnsubAllConfirm();
    unsubscribeAllShipments({ accessToken });
    setIsConfirmed(true);
    userInfo &&
      setUserInfo({
        ...userInfo,
        defaultOptIn: false,
      });
  };

  const mainText = isConfirmed
    ? t(MasterTranslation.UnsubAllSuccessMainText)
    : t(MasterTranslation.UnsubAllConfirmationMainText);

  const unsubscribeButton = unsubscribeTranslated && !isConfirmed && (
    <UnsubscribeButton onClick={onUnsubscribeAllShipments}>{unsubscribeTranslated}</UnsubscribeButton>
  );

  return (
    <UnsubscribeAllWrapper>
      <PageWithGraphic>
        <PageTitle>{mainText}</PageTitle>
        {unsubscribeButton}
        <BackToMyParcelsButton href={getPmtLangHref(pmtCurrentLanguageCode)}>
          {t(MasterTranslation.BackToMyParcels)}
        </BackToMyParcelsButton>
      </PageWithGraphic>
    </UnsubscribeAllWrapper>
  );
};

export default UnsubscribeAll;
