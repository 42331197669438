import styled from 'styled-components';
import { TextStyles } from '@/constants';
import { Button, Typography } from '@/components';

export const WallContainer = styled.div`
  width: 100%;
  max-width: 534px;
  position: relative;
  overflow: hidden;
  background: ${({ theme }) => theme.colors.darkBlue};
  box-shadow: ${({ theme }) => theme.elevations[2]};
  border-radius: 8px;
  padding: ${({ theme }) => theme.spacing[32]};
  color: ${({ theme }) => theme.colors.highEmphasisWhite};
  word-break: break-word;

  > div {
    position: relative;
    z-index: 1;
  }
`;

export const CustomButton = styled(Button)`
  margin: ${({ theme }) => theme.spacing[24]} auto 0 auto;
`;

export const SubTitle = styled(Typography).attrs({
  variant: TextStyles['Body Text Large'],
})`
  text-align: center;
  margin-top: ${({ theme }) => theme.spacing[16]};
`;
