import getConfig from 'next/config';
import { createClient, type EntryCollection, type Sys } from 'contentful';
import { sendSentryError } from '@/utils';
import { ContentTypeIds, ErrorMessages, MessageField, CategoryContentTypeIds } from '@/constants';
import type { IContentfulRawDataSys } from '@/interfaces/redis.interfaces';
import { getMinifiedCarrier } from '@/fetchers';

const { serverRuntimeConfig, publicRuntimeConfig } = getConfig();

type GetContentfulUpperEntries = (sys: IContentfulRawDataSys) => Promise<
  | {
      status: number;
      error: unknown;
    }
  | {
      status: number;
      data: EntryCollection<unknown>;
    }
>;

export const getContentfulUpperEntries: GetContentfulUpperEntries = async (sys) => {
  const contentfulClient = createClient({
    space: serverRuntimeConfig.contentfulSpace,
    accessToken: serverRuntimeConfig.contentDeliveryApiAccessToken,
    environment: publicRuntimeConfig.contentfulEnvironment,
  });

  try {
    const isAsset: boolean = sys.type === ContentTypeIds.Asset;
    const data = await contentfulClient.getEntries({
      [isAsset ? 'links_to_asset' : 'links_to_entry']: sys.id,
      include: 0,
    });

    return { status: 200, data };
  } catch (error) {
    const errorId = (<{ sys: Sys }>error).sys?.id;

    return {
      status: 400,
      error: errorId || ErrorMessages[MessageField.UNDEFINED_ERROR],
    };
  }
};

// Contentful Base Fetcher
const callContentful = async (query: string, isPreviewMode: boolean, pagePath?: string, position?: string) => {
  const ACCESS_TOKEN = isPreviewMode
    ? serverRuntimeConfig.contentPreviewApiAccessToken
    : serverRuntimeConfig.contentDeliveryApiAccessToken;
  const fetchUrl = `https://graphql.contentful.com/content/v1/spaces/${serverRuntimeConfig.contentfulSpace}/environments/${publicRuntimeConfig.contentfulEnvironment}`;
  const fetchOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${ACCESS_TOKEN}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ query }),
  };

  try {
    const res = await fetch(fetchUrl, fetchOptions);
    const data = await res.json();

    if (data.errors) {
      const errors = data.errors.filter((error: any) => !error.message.startsWith('Query execution error.'));
      const _errors = errors.map((error: any) => {
        delete error.extensions;
        return {
          ...error,
          pagePath,
          position,
        };
      });

      if (errors.length) console.error(_errors);
    }

    return data;
  } catch (err) {
    sendSentryError(err, { query, ['preview-mode']: isPreviewMode });
    return { data: null };
  }
};

export const callPublicContentful = async (
  query: string,
  // TODO: Khanh - update fetcher to use in preview mode
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  isPreviewMode: boolean,
) => {
  const fetchUrl = '/api/contentful';
  const fetchOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ query }),
  };

  const res = await fetch(fetchUrl, fetchOptions);
  const data = await res.json();

  return data;
};

type GetMinifiedCategoryData = (args: {
  isPreviewMode: boolean;
  contentType: CategoryContentTypeIds;
  id: string;
}) => Promise<any>;

export const getMinifiedData: GetMinifiedCategoryData = async ({ isPreviewMode, contentType, id }) => {
  switch (contentType) {
    case CategoryContentTypeIds.carrier:
      return getMinifiedCarrier({ isPreviewMode, id });
    default:
      return null;
  }
};

export default callContentful;
