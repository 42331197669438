import type React from 'react';
import { PageWithGraphic } from '@/components';
import { getPmtLangHref, renderHTML } from '@/utils';
import { useGlobalState } from '@/hooks';
import { MasterTranslation } from '@/constants';
import { UnsubscribeSingleWrapper, PageTitle, ViewMyParcelsButton } from './UnsubscribeSingle.styles';

interface IUnsubscribeSingle {
  parcelId: string;
}

const UnsubscribeSingle: React.FC<IUnsubscribeSingle> = ({ parcelId }) => {
  const { t, pmtCurrentLanguageCode } = useGlobalState();

  const renderPageTitle = () => {
    let pageTitle = t(MasterTranslation.UnsubSingleSuccessMainText);

    pageTitle = pageTitle.replace('{tracking_id}', `<span>${parcelId}</span>`);

    return renderHTML(pageTitle);
  };

  return (
    <UnsubscribeSingleWrapper>
      <PageWithGraphic>
        <PageTitle>{renderPageTitle()}</PageTitle>
        <ViewMyParcelsButton href={getPmtLangHref(pmtCurrentLanguageCode)}>
          {t(MasterTranslation.ViewMyParcels)}
        </ViewMyParcelsButton>
      </PageWithGraphic>
    </UnsubscribeSingleWrapper>
  );
};

export default UnsubscribeSingle;
