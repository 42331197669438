// Reference: https://parcelperform.atlassian.net/wiki/spaces/PM/pages/3127279699/Orion-18+Create+Update+Shipments+PMT+UI+Results
// UC 3a
import dayjs from 'dayjs';
import { type IShipmentData, EventPhaseKey } from '../interfaces';

const EDD_DISALLOWED_PHASES = [
  EventPhaseKey.OutForDelivery,
  EventPhaseKey.Delivery,
  EventPhaseKey.ReturnToSender,
] as string[];

export const getEdd = (trackingData?: IShipmentData) => {
  if (!trackingData || !trackingData.parcel) {
    return { start: undefined, end: undefined };
  }

  const {
    parcelExpectedTimeLatestStart,
    parcelExpectedTimeLatestEnd,
    parcelExpectedTimeFirstStart,
    parcelExpectedTimeFirstEnd,
  } = trackingData.parcel;

  if (parcelExpectedTimeLatestStart) {
    return {
      start: parcelExpectedTimeLatestStart,
      end: parcelExpectedTimeLatestEnd,
    };
  }

  if (parcelExpectedTimeFirstStart) {
    return {
      start: parcelExpectedTimeFirstStart,
      end: parcelExpectedTimeFirstEnd,
    };
  }

  const { expectedDelivery, expectedDeliveryTo } = trackingData;

  return {
    start: expectedDelivery,
    end: expectedDeliveryTo,
  };
};

export const isEddAvailable = (trackingData?: IShipmentData) => {
  if (!trackingData || !trackingData.parcel) return false;

  const { parcel } = trackingData;
  const latestEvent = parcel.lastEvent;
  const latestEventPhase = latestEvent?.phaseKey;

  if (latestEventPhase && EDD_DISALLOWED_PHASES.includes(latestEventPhase)) return false;

  const { parcelExpectedTimeLatestStart, parcelExpectedTimeFirstStart } = trackingData.parcel;

  const { expectedDelivery } = trackingData;

  if (!parcelExpectedTimeLatestStart && !parcelExpectedTimeFirstStart && !expectedDelivery) {
    return false;
  }

  const { start } = getEdd(trackingData);

  return !dayjs(start).isBefore(dayjs());
};

// Disclaimer: Valid for PP UTC format only
export const isEddUtc = (time?: string) => {
  if (!time || typeof time !== 'string') return false;

  return time.charAt(time.length - 1) === 'Z';
};
