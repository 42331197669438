import {
  type IPmtOnboardingPopup,
  type ITrackingLeftPanel,
  type ITrackingRightPanel,
  PmtSignUpStep,
  PmtOnboardingPopupCta,
} from './trackingOnboardingPopup.interfaces';
import type { GtmClickFilter, GtmClickParcel } from './gtm.interfaces';
import {
  ParcelEventAnalyticsType,
  ParcelStatus,
  EventPhaseKey,
  ShipmentStatus,
  ShipmentSearchStatus,
  ShipmentStage,
  type ICarrier,
  type ICarrierWithFirstFoundEvent,
  type IParcelEvent,
  type IOrderTracking,
  type IShipment,
  type IShipmentData,
  type IParcel,
  type IShipmentAdditionalInfo,
  type IShipmentLineItem,
  type ITrackingEventDateOptions,
  type ITrackingInput,
  type ICarrierByParcel,
  type INotiButtonStateByParcel,
} from './common.interfaces';
import {
  TrackingMilestone,
  CarrierDropdownState,
  type ITrackingProgressTracker,
  type IMilestoneCondition,
} from './trackingEventCard.interfaces';
import type { IMainTranslation, ITrackingTranslation, INotificationTranslation } from './translation.interfaces';
import { ShipmentSubscribeState } from './subscribe.interfaces';
import type { IEmailSyncAccount, IEmailAccount, TEmailAccountDTO, TListEmailSyncAccountsDTO } from './emailSync.interfaces';

export type {
  ICarrier,
  ICarrierWithFirstFoundEvent,
  IParcelEvent,
  IOrderTracking,
  IShipment,
  IShipmentData,
  IParcel,
  IShipmentAdditionalInfo,
  IShipmentLineItem,
  ITrackingProgressTracker,
  IMilestoneCondition,
  ITrackingEventDateOptions,
  ITrackingInput,
  ICarrierByParcel,
  INotiButtonStateByParcel,
  IMainTranslation,
  ITrackingTranslation,
  INotificationTranslation,
  GtmClickFilter,
  GtmClickParcel,
  IPmtOnboardingPopup,
  ITrackingLeftPanel,
  ITrackingRightPanel,
  IEmailSyncAccount,
  IEmailAccount,
  TEmailAccountDTO,
  TListEmailSyncAccountsDTO,
};

export {
  ParcelEventAnalyticsType,
  ParcelStatus,
  EventPhaseKey,
  ShipmentStatus,
  ShipmentSearchStatus,
  ShipmentStage,
  CarrierDropdownState,
  ShipmentSubscribeState,
  PmtSignUpStep,
  PmtOnboardingPopupCta,
  TrackingMilestone,
};
