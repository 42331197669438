import type { FC } from 'react';
import { type PMTAdType, pmtAdUnits } from '@/modules/pmt/constants';
import { useAdsContext } from '@/modules/pmt/context';
import Adsense from '@/components/Adsense';

type PMTAdsenseProps = { variant: PMTAdType; id?: string; className?: string };

export const PMTAdsense: FC<PMTAdsenseProps> = ({ variant, ...props }) => {
  const { currentAdSetup } = useAdsContext();

  if (!currentAdSetup || !pmtAdUnits[currentAdSetup][variant]) return null;

  return <Adsense {...props} dataAdSlot={pmtAdUnits[currentAdSetup][variant] as string} />;
};
