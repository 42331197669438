import styled from 'styled-components';
import { Container, Typography } from '@/components';
import { SectionThemeKey } from '@/interfaces';
import { TextStyles, theme } from '@/constants';
import { styledTextAsObj } from '../Typography/Typography.styles';

export const FullSectionContainer = styled(Container)(({ theme }) => ({
  padding: `${theme.spacing[40]} ${theme.spacing[20]}`,

  [`${theme.mediaBreakpointUp.md}`]: {
    padding: `${theme.spacing[64]} ${theme.spacing[80]}`,
  },
}));

export const SectionWrapper = styled.div(({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column-reverse',
  marginBottom: theme.spacing[20],

  [`${theme.mediaBreakpointUp.md}`]: {
    flexDirection: 'row',
    marginBottom: theme.spacing[40],
  },
}));

export const FormWrapper = styled.div(({ theme }) => ({
  padding: `${theme.spacing[22]} ${theme.spacing[20]}`,
  backgroundColor: theme.colors.highEmphasisWhite,
  borderRadius: 8,
  boxShadow: theme.elevations[4],
  marginTop: theme.spacing[20],

  [`${theme.mediaBreakpointUp.md}`]: {
    marginTop: 0,
  },
}));

const Title = styled(Typography).attrs({
  variant: TextStyles['Heading 1'],
})(({ theme }) => ({
  textAlign: 'center',

  [`${theme.mediaBreakpointUp.md}`]: {
    ...styledTextAsObj[TextStyles['Large Text Display']],
    textAlign: 'left',
  },
}));

export const MobileTitle = styled(Title)(({ theme }) => ({
  display: 'block',

  [`${theme.mediaBreakpointUp.md}`]: {
    display: 'none',
  },
}));

export const DesktopTitle = styled(Title)(({ theme }) => ({
  display: 'none',

  [`${theme.mediaBreakpointUp.md}`]: {
    display: 'block',
  },
}));

export const LeftSectionWrapper = styled.div({
  flex: 1,
});

export const RightSectionWrapper = styled.div(({ theme }) => ({
  flex: 1,

  [`${theme.mediaBreakpointUp.md}`]: {
    marginLeft: theme.spacing[80],
  },
}));

// `theme` is from @/constants, not from ThemeProvider
const sectionThemeToStyleMapperObj = {
  [SectionThemeKey.Dark]: {
    backgroundColor: theme.colors.darkBlue,
    [`${DesktopTitle}, ${MobileTitle}`]: {
      color: theme.colors.highEmphasisWhite,
    },
  },
  [SectionThemeKey.Light]: {
    backgroundColor: theme.colors.surface03,
    [`${DesktopTitle}, ${MobileTitle}`]: {
      color: theme.colors.darkBlue,
    },
  },
};

export const LeadsWrapperContainer = styled.div<{
  backgroundImage?: string;
  sectionTheme?: SectionThemeKey;
}>(({ theme, sectionTheme, backgroundImage }) => ({
  borderTop: `4px solid ${theme.colors.surface05}`,
  scrollMarginTop: theme.spacing[30],

  ...sectionThemeToStyleMapperObj[sectionTheme || SectionThemeKey.Light],

  [`${theme.mediaBreakpointUp.md}`]: {
    borderTop: 'none',
    backgroundImage: backgroundImage ? `url(${backgroundImage})` : 'none',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    scrollMarginTop: theme.spacing[60],
  },
}));
