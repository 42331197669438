import { ContentTypeName } from '@/constants';
import { type IRichText, LeadGenBannerVariant, type SectionThemeKey } from '@/interfaces';
import { capitalize } from '@/utils';

import { Container, PageOverviewBanner, RichText, Breadcrumbs } from '@/components';
import { RichTextContainer } from '../SectionListRenderer.styles';
import SectionListRenderer from '../SectionListRenderer';

interface ILandingPageRendererProps {
  sectionList?: any[];
  content?: IRichText;
  breadcrumbsTheme?: SectionThemeKey;
  title?: string;
  onlyLogo?: boolean;
}

const LandingPageRenderer: React.FC<ILandingPageRendererProps> = ({
  sectionList = [],
  content,
  breadcrumbsTheme,
  title,
  onlyLogo,
}) => {
  const foundPageOverviewBanner = sectionList.find(
    (section: any) => capitalize(section.__typename) === ContentTypeName.PageOverviewBanner,
  );

  const filteredSectionList = sectionList.reduce((filtered, section: any) => {
    if ([foundPageOverviewBanner].includes(section)) {
      return filtered;
    }
    if (capitalize(section.__typename) === ContentTypeName.LeadGenBanner) {
      section.variant = LeadGenBannerVariant.FULL_WIDTH;
    }
    return [...filtered, section];
  }, []);

  return (
    <>
      {foundPageOverviewBanner && <PageOverviewBanner {...foundPageOverviewBanner} />}
      {!onlyLogo && <Breadcrumbs title={title} breadcrumbsTheme={breadcrumbsTheme} hasContainer isFixed />}
      {content && !filteredSectionList?.length && (
        <Container>
          <RichTextContainer>
            <RichText richText={content} />
          </RichTextContainer>
        </Container>
      )}
      <SectionListRenderer sectionList={filteredSectionList} pageTitle={title} />
    </>
  );
};

export default LandingPageRenderer;
