import type React from 'react';
import { type Dispatch, type SetStateAction, useCallback, useEffect, useRef, useState } from 'react'
import { onSnapshot, type Query, type Unsubscribe } from 'firebase/firestore';
import { useGlobalState } from '@/hooks';
import { AnonymousSignIn } from '@/lib';
import type { IShipment } from '@/modules/pmt/interfaces';
import { queryBuilder } from '@/modules/pmt/services';
import { shipmentConverter } from '@/modules/pmt/mappers';
import { isEmailSyncShipment } from '@/modules/pmt/utils';
import { FILTER_OPTIONS, SORT_OPTIONS } from '@/modules/pmt/constants';
import { useMyParcelsContext, useShipmentFiltersContext } from '@/modules/pmt/context';

export const useShipments = (
  deletingShipmentsRef: React.MutableRefObject<Set<string>>,
  shipments: {
    overview: IShipment[];
    delivered: IShipment[];
  },
  setShipments: Dispatch<
    SetStateAction<{
      overview: IShipment[];
      delivered: IShipment[];
    }>
  >,
) => {
  const [shipmentsLoaded, setShipmentsLoaded] = useState(false);
  // Added is the number of shipments that a user has
  const [noShipmentsAdded, setNoShipmentsAdded] = useState<boolean>(true);
  // Found is the number of shipments that is filtered via a where/orderBy clause
  const [noShipmentsFound, setNoShipmentsFound] = useState<boolean>(true);
  const shipmentsQtyRef = useRef<number | null>(null);

  const { firestoreUuid } = useGlobalState();
  const { filterConfig, filterHasChanged } = useShipmentFiltersContext();
  const { focusFirstShipmentRef } = useMyParcelsContext();

  const filteredByAll = filterConfig.currentFilter === FILTER_OPTIONS.ALL;
  const sortedByLatestAdded = filterConfig.currentSort === SORT_OPTIONS.LATEST_ADDED;

  const subscribe = useCallback(
    (query: Query<IShipment>) => {
      const unsubscribe = onSnapshot(
        query,
        // observer callback is only fired when there's a change in current query listeners
        // changes outside of current query listeners will not trigger this callback
        (snapshot) => {
          const docChanges = snapshot.docChanges();

          if (filteredByAll && sortedByLatestAdded) {
            setNoShipmentsAdded(snapshot.empty);
          }
          setNoShipmentsFound(snapshot.empty);

          // query returns no results
          if (snapshot.empty) {
            setShipments((prev) => ({ ...prev, overview: [] }));
            setShipmentsLoaded(true);
            return;
          }
          // filter has changed, no need check each doc change, just set to new shipments
          if (filterHasChanged.current) {
            setShipments((prev) => {
              const newShipments = {
                ...prev,
                overview: snapshot.docs.map((doc) => ({
                  ...doc.data(),
                  isDeleting: deletingShipmentsRef.current.has(doc.id),
                })),
              };
              return newShipments;
            });

            filterHasChanged.current = false;
            setShipmentsLoaded(true);
            return;
          }

          docChanges.forEach((change) => {
            const { newIndex, oldIndex, type, doc } = change;
            const shipment = doc.data();

            if (type === 'removed') {
              setShipments((prev) => ({
                ...prev,
                overview: prev.overview.slice(0, oldIndex).concat(prev.overview.slice(oldIndex + 1)),
              }));
              deletingShipmentsRef.current.delete(doc.id);
            }
            if (type === 'added') {
              // Focus first shipment when a new shipment is added via search in Tracking Widget, not email sync
              if (!isEmailSyncShipment(shipment.data)) {
                focusFirstShipmentRef.current = true;
              }
              setShipments((prev) => {
                const existed = prev.overview.some(({ data: { firestoreDocId } }) => firestoreDocId === doc.id);
                // Load more fetches the entire list of shipments + the new ones
                // so we only need to add new ones to shipments array
                if (existed) return prev;

                return {
                  ...prev,
                  overview: [...prev.overview.slice(0, newIndex), shipment, ...prev.overview.slice(newIndex)],
                };
              });
            }
            if (type === 'modified') {
              setShipments((prev) => {
                if (prev.overview[oldIndex].data.lastSearched !== shipment.data.lastSearched) {
                  focusFirstShipmentRef.current = true;
                }
                if (oldIndex !== newIndex) {
                  const temp = prev.overview[oldIndex];
                  prev.overview[oldIndex] = prev.overview[newIndex];
                  prev.overview[newIndex] = temp;
                  return { ...prev };
                } else {
                  prev.overview[oldIndex] = shipment;
                  return { ...prev };
                }
              });
            }
          });
          setShipmentsLoaded(true);
        },
        (error) => {
          console.error('Could not fetch shipments', error);
        },
      );

      return unsubscribe;
    },
    [filteredByAll, sortedByLatestAdded, filterHasChanged, setShipments, deletingShipmentsRef, focusFirstShipmentRef],
  );

  useEffect(() => {
    if (!firestoreUuid) return;
    queryBuilder.init(firestoreUuid);
    let unsubscribe: Unsubscribe | undefined;

    (async () => {
      const currentQuery = queryBuilder.getCurrentQuery();
      if (!currentQuery) return;

      await AnonymousSignIn();
      unsubscribe = subscribe(currentQuery.withConverter(shipmentConverter));
    })();

    return () => unsubscribe?.();
  }, [subscribe, firestoreUuid, filterConfig]);

  useEffect(() => {
    if (!firestoreUuid) return;

    (async () => {
      const count = await queryBuilder.getTotalShipments();
      shipmentsQtyRef.current = count;
    })();
  }, [firestoreUuid]);

  return {
    noShipmentsAdded,
    noShipmentsFound,
    shipmentsLoaded,
    setShipmentsLoaded,
    shipmentsQty: shipmentsQtyRef.current,
    hasMoreShipments: shipments.overview.length > filterConfig.currentLimit,
  };
};
