import { LeadGenBannerSection } from './SectionListRenderer.styles';

import { ContentTypeMapping, ContentTypeName } from '@/constants';
import { capitalize } from '@/utils';
import { PMCAdType, ButtonFunctionKey, type IEvent, SectionWrapperVariant } from '@/interfaces';
import { useGlobalState } from '@/hooks';
import SectionWrapper from '../SectionWrapper/SectionWrapper.styles';

interface ISectionListRendererProps {
  featuredEventList?: IEvent[] | null;
  sectionList: any[];
  pageTitle?: string;
}

const SectionListRenderer: React.FC<ISectionListRendererProps> = ({ featuredEventList, sectionList, pageTitle }) => {
  const { isLoggedIn } = useGlobalState();
  return (
    <>
      {sectionList.map((section, index) => {
        const SectionComponent = ContentTypeMapping[section.__typename as ContentTypeName];

        if (!SectionComponent) {
          return null;
        }

        switch (capitalize(section.__typename)) {
          case ContentTypeName['LeadGenBanner']: {
            const isPopUpButton = section?.buttons?.some((button: any) => button.function === ButtonFunctionKey.PopUp);
            const isHideLoggedInUser = isPopUpButton && isLoggedIn;
            return (
              <LeadGenBannerSection key={index} variant={section.variant} isHideLoggedInUser={isHideLoggedInUser}>
                <SectionComponent {...section} />
              </LeadGenBannerSection>
            );
          }

          case ContentTypeName['EventList']: {
            if (section.featured && !featuredEventList) return null;
            if (!section.featured && featuredEventList) return null;
            return <SectionComponent {...section} featuredEventList={featuredEventList} key={index} />;
          }

          case ContentTypeName['Ads']: {
            return (
              <SectionWrapper
                key={index}
                hasPadding={false}
                hasBorderTop={false}
                variant={SectionWrapperVariant.BlockDisplay}
              >
                <SectionComponent className='ads-left-section' variant={PMCAdType.LEFT_SECTION} {...section} />
              </SectionWrapper>
            );
          }

          case ContentTypeName['LeadsWrapper']: {
            return <SectionComponent {...section} pageTitle={pageTitle} key={index} />;
          }

          default:
            return <SectionComponent key={index} {...section} />;
        }
      })}
    </>
  );
};

export default SectionListRenderer;
