import type React from 'react';
import { useGlobalState } from '@/hooks';
import { type IPageOverviewBanner, SectionThemeKey } from '@/interfaces';
import { Container } from '@/components';
import { TradeRouteWidgetContainer, TradeRouteWidgetWrapper } from './BannerWithTradeRouteWidget.styles';
import GraphicImg from '../../GraphicImg';
import TradeRouteSelector, { type ITradeRoute } from './TradeRouteSelector/TradeRouteSelector';
import { useRouter } from 'next/router';
import WidgetTextSection from '../WidgetTextSection';

const BannerWithTradeRouteWidget: React.FC<IPageOverviewBanner> = ({
  title,
  titleMobile,
  subText,
  subTextMobile,
  priority,
  image,
  imageMobile,
  theme,
}) => {
  const router = useRouter();
  const { getProperImageUrl } = useGlobalState();

  const handleOnTradelaneSearch = ({ from, to }: ITradeRoute) => {
    const slug = `${from}-to-${to}`;
    router.push(slug);
  };

  return (
    <TradeRouteWidgetContainer sectionTheme={theme || SectionThemeKey.Light}>
      <GraphicImg src={getProperImageUrl(image)} image={image} imageMobile={imageMobile} priority={priority} />
      <Container style={{ position: 'relative' }}>
        <WidgetTextSection
          mobileTitle={titleMobile}
          desktopTitle={title}
          mobileSubText={subTextMobile ?? ''}
          desktopSubText={subText ?? ''}
        />
      </Container>

      <TradeRouteWidgetWrapper>
        <TradeRouteSelector theme={theme} onSearch={handleOnTradelaneSearch} />
      </TradeRouteWidgetWrapper>
    </TradeRouteWidgetContainer>
  );
};

export default BannerWithTradeRouteWidget;
