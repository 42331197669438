import { Name, TextContent, LinkContent } from './BoardDetail.styles';
import TooltipWrapper from '@/components/Tooltip/components/TooltipWrapper';

interface TextBoardProps {
  name: string;
  displayText: string;
}

const TextBoard: React.FC<TextBoardProps> = ({ name, displayText }) => {
  return (
    <div>
      <Name>{name}</Name>
      <TooltipWrapper value={displayText}>
        <TextContent>{displayText}</TextContent>
      </TooltipWrapper>
    </div>
  );
};

interface LinkBoardProps extends TextBoardProps {
  href: string;
}

const LinkBoard: React.FC<LinkBoardProps> = ({ name, href, displayText }) => {
  return (
    <div>
      <Name>{name}</Name>
      <TooltipWrapper value={displayText}>
        <LinkContent href={href} target='_blank'>
          {displayText}
        </LinkContent>
      </TooltipWrapper>
    </div>
  );
};

export { LinkBoard, TextBoard };
