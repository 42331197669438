import React, { type FC, useMemo } from 'react';
import { useRouter } from 'next/router';
import { getBreadcrumbData, truncateTextInObject } from '@/utils';
import { ResponsiveCollection, SectionThemeKey } from '@/interfaces';
import { useResponsive } from '@/hooks';
import { LinkWrapper } from '@/components';
import {
  Angle,
  BreadcrumbsSectionContainer,
  BreadcrumbsContainer,
  BreadcrumbItem,
  BreadcrumbLabel,
  SectionWrapper,
} from './Breadcrumbs.styles';

interface IBreadcrumbs {
  breadcrumbsTheme?: SectionThemeKey;
  hasContainer?: boolean;
  isFixed?: boolean;
  title?: string;
}

// TODO: remove isFixed
const Breadcrumb: FC<IBreadcrumbs> = ({ breadcrumbsTheme = SectionThemeKey.Dark, hasContainer, isFixed, title }) => {
  const { asPath } = useRouter();
  const breadcrumbsData = getBreadcrumbData({
    asPath,
    title,
  });
  const { isMobile } = useResponsive([ResponsiveCollection.Mobile]);

  if (!breadcrumbsData?.length) return null;

  const numberOfAngle = breadcrumbsData.length - 1;
  const maxChar = useMemo(() => (isMobile ? 45 : 96), [isMobile]);

  const truncatedBreadcrumbsData = truncateTextInObject(
    breadcrumbsData,
    'label',
    maxChar - numberOfAngle * 4, // 4 is number of characters equal to the width of the angle
  );

  const Wrapper = hasContainer ? SectionWrapper : React.Fragment;

  return (
    <BreadcrumbsSectionContainer hasContainer={hasContainer} isFixed={isFixed}>
      <Wrapper>
        <BreadcrumbsContainer breadcrumbsTheme={breadcrumbsTheme}>
          {truncatedBreadcrumbsData?.map(({ label, slug }, index, arr) => {
            return (
              <BreadcrumbItem key={index}>
                <LinkWrapper href={slug}>
                  <BreadcrumbLabel>{label}</BreadcrumbLabel>
                </LinkWrapper>
                {index < arr.length - 1 && <Angle />}
              </BreadcrumbItem>
            );
          })}
        </BreadcrumbsContainer>
      </Wrapper>
    </BreadcrumbsSectionContainer>
  );
};

export default Breadcrumb;
