import Link from 'next/link';
import { ContentTypeName } from '@/constants';
import type { IMarketAndTag } from '@/interfaces';
import { Flag, TagBubble, CountryFlag, MarketBubble, FlagContainer, MarketBubbleText, Anchor } from './Bubble.styles';

interface BubbleProps extends IMarketAndTag {
  href: string;
  variant: ContentTypeName;
}

const Bubble: React.FC<BubbleProps> = (props) => {
  const { href, title, variant } = props;

  if (variant === ContentTypeName.Tag) {
    return (
      <Link passHref href={href} legacyBehavior>
        <Anchor>
          <TagBubble>{title}</TagBubble>
        </Anchor>
      </Link>
    );
  }

  const renderFlag = () => {
    const { marketName, countryCode } = props;
    const isRegion = !countryCode;

    if (isRegion) return <Flag src='/images/icons/flag.svg' alt='globe' />;
    return <CountryFlag countryCode={countryCode} alt={marketName} />;
  };

  return (
    <Link passHref href={href} legacyBehavior>
      <Anchor>
        <MarketBubble>
          <FlagContainer>{renderFlag()}</FlagContainer>
          <MarketBubbleText>{title}</MarketBubbleText>
        </MarketBubble>
      </Anchor>
    </Link>
  );
};

export default Bubble;
