import type { LanguageCode } from '@/interfaces';

export const getPmtTranslations = async (languageCode: LanguageCode) => {
  const fetchUrl = `https://s3-ap-southeast-1.amazonaws.com/static-pp/pp-web-app-assets/pmt-translations/${languageCode}.json`;
  const fetchOptions: RequestInit = { method: 'GET' };

  try {
    const response = await fetch(fetchUrl, fetchOptions);
    const data = await response.json();

    return data;
  } catch (e) {
    console.error('pmtTranslation/getData', e);
  }
};
