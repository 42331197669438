import styled, { type CSSProperties } from 'styled-components';
import { LayoutHierarchy, TextStyles } from '@/constants';
import { SectionTitle } from '@/components/SectionTitle';
import { Typography } from '@/components';

const NAV_HEIGHT = 67;

const multiEllipsis: CSSProperties = {
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
};

export const MobileHeaderContainer = styled.div<{ top?: number }>`
  position: sticky;
  top: ${({ top }) => top || NAV_HEIGHT}px;
  width: 100%;
  z-index: ${LayoutHierarchy.Fourth};
  background: ${({ theme }) => theme.colors.highEmphasisWhite};
`;

export const HeaderContent = styled.div<{ isMobile: boolean }>`
  padding: ${({ theme }) => theme.spacing[20]};
  display: flex;

  ${({ theme }) => theme.mediaBreakpointUp.lg} {
    ${({ isMobile }) => isMobile && 'display: none;'}
    align-items: flex-start;
    padding: 0;
    margin: 0;
  }
`;

export const BackArrowButton = styled.img.attrs({
  src: '/images/icons/arrow_back.svg',
  alt: 'back arrow',
})`
  object-fit: none;
  height: 30px;
  width: 20px;
  margin-right: ${({ theme }) => theme.spacing[10]};

  ${({ theme }) => theme.mediaBreakpointUp.lg} {
    display: none;
  }
`;

export const TrackingIdText = styled(SectionTitle)<{ truncatedLines: number }>(({ theme, truncatedLines }) => ({
  fontStyle: 'normal',
  fontWeight: theme.fontWeight.semiBold,
  fontSize: theme.fontSizes[20],
  lineHeight: theme.lineHeight[30],
  maxHeight: truncatedLines === 2 ? 72 : 30,
  flex: 1,
  marginBottom: 0,
  marginRight: theme.spacing[10],
  color: theme.colors.highEmphasisBlack,
  wordBreak: 'break-all',
  span: {
    ...multiEllipsis,
    WebkitLineClamp: truncatedLines,
  },
}));

export const MerchantName = styled(Typography).attrs({
  variant: TextStyles['Heading 6'],
})(({ theme }) => ({
  color: theme.colors.darkOrange,
  textTransform: 'uppercase',
  ...multiEllipsis,
  WebkitLineClamp: 1,
  wordBreak: 'break-word',
  whiteSpace: 'pre-wrap',
}));

export const ESHeaderWrapper = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'center',
  flex: 1,
  marginLeft: theme.spacing[10],

  [`${TrackingIdText}:after`]: {
    display: 'none',
  },
}));
