import { Breadcrumbs, Container, RightSection } from '@/components';
import type { IIndividualCarrier, IRightPanel, ISingleItem } from '@/interfaces';
import { ContentTypeName } from '@/constants';
import { capitalize } from '@/utils';

import { LeftSection, SectionListWrapper } from '../SectionListRenderer.styles';
import SectionListRenderer from '../SectionListRenderer';
import HeaderSection from './HeaderSection';
import OverviewSection from './OverviewSection';
import ServiceOfferingsSection from './ServiceOfferingsSection';

interface IIndividualCompanyPageRendererProps {
  sectionList?: any[];
  headerSection: Partial<IIndividualCarrier>;
  overviewSection: Partial<IIndividualCarrier>;
  serviceOfferingsSection: {
    northAmerica?: ISingleItem[];
    europe?: ISingleItem[];
    oceania?: ISingleItem[];
  };
  rightSectionList: Array<IRightPanel>;
}

const FULL_WIDTH_SECTION_LIST = [ContentTypeName.SeoSection, ContentTypeName.LeadsWrapper];

const IndividualCompanyPageRenderer: React.FC<IIndividualCompanyPageRendererProps> = ({
  sectionList = [],
  headerSection,
  overviewSection,
  rightSectionList,
  serviceOfferingsSection,
}) => {
  const sections = sectionList?.reduce<{
    fullWidthSectionList: any[];
    leftSectionList: any[];
  }>(
    (result, section) => {
      const isFullWidthSection = FULL_WIDTH_SECTION_LIST.includes(capitalize(section.__typename) as ContentTypeName);

      if (isFullWidthSection) {
        result.fullWidthSectionList.push(section);
      } else {
        result.leftSectionList.push(section);
      }

      return result;
    },
    {
      fullWidthSectionList: [],
      leftSectionList: [],
    },
  );

  const { northAmerica, europe, oceania } = serviceOfferingsSection;

  return (
    <>
      <Container>
        <SectionListWrapper>
          <LeftSection>
            <Breadcrumbs title={headerSection.carrierDisplayName} breadcrumbsTheme={headerSection.breadcrumbsTheme} />
            <HeaderSection {...headerSection} />
            <OverviewSection {...overviewSection} />

            <ServiceOfferingsSection northAmerica={northAmerica} europe={europe} oceania={oceania} />

            <SectionListRenderer sectionList={sections.leftSectionList} />
          </LeftSection>
          {rightSectionList && <RightSection rightSectionList={rightSectionList} />}
        </SectionListWrapper>
      </Container>
      <SectionListRenderer sectionList={sections.fullWidthSectionList} pageTitle={headerSection.carrierDisplayName} />
    </>
  );
};

export default IndividualCompanyPageRenderer;
