export enum MasterTranslation {
  // Header
  SocialsTitle = 'socials_title',

  // Tracking widget
  TrackingWidget = 'tracking_widget_placeholder',
  AddParcels = 'add_parcels',

  // Remove shipment
  DeleteParcel = 'delete_parcel',
  DeleteConfirmation = 'delete_confirmation',
  Cancel = 'cancel',
  Delete = 'delete',
  Deleting = 'deleting',

  // Parcel list
  LoadMore = 'load_more',
  ResetFilter = 'reset_filter',
  NoParcelsAdded = 'no_parcels_added',
  NoParcelsFound = 'no_parcels_found',
  New = 'new',
  Past = 'past',

  // Parcel card
  LookingParcel = 'looking_parcel',
  ParcelNotFound = 'parcel_not_found',

  // Individual view
  NoInfo = 'no_info',
  CarrierDropdownPlaceholder = 'carrier_dropdown_placeholder',
  IndividualCardTitle = 'individual_card_title',
  IndividualCardSubtitle = 'individual_card_subtitle',
  NoResultsFound = 'no_results_found',
  HandledBy = 'handled_by',
  Website = 'website',

  // Cookie banner
  CookieBanner = 'cookie_banner',
  CookiePolicy = 'cookie_policy',

  // Date time
  Today = 'today',
  Yesterday = 'yesterday',

  // Milestones
  Pending = 'pending',
  Ordered = 'ordered',
  InTransit = 'in_transit',
  OutForDelivery = 'out_for_delivery',
  Delivered = 'delivered',
  ToCollect = 'to_collect',
  Collected = 'collected',
  Returned = 'returned',
  Cancelled = 'cancelled',

  // Unsubscribe single
  UnsubSingleSuccessMainText = 'unsub_single_success_main_text',
  ViewMyParcels = 'view_my_parcels',

  // Unsubscribe all shipments
  UnsubAllConfirmationMainText = 'unsub_all_confirmation_main_text',
  UnsubAllSuccessMainText = 'unsub_all_success_main_text',
  Unsubscribe = 'unsubscribe',
  BackToMyParcels = 'back_to_my_parcels',
  UnsubscribeText = 'unsubscribe_text',
  UnsubscribeButton = 'unsubscribe_button',

  // PMT Onboarding Popup - Sign up screen
  ContinueWithLinkedIn = 'continue_linkedin',
  ContinueWithGoogle = 'continue_google',
  Or = 'or',
  TrackManually = 'or_email_sync_onboarding_pop_up',
  Continue = 'continue',
  Submitting = 'submitting',
  ExistEmailPromptBox = 'google/linkedin_match',
  EmailFormattingErrorMessage = 'email_address_error',
  SkipPMCNewsletter = 'skip_pmc_newsletter',
  SubscribePMCNewsletter = 'subscribe_button_pmc_newsletter',

  // PMT Onboarding Popup - Email verification screen
  VerificationMainText = 'onboarding_popup_verification_main_text',
  VerificationSubText = 'onboarding_popup_verification_sub_text',

  // PMT Onboarding Popup - Success screen
  SuccessMainText = 'onboarding_popup_success_main_text',
  SuccessSubText = 'onboarding_popup_success_sub_text',

  // Header Banner
  ValidLinkTextDesktop = 'valid_link_text_desktop',
  ValidLinkTextMobile = 'valid_link_text_mobile',
  InvalidLinkTextDesktop = 'invalid_link_text_desktop',
  InvalidLinkTextMobile = 'invalid_link_text_mobile',
  ResendLink = 'resend_link',
  SendingLink = 'sending_link',
  Sending = 'sending',
  LinkSent = 'link_sent',

  // PMT Verification Popup
  BrowserVerificationPopupQuestionMainText = 'browser_verification_popup_question_main_text',
  BrowserVerificationPopupQuestionSubText = 'browser_verification_popup_question_sub_text',
  BrowserVerificationPopupSuccessMainText = 'browser_verification_popup_success_main_text',
  BrowserVerificationPopupSuccessSubText = 'browser_verification_popup_success_sub_text',
  SendLinkToEmail = 'send_link_to_email',

  // Email Sync
  EmailConnectedList = 'email_connected_list',
  DisconnectEmailTitle = 'disconnect_email_title',
  DisconnectEmailDescription = 'disconnect_email_description',
  Disconnect = 'disconnect',
  SyncingBannerMainText = 'syncing_banner_main_text',
  SyncingBannerSubText = 'syncing_banner_sub_text',

  // PMT - Orion 18
  Delivery = 'delivery',
  DeliveryCountdown = 'delivery_countdown',
  DeliveryCountdownOne = 'delivery_countdown_1',
  Order = 'order',
  Qty = 'qty',
  Subtotal = 'subtotal',
  Tax = 'tax',
  ShippingFee = 'shipping_fee',
  Discount = 'discount',
  Total = 'total',
  ShippingMethod = 'shipping_method',
  Tracking = 'tracking',
  ViewLess = 'view_less',
  ViewMore = 'view_more',
  PendingParcel = 'pending_parcel',
  OrderConfirmed = 'order_confirmed',
  WaitingTrackingInfo = 'waiting_tracking_info',

  // Delayed Shipment Popup
  DelayedShipmentPopupMainText = 'delayed_shipment_popup_main_text',
  DelayedShipmentPopupSubText = 'delayed_shipment_popup_sub_text',
  DelayedShipmentPopupButton = 'delayed_shipment_popup_button',

  // PMT - Horus 1
  EmailListTitle = 'email_list_title',
  TrackingWidgetConnectGmail = 'tracking_widget_connect_gmail',
  UnableConnectEmailMain = 'alert_unable_to_connect_email_main',
  UnableConnectEmailSub = 'alert_unable_to_connect_email_sub',
  MaxEmailsReachedMain = 'alert_max_email_connected_main',
  MaxEmailsReachedSub = 'alert_max_email_connected_sub',
  EmailAlreadyConnectedMain = 'alert_email_already_connected_main',
  EmailAlreadyConnectedSub = 'alert_email_already_connected_sub',

  // PMT - Dialog box text
  EditNamePopupMainText = 'edit_name_popup_main_text',
  ArchiveSuccessPopupMainText = 'archive_success_popup_main_text',
  ArchiveSuccessPopupSubText = 'archive_success_popup_sub_text',
  ShipmentSumUnsubPopupMainText = 'shipment_sum_unsub_popup_main_text',
  ShipmentSumUnsubPopupSubText = 'shipment_sum_unsub_popup_sub_text',

  // PMT - Dialog box buttons
  Save = 'save',
  Saving = 'saving',
  UnsubscribePopupButton = 'unsubscribe_popup_button',

  // PMT - Horus 4 - Actions
  EditNameAction = 'edit_name_action',
  ShareLinkAction = 'share_link_action',
  ArchiveAction = 'archive_action',
  UnarchiveAction = 'unarchive_action',

  // PMT - Horus 4 - Toast messages
  CopyToastSuccess = 'copy_toast_success',
  ArchiveToastSuccess = 'archive_toast_success',
  UnarchiveToastSuccess = 'unarchive_toast_success',
  EditNameToastFail = 'edit_name_toast_fail',
  ArchiveToastFail = 'archive_toast_fail',
  UnarchiveToastFail = 'unarchive_toast_fail',

  // PMT - Horus 4 - Parcel list
  ParcelCardTrackingIdText = 'parcel_card_tracking_id_text',
  ParcelCardOrderIdText = 'parcel_card_order_id_text',
  ArchiveViewHeader = 'archive_view_header',

  // PMT - Horus 4 - Separator
  RecentlyAddedSeparator = 'recently_added_separator',
  PastSeparator = 'past_separator',
  ArrivingTodaySeparator = 'arriving_today_separator',
  ArrivingTomorrowSeparator = 'arriving_tomorrow_separator',
  ArrivingThisWeekSeparator = 'arriving_this_week_separator',
  ArrivingSoonSeparator = 'arriving_soon_separator',
  PendingDeliveryDateSeparator = 'pending_delivery_date_separator',
  DeliveredSeparator = 'delivered_separator',

  // PMT - Horus 4 - Filters
  AllFilter = 'all_filter',
  PendingFilter = 'pending_filter',
  OrderedFilter = 'ordered_filter',
  InTransitFilter = 'in_transit_filter',
  OutForDeliveryFilter = 'out_for_delivery_filter',
  ToCollectFilter = 'to_collect_filter',
  DeliveredFilter = 'delivered_filter',
  ReturnedFilter = 'returned_filter',
  ArchiveFilter = 'archived_filter',
  CancelledFilter = 'cancelled_filter',

  // PMT - Horus 4 - Sort
  LatestAddedSort = 'latest_added_sort',
  LatestUpdateSort = 'latest_update_sort',
  DeliveryDateSort = 'delivery_date_sort',

  // PMT - Horus 4 - Individual shipment view - No parcel
  ShareLinkNotFoundTitle = 'share_link_not_found_title',
  ShareLinkNotFoundSubtitle = 'share_link_not_found_subtitle',
  ShareLinkNotFoundButton = 'share_link_not_found_button',

  // PMT - Horus 4 - Onboarding Popup
  LimitedAccessPopupMainText = 'limited_access_popup_main_text',
  LimitedAccessPopupSubText = 'limited_access_popup_sub_text',
  LimitedAccessPopupSuccessMainText = 'limited_access_popup_success_main_text',
  LimitedAccessPopupSuccessButton = 'limited_access_popup_success_button',

  // PMT - Horus 4 - Tooltips
  ArchiveTooltip = 'archive_tooltip',

  // PMT - Unsubscribe shipment summary notification
  ShipmentSumUnsubScreenMainText = 'shipment_sum_unsub_screen_main_text',
  ShipmentSumUnsubScreenLinkText = 'shipment_sum_unsub_screen_link_text',
  ShipmentSumUnsubLoadingMainText = 'shipment_sum_unsub_loading_main_text',
  ShipmentSumUnsubLinkExpiredMainText = 'shipment_sum_unsub_link_expired_main_text',
  ShipmentSumUnsubLinkExpiredLinkText = 'shipment_sum_unsub_link expired_link_text',
  ShipmentSumUnsubFailedMainText = 'shipment_sum_unsub_failed_main_text',
  ShipmentSumUnsubFailedLinkText = 'shipment_sum_unsub_failed_link_text',

  // PMT - Notification List
  NotificationListTitle = 'notification_list_title',
  ShipmentSumNotiListTitle = 'shipment_sum_noti_list_title',
  ShipmentSumNotiListSubtitle = 'shipment_sum_noti_list_subtitle',
}
