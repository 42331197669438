import { createGlobalStyle } from 'styled-components';
import { Poppins } from 'next/font/google';

// Global CSS
import 'normalize.css/normalize.css';
import { LayoutHierarchy, TooltipVariant } from '@/constants';

export const poppins = Poppins({
  weight: ['400', '500', '600'],
  subsets: ['latin'],
  adjustFontFallback: false,
});

const GlobalStyles = createGlobalStyle`
  html {
    font-size: 16px;
    overflow-x: hidden !important;
    scroll-behavior: smooth;
  }

  body {
    * {
      box-sizing: border-box;
      font-family: "Twemoji Country Flags", ${poppins.style.fontFamily}, sans-serif;
    }

    a {
      text-decoration: none;
    }

    #__next {
      height: 100%;
    }

    > ins.adsbygoogle.adsbygoogle-noablate {
      z-index: ${LayoutHierarchy.Third} !important;
    }
  }

  .ads-top-of-page .adsbygoogle {
    width: 100% !important;
    max-width: 342px !important;
    height: 50px !important;
    margin: 0 auto !important;
  }

  .ads-pmt-header .adsbygoogle {
    width: 100% !important;
    max-width: 360px !important;
    height: 90px !important;
    margin: 0 auto !important;
  }

  .ads-left-section .adsbygoogle,
  .pmt-individual-parcel .adsbygoogle {
    width: 100% !important;
    max-width: 335px !important;
    height: 100.05px !important;
    margin: 0 auto !important;
  }

  .pmt-individual-parcel .adsbygoogle {
    display: flex !important;
    justify-content: center !important;
  }

  .ads-right-section .adsbygoogle {
    width: 100% !important;
    max-width: 301px !important;
    height: 291px !important;
    margin: 0 auto !important;
  }

  .pmt-inside-parcel-list .adsbygoogle {
    width: 100% !important;
    max-width: 320px !important;
    height: 100px !important;
    margin: auto !important;
  }

  .pmt-square-inside-parcel-list .adsbygoogle {
    width: 100% !important;
    max-width: 336px !important;
    height: 345px !important;
    margin: auto !important;
  }

  .pmt-below-parcel-list .adsbygoogle {
    width: 100% !important;
    max-width: 353px !important;
    height: 291px !important;
    margin: 0 auto !important;
  }

  .pmt-square .adsbygoogle {
    width: 100% !important;
    max-width: 353px !important;
    height: 345px !important;
    margin: 0 auto !important;
  }

  .pmt-square-2 .adsbygoogle {
    width: 100% !important;
    max-width: 353px !important;
    height: 353px !important;
    margin: 0 auto !important;
  }

  @media(min-width: 600px) {
    .ads-top-of-page .adsbygoogle {
      max-width: 731px !important;
      height: 92px !important;
    }

    .ads-pmt-header .adsbygoogle {
      max-width: 980px !important;
      height: 120px !important;
      margin: 0 auto !important;
    }

    .ads-left-section .adsbygoogle,
    .pmt-individual-parcel .adsbygoogle {
      max-width: 736px !important;
      height: 180px !important;
    }

    .pmt-inside-parcel-list .adsbygoogle {
      width: 100% !important;
      max-width: 320px !important;
      height: 120px !important;
      margin: auto !important;
    }
  }

  // Override Styling of Recaptcha
    .grecaptcha-badge {
      z-index: 1;
      bottom: 20px !important;
      display: none !important;
    }

  [data-floating-ui-portal] {
    .tooltip__content--${TooltipVariant.Icon},
    .tooltip__content--${TooltipVariant.FullWidth} {
      word-break: break-word;
      display: -webkit-box;
      overflow: hidden;
      -webkit-box-orient: vertical;
    }

    .tooltip__content--${TooltipVariant.FullWidth} {
      -webkit-line-clamp: 1;
      max-height: 20px;
    }

    .tooltip__content--${TooltipVariant.Icon} {
      -webkit-line-clamp: 5;
      max-height: 100px;
    }
  }

  #nprogress,
  #nprogress .bar {
    z-index: ${LayoutHierarchy.First} !important;
  }
`;

export default GlobalStyles;
