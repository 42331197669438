import type { CodeResponse } from '@react-oauth/google';
import Cookies from 'js-cookie';
import { CookieKey, SignUpSource } from '@/constants';
import { useGlobalState } from '@/hooks';
import { userInfoModify } from '@/utils';
import { authenticate, updateTrackedShipments } from '@/fetchers';
import type { OriginalUserInfo } from '@/utils/umsDataModify.utils';

export type AuthPayload = {
  code: CodeResponse['code'];
  signUpSource: SignUpSource;
  newsletterOptIn: boolean;
  country?: string;
  fromPpEmail?: string;
};

export const useAuthentication = () => {
  const { setIsLoggedIn, setUserInfo, setFirestoreUuid, pmtCurrentLanguageCode } = useGlobalState();

  /**
   *
   * @param {string} accessToken access token
   * @param {OriginalUserInfo} userInfo user info
   * @returns void
   */
  const PMLogin = (accessToken: string, userInfo: OriginalUserInfo) => {
    Cookies.set(CookieKey.UmsAccessToken, accessToken, {
      expires: 365,
    });
    setIsLoggedIn(true);
    const modifiedUserInfo = userInfoModify(userInfo);
    setUserInfo(modifiedUserInfo);

    if (userInfo) {
      Cookies.set(CookieKey.UserId, userInfo.id, { expires: 365 });
      setFirestoreUuid(userInfo.id);

      updateTrackedShipments({
        language: pmtCurrentLanguageCode,
        accessToken: accessToken,
      });
    }
  };

  /**
   *
   * @param {string} code access token
   * @param {SignUpSource} signUpSource signup source PMT / PMC
   * @param {boolean} newsletterOptIn opt in to newsletter
   * @param {string} country country code
   * @returns {Promise<any>} data
   */
  const authPM = async ({ code, signUpSource = SignUpSource.PMT, newsletterOptIn = false, country }: AuthPayload) => {
    try {
      const response = await authenticate({
        mediumOrigin: 'google',
        code,
        country,
        signUpSource,
        newsletterOptIn,
      });

      const hasError = 'error' in response;

      if (!hasError) {
        return response;
      }
    } catch (error) {
      console.error(error);
    }
  };

  return { authPM, PMLogin };
};
