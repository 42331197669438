import { useEffect, useRef } from 'react';
import { TextStyles } from '@/constants';
import { type IBannerWithInfoBoard, type IPageOverviewBanner, ResponsiveCollection, SectionThemeKey } from '@/interfaces';
import { Container, Description } from '@/components';
import { useGlobalState, useResponsive } from '@/hooks';

import GraphicImg from '../../GraphicImg';
import BoardDetailList from './BoardDetailList';
import BoardLogo from './BoardLogo';
import {
  BannerWrapper,
  BoardWrapper,
  Title,
  TitleWrapper,
  HeadingWrapper,
  BannerButton,
  DescriptionWrapper,
} from './BannerWithInfoBoard.styles';

const BannerWithInfoBoard: React.FC<IBannerWithInfoBoard> = ({
  title,
  logo,
  description,
  boardDetails,
  buttons,
  priority,
  image,
  imageMobile,
  theme,
}) => {
  const { getProperImageUrl } = useGlobalState();
  const { isDesktop } = useResponsive([ResponsiveCollection.Desktop]);
  const bannerRef = useRef<HTMLDivElement>(null);
  const boardRef = useRef<HTMLDivElement>(null);
  const hasNotEnoughData = !boardDetails?.length || !description;

  const renderButton = (buttons: IPageOverviewBanner['buttons']) => {
    if (!buttons?.length) return null;
    const button = buttons[0];

    return <BannerButton {...button}>{button.primaryButtonText}</BannerButton>;
  };

  useEffect(() => {
    if (!bannerRef.current || !boardRef.current) return;

    const calculateMarginBottom = () => {
      if (!bannerRef.current || !boardRef.current) return;
      const TOP_BORDER_SIZE = 1;
      const bannerHeight = bannerRef.current.offsetHeight;
      const bannerPaddingTop = Number.parseInt(getComputedStyle(bannerRef.current).paddingTop);
      const boardHeight = boardRef.current.offsetHeight;

      const additionalScreenBasedSpacing = isDesktop ? 0 : 20;
      const marginBottom =
        boardHeight - TOP_BORDER_SIZE - (bannerHeight - bannerPaddingTop) + additionalScreenBasedSpacing;
      bannerRef.current.style.marginBottom = `${marginBottom}px`;
    };

    const resizeObserver = new ResizeObserver(calculateMarginBottom);

    resizeObserver.observe(boardRef.current);

    return () => resizeObserver.disconnect(); // clean up
  }, [isDesktop]);

  if (!title) return null;

  const Header: React.FC = () => (
    <HeadingWrapper>
      <TitleWrapper>
        <BoardLogo src={logo} alt={title || 'logo'} placeholderText={title} />
        <Title>{title}</Title>
      </TitleWrapper>
      {renderButton(buttons)}
    </HeadingWrapper>
  );

  return (
    <BannerWrapper ref={bannerRef} hasNotEnoughData={hasNotEnoughData} sectionTheme={theme || SectionThemeKey.Light}>
      <GraphicImg src={getProperImageUrl(image)} image={image} imageMobile={imageMobile} priority={priority} />
      <Container style={{ position: 'relative' }}>
        <BoardWrapper ref={boardRef}>
          <Header />
          {description && (
            <DescriptionWrapper>
              <Description
                description={description}
                lineClamp={{ desktop: 4, mobile: 4 }}
                variant={TextStyles['Body Text Small']}
              />
            </DescriptionWrapper>
          )}
          {boardDetails && <BoardDetailList boardDetails={boardDetails} />}
        </BoardWrapper>
      </Container>
    </BannerWrapper>
  );
};

export default BannerWithInfoBoard;
