import type { Dispatch, SetStateAction } from 'react';
import type { NotiButtonState } from '@/constants';
import type { TrackingMilestone, ShipmentSubscribeState, CarrierDropdownState } from '../tracking';
import type { IProgressStage } from '../progress.interface';

export enum ParcelEventAnalyticsType {
  Regular = 'regular',
  Delay = 'delay',
  Exception = 'exception',
}

// in pp_shared_v2
export enum ParcelStatus {
  NotFound = 'not_found',
  Active = 'active',
  Exception = 'exception',
  Delayed = 'delayed',
  Delivered = 'delivered',
  Pending = 'pending',
}

// in pp-constants
export enum ShipmentStatus {
  Active = 'active',
  Inactive = 'inactive',
  Delivered = 'delivered',
  Pending = 'pending',
  Expired = 'expired',
  Return = 'return',
}

export enum ShipmentStage {
  Ordered = 'Ordered',
  Pending = 'Pending',
  Cancelled = 'Cancelled',
  InTransit = 'InTransit',
  ToCollect = 'ToCollect',
  Collected = 'Collected',
  Error = 'Error',
}

export enum EventPhaseKey {
  OrderEvents = 'A',
  FulfilmentEvents = 'B',
  PreAlert = 'C',
  Pickup = 'D',
  Transit = 'E',
  Customs = 'F',
  OutForDelivery = 'G',
  Delivery = 'H',
  ReturnToSender = 'I',
  Others = 'X',
  Internals = 'Y',
}

export enum ShipmentSearchStatus {
  Timeout = 'timeout',
  Pending = 'pending',
  Fetched = 'fetched',
  ScrapingWithCarrier = 'scraping_with_carrier',
}

export interface ICarrier {
  id: string;
  carrierId: string;
  name: string;
  logo?: string;
  url?: string;
  csPhone?: string;
  carrierUrl?: string;
  carrierUrlTracking?: string;

  // for Carrier Dropdown handling
  state?: CarrierDropdownState;
}

export interface ICarrierWithFirstFoundEvent extends ICarrier {
  firstFoundInEvent: IParcelEvent;
}

export interface IParcelEvent {
  id: number;
  carrier: ICarrier;
  type: string; // Successfully delivered
  key: string; // A10, A12,...
  date: string; // DD MMM YY/Today/Yesterday, 00:00 AM, use `importedDate` if no `time`
  location?: string | null; // Bochum, Arnsberg, Rhénanie du Nord-Westphalie, Allemagne
  // below is for progress tracker
  phaseKey: EventPhaseKey;
  analytics: ParcelEventAnalyticsType;
  isFinalEvent: boolean; // false
  isFixedAddress: boolean; // false
}

export interface IShipment {
  isDeleting?: boolean;
  data: IShipmentData;
}

export interface IShipmentData {
  firestoreDocId: string;
  searchStatus: ShipmentSearchStatus;
  createdAt: string;
  updatedAt: string;
  hidden: boolean;
  hasIssue: boolean;
  milestone: TrackingMilestone;
  stage: IProgressStage;
  shipmentImportedDate: string; // ISO date
  shipmentStatus: ShipmentStatus | undefined;
  shipmentUuid?: string;
  carrierId?: string;
  parcel: IParcel;
  rawParcel: any;
  subscribeState: ShipmentSubscribeState;
  expectedDelivery: string;
  expectedDeliveryTo: string;
  additionalInfo?: IShipmentAdditionalInfo;
  orderTracking?: IOrderTracking;
  shipmentValue?: string;
  totalShippingCost?: string;
  lineItems?: IShipmentLineItem[];
  carrierReference?: string;
  lastSearched?: string;
  estimatedDeliveryDate?: string;
  isDeleted?: boolean;
  parcelUserTag?: string[];
}

export interface IParcel {
  id: string;
  parcelId: string;
  carrier?: ICarrier;
  status: ParcelStatus;
  events: IParcelEvent[];
  lastEvent?: IParcelEvent;
  lastValidEvent?: IParcelEvent;
  parcelExpectedTimeLatestStart?: string;
  parcelExpectedTimeLatestEnd?: string;
  parcelExpectedTimeFirstStart?: string;
  parcelExpectedTimeFirstEnd?: string;
}

export interface IShipmentAdditionalInfo {
  emailAccount: string;
  emailSender: string;
  senderDomain: string;
  orderUrl: string;
  orderSourceNameSiteLink: string;
  orderSourceLogo: string;
  orderTotalPrice?: string;
  orderTotalTax?: string;
  discounts?: string[]; // List of all additional_info.discounts_{n}_amount
  userId?: string;
  label?: string;
}

export interface IShipmentLineItem {
  currencyCode?: string;
  productName?: string;
  productUrl?: string;
  productImageUrl?: string;
  quantity?: string;
  productCost?: string;
  totalPrice?: string;
  productDescription?: string;
}

export interface IOrderTracking {
  orderId: string;
  orderSourceName?: string;
}

export interface ITrackingEventDateOptions {
  showDate: boolean;
  showTime: boolean;
  fullDay: boolean;
  showDay?: boolean;
  isFormatTimeUnderOneDay?: boolean;
}

export interface ITrackingInput {
  chips: string[];
  setChips: Dispatch<SetStateAction<string[]>>;
  maxChip?: number;
  clickedSubmitButton?: boolean;
  isDisabled: boolean;
  onChange: Dispatch<SetStateAction<string>>;
}

export interface ICarrierByParcel {
  [parcelId: string]: {
    [key in CarrierDropdownState]: ICarrier;
  };
}

export interface INotiButtonStateByParcel {
  [parcelId: string]: {
    [carrierId: string]: NotiButtonState;
  };
}

export enum SyncParcelsStatus {
  PENDING = 'pending',
  SUCCESS = 'success',
}
