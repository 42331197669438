import styled from 'styled-components';
import { Typography, Container } from '@/components';
import { SectionThemeKey } from '@/interfaces';
import { TextStyles } from '@/constants';

export const BannerContainer = styled(Container)`
  display: flex;
  position: relative;
`;

export const BannerInnerContainer = styled.div`
  max-width: 736px;
`;

export const SubTitle = styled(Typography).attrs({
  variant: TextStyles['Overline'],
})`
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;  
  overflow: hidden;
  margin-bottom: ${({ theme }) => theme.spacing[8]};
`;

export const Title = styled(Typography).attrs({
  variant: TextStyles['Heading 1'],
})`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;  
  overflow: hidden;
  display: none;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    display: -webkit-box;
  }
`;

export const MobileTitle = styled(Title)`
  display: -webkit-box;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    display: none;
  }
`;

export const SubText = styled(Typography).attrs({
  variant: TextStyles['Body Text Large'],
})`
  margin-top: ${({ theme }) => theme.spacing[8]};
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;  
  overflow: hidden; 
  white-space: pre-line;
  display: none;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    display: -webkit-box;
  }
`;

export const MobileSubText = styled(SubText)`
  display: -webkit-box;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    display: none;
  }
`;

export const ButtonGroupWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacing[32]};
`;

export const ImageContainer = styled.div`
  position: relative;
  flex-shrink: 0;
  width: 544px;
  height: 252px;
  margin-left: ${({ theme }) => theme.spacing[32]};
  display: none;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    display: block;
  }
`;

export const DefaultBannerWrapper = styled.div<{
  sectionTheme: SectionThemeKey;
}>`
  position: relative;
  padding: ${({ theme }) => `${theme.spacing[40]} ${theme.spacing[20]}`};

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    padding: ${({ theme }) => `90px ${theme.spacing[20]}`};
  }

  background-color: ${({ sectionTheme, theme }) => {
    return sectionTheme === SectionThemeKey.Light ? theme.colors.surface02 : theme.colors.darkBlue;
  }};

  ${Title} {
    color: ${({ sectionTheme, theme }) => {
      return sectionTheme === SectionThemeKey.Light ? theme.colors.midnight : theme.colors.highEmphasisWhite;
    }};
  }

  ${SubTitle}, ${SubText} {
    color: ${({ sectionTheme, theme }) => {
      return sectionTheme === SectionThemeKey.Light ? theme.colors.mediumEmphasisBlack : theme.colors.highEmphasisWhite;
    }};
  }
`;
