import { useEffect, useState, useRef } from 'react';
import { useGlobalState, useResponsive } from '@/hooks';
import { ResponsiveCollection } from '@/interfaces';
import { ParcelEventAnalyticsType, type IShipmentData } from '@/modules/pmt/interfaces';

const DELAYED_POPUP_TIMEOUT = 5000; // ms

interface IUseDelayedShipmentPopUpProps {
  currentShipment?: IShipmentData;
  showDetailsView: boolean;
  pauseOpenOnboardingPopup: (isPaused: boolean) => void;
}

export const useDelayedShipmentPopUp = ({
  currentShipment,
  showDetailsView,
  pauseOpenOnboardingPopup,
}: IUseDelayedShipmentPopUpProps) => {
  const { isLoggedIn, showPMTPopUp } = useGlobalState();
  const { isMobile } = useResponsive([ResponsiveCollection.Mobile]);
  const firestoreDocId = currentShipment?.firestoreDocId;
  const latestEventAnalytics = currentShipment?.parcel?.lastEvent?.analytics;

  const [showDelayedShipmentPopUp, setShowDelayedShipmentPopUp] = useState<boolean>(false);
  const [isDelayedPopupShown, setIsDelayedPopUpShown] = useState<boolean>(false);
  const onboardingTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (isLoggedIn || (isMobile && !showDetailsView) || isDelayedPopupShown) return;

    const clearOnboardingTimeout = () => {
      if (onboardingTimeoutRef.current) clearTimeout(onboardingTimeoutRef.current);
    };

    if (latestEventAnalytics === ParcelEventAnalyticsType.Delay && !showPMTPopUp) {
      clearOnboardingTimeout();
      pauseOpenOnboardingPopup(true);
      onboardingTimeoutRef.current = setTimeout(() => {
        setShowDelayedShipmentPopUp(true);
        setIsDelayedPopUpShown(true);
      }, DELAYED_POPUP_TIMEOUT);
    } else {
      // user must close delayed popup before being able to click another shipment
      // therefore no need to close delayed popup here because it will be false if user closes delayed popup
      clearOnboardingTimeout();
      pauseOpenOnboardingPopup(false);
    }

    // cannot compare parcel.status in case user clicks on parcels with delayed status
  }, [firestoreDocId, isLoggedIn, isMobile, isDelayedPopupShown, showDetailsView, showPMTPopUp]);

  useEffect(() => {
    setIsDelayedPopUpShown(false);
  }, [firestoreDocId]);

  return {
    showDelayedShipmentPopUp,
    onCloseDelayedShipmentPopUp: () => {
      pauseOpenOnboardingPopup(false);
      setShowDelayedShipmentPopUp(false);
    },
  };
};
