export enum ButtonFunctionKey {
  Link = 'Link',
  Download = 'Download',
  PopUp = 'Pop up',
  Email = 'Email',
}

export enum ButtonColorKey {
  Blue = 'Blue',
  Yellow = 'Yellow',
  White = 'White',
  Transparent = 'Transparent',
}

export enum ButtonVariant {
  Primary = 'Primary',
  Secondary = 'Secondary',
  Outlined = 'Outlined',
  Link = 'Link',
  Hyperlink = 'Hyperlink',
}

export enum ButtonSize {
  Small = 'Small',
  Medium = 'Medium',
  Large = 'Large',
}

export enum IconAlignment {
  Left = 'Left',
  Right = 'Right',
}

export interface IButton {
  sys?: { id: string };
  primaryButtonText?: string;
  secondaryButtonText?: string;
  function?: ButtonFunctionKey;
  email?: string;
  color?: ButtonColorKey;
  buttonLink?: string;
  buttonEventLabel?: string;
  openInNewTab?: boolean;
  hideOnLoggedIn?: boolean;
  triggerPopUp?: boolean;
}

export interface ButtonProps extends IButton {
  children?: React.ReactNode;
  variant?: ButtonVariant;
  onClick?: () => void;
  href?: string;
  size?: ButtonSize;
  mobileSize?: ButtonSize;
  type?: 'button' | 'submit' | 'reset';
  icon?: any;
  alignment?: IconAlignment;
  disabled?: boolean;
  style?: any;
  className?: string;
  id?: string;
  eventTrigger?: string;
  eventLabel?: string;
  truncate?: boolean | number;
  isPMTButton?: boolean;
  hideIcon?: boolean;
}
