import { ROUTES, RouteName } from '@/constants';

export const LEADS_PAGE_TYPE = {
  [ROUTES[RouteName.ArticleSummary].path]: 'Generic contact form',
  [ROUTES[RouteName.Carriers].path]: 'Carrier directory contact form',
  [ROUTES[RouteName.IndividualCarrier].path]: 'Carrier page contact form',
};

export enum ReportOrigin {
  rightPanel = 'Right Panel',
  article = 'Article',
  landingPage = 'Landing Page',
}

export enum GA4SessionType {
  NoSessionData = 'No Session Data',
  ExistingSession = 'Existing Session',
  NewSession = 'New Session',
}

export enum ConnectEmailMethod {
  LoginCta = 'login_cta',
  GrantAccessPopup = 'grant_access_popup',
  EsyncCta = 'esync_cta',
  ToggleOn = 'toggle_on',
  TrackingWidget = 'tracking_widget',
}

export enum EsyncAlerts {
  ConfigLimitReached = 'config_limit_reached',
  MaxEmailsConnected = 'max_emails_connected',
  EmailAlreadyConnected = 'email_already_connected',
}

export enum UnsubSummaryEmailMethod {
  Email = 'email',
  Toggle = 'toggle',
}
