import type { IThumbnailSection } from '@/interfaces';
import { LoadMoreSection, ViewAllSection } from './templates';

const ThumbnailSection: React.FC<IThumbnailSection> = (props) => {
  const { containsMarketTag, latestData } = props;

  if (!latestData) return null;

  if (!containsMarketTag) {
    return <LoadMoreSection {...props} />;
  }

  return <ViewAllSection {...props} />;
};

export default ThumbnailSection;
