import { ORG_ID } from '@/constants';
import {
  getShipmentMilestone,
  getShipmentStage,
  getShipmentTime,
  getShipmentIssue,
  transformCustomFields,
} from '../utils';
import type { IShipmentData } from '../interfaces';
import { mapParcel, mapLineItem, mapOrderTracking } from '.';

export const mapShipment = (firestoreDocId: string, shipment: any): IShipmentData => {
  const mapped = <IShipmentData>{
    firestoreDocId,
    searchStatus: shipment.search_status,
    createdAt: getShipmentTime(shipment.created_at),
    updatedAt: getShipmentTime(shipment.updated_at),
    shipmentStatus: shipment.shipment_status || shipment.parcel.parcel_org_dict?.[ORG_ID]?.shipment_status,
    shipmentUuid: shipment.parcelperform_id || shipment.parcel.parcel_org_dict?.[ORG_ID]?.parcelperform_id,
    carrierId: shipment.parcel.carrier?.carrier_id,
    parcel: mapParcel(shipment.parcel),
    rawParcel: shipment.parcel,
    shipmentImportedDate: getShipmentTime(
      (shipment.parcel?.events?.length > 0 &&
        (shipment.parcel.events[0].event_time || shipment.parcel.events[0].imported_date)) ||
        shipment.imported_date ||
        shipment.parcel.imported_date,
    ),
    subscribeState: shipment.subscribe_state,
    // email_sync output
    orderTracking: mapOrderTracking(shipment.order_tracking),
    expectedDelivery: getShipmentTime(shipment.expected_delivery),
    expectedDeliveryTo: getShipmentTime(shipment.expected_delivery_to),
    additionalInfo: transformCustomFields(shipment.custom_fields), // map custom_fields to additionalInfo to match with the requirement
    shipmentValue: shipment.shipment_value,
    totalShippingCost: shipment.total_shipping_cost,
    lineItems: shipment.line_items?.map(mapLineItem),
    carrierReference: shipment.carrier_reference,
    lastSearched: getShipmentTime(shipment.last_searched),
    estimatedDeliveryDate: getShipmentTime(shipment.estimated_delivery_date),
    isDeleted: shipment.is_deleted,
    parcelUserTag: shipment.parcel_user_tag,
  };

  mapped.hasIssue = getShipmentIssue(mapped);
  mapped.milestone = getShipmentMilestone(mapped);
  mapped.stage = getShipmentStage(mapped);

  return mapped;
};
