import styled from 'styled-components';
import { LayoutHierarchy } from '@/constants';
import { type BreakpointNames, SectionWrapperVariant } from '@/interfaces';
import { SectionTitle, SectionTitleSmall } from '../SectionTitle';

const headerHeight = 67;

const SectionWrapper = styled.div<{
  hasPadding?: boolean;
  hasBorderTop?: boolean;
  variant?: SectionWrapperVariant;
  breakpoint?: BreakpointNames;
}>`
  ${({ theme, hasPadding, hasBorderTop, breakpoint }) => `
    background: ${theme.colors.highEmphasisWhite};

    ${hasPadding ? `padding: ${theme.spacing[20]}` : ''};

    ${hasBorderTop ? `border-top: 4px solid ${theme.colors.surface05};` : ''}

    ${theme.mediaBreakpointUp[breakpoint || ('md' as BreakpointNames)]} {
      border-radius: 4px;
      border-top: 4px solid ${theme.colors.darkOrange};
      box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16);

      :not(:last-child) {
        margin-bottom: ${theme.spacing[24]};
      }
    }
  `}

  ${({ theme, variant, breakpoint }) =>
    variant === SectionWrapperVariant.RowDisplay &&
    `
    ${theme.mediaBreakpointUp[breakpoint || ('md' as BreakpointNames)]} {
      display: flex;
      padding-left: ${theme.spacing[16]};
      padding-right: ${theme.spacing[16]};
      align-items: center;
      border-radius: 8px;
      box-shadow: 0px 2px 4px rgba(40, 41, 61, 0.04), 0px 8px 16px rgba(96, 97, 112, 0.16);

      ${SectionTitleSmall}, ${SectionTitle} {
        margin-bottom: 0;
        margin-right: ${theme.spacing[20]};

        &::before {
          display: none;
        }
      }
    }
  `}

  ${({ theme, variant }) =>
    variant === SectionWrapperVariant.StickyDisplay &&
    `
    display: none;
    position: sticky;
    z-index: ${LayoutHierarchy.Fourth};
    top: ${headerHeight}px;
    padding-top: ${theme.spacing[16]};

    ${theme.mediaBreakpointDown.sm} {
      display: block;
      border-top: none;
    }
  `}

  ${({ variant }) =>
    variant === SectionWrapperVariant.BlockDisplay &&
    `
    border-top: none !important;
  `}
`;

SectionWrapper.defaultProps = {
  hasPadding: true,
  hasBorderTop: true,
};

export default SectionWrapper;
