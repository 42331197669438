import getConfig from 'next/config';
import { LanguageCode } from '@/interfaces';
import {
  ACCEPTED_ARTICLE_TYPES,
  FALLBACK_PMT_LANGUAGE_CODE,
  CompanyType,
  RouteName,
  ROUTES,
  TRACK_MY_PARCEL_REGEX,
} from '@/constants';
import replacePath from './replacePath.utils';

export const getPathnameFromURL = (url: string): string => {
  try {
    const parsedURL = new URL(url);

    return parsedURL.pathname;
  } catch {
    return '';
  }
};

const getHostnameFromUrl = (url: string): string => {
  try {
    const parsedURL = new URL(url);

    return parsedURL.hostname;
  } catch {
    return '';
  }
};

// If true, open in new tab
export const isExternalURL = (url: string | undefined): boolean => {
  if (!url) return false;
  const { publicRuntimeConfig } = getConfig();
  const parsedURL = isAbsoluteUrl(url) && new URL(url);
  return parsedURL && parsedURL.host !== publicRuntimeConfig.webDomain;
};

export const isAbsoluteUrl = (url?: string): boolean => !!url && /^[a-z][a-z0-9+.-]*:/.test(url);

export const getArticleTypeNameFromSlug = (articleTypeSlug: string): string | null => {
  let articleTypeName: string | null = null;

  Object.entries(ACCEPTED_ARTICLE_TYPES).some(([name, slug]) => {
    if (slug === articleTypeSlug) {
      articleTypeName = name;

      return true;
    }
  });

  return articleTypeName;
};

export const getAcceptedCompanyType = (companyType: string): string | null => {
  const isAccepted = Object.values<string>(CompanyType).includes(companyType);
  return isAccepted ? companyType : null;
};

export const isOpenInNewTab = (url: string) => {
  if (isExternalURL(url)) return '_blank';
  return undefined;
};

type ModifyUrlQuery = (url: string, modifier: (previousQuery: unknown) => Record<string, string | number>) => string;

export const modifyUrlQuery: ModifyUrlQuery = (url, modifier) => {
  const hasQuery = url.includes('?');
  const previousQuery: Record<string, string | number> = {};

  if (hasQuery) {
    const queryString = url.split('?').pop() as string;

    queryString.split('&')?.forEach((item) => {
      const [key, value] = item.split('=');

      previousQuery[key] = value;
    });
  }

  const newQuery = modifier(previousQuery as any);
  const originalPath = url.split('?').shift();
  const newQueryString = Object.entries(newQuery)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

  return originalPath + '?' + newQueryString;
};

export const generateSquareLogo = (logo: string) =>
  logo &&
  `https://assets.parcelperform.com/pp-web-app-assets/logo-carriers/square-logo-carries/${logo.split(/[/]+/).pop()}`;

// For PMT
export const checkTrackMyParcelPage = (path: string) => {
  const trackMyParcelMatch = path.match(TRACK_MY_PARCEL_REGEX);
  let trackMyParcelLang = FALLBACK_PMT_LANGUAGE_CODE;
  if (trackMyParcelMatch) {
    const isTrackMyParcelLang = Object.values(LanguageCode).includes(trackMyParcelMatch[2] as LanguageCode);
    if (isTrackMyParcelLang) trackMyParcelLang = trackMyParcelMatch[2] as LanguageCode;
  }

  return {
    isTrackMyParcelPage: !!trackMyParcelMatch,
    languageCode: trackMyParcelLang,
  };
};

type GetPmtLangHref = (
  languageCode?: LanguageCode,
  routeName?: {
    defaultLang: RouteName;
    multiLang: RouteName;
  },
) => string;

// For PMT
export const getPmtLangHref: GetPmtLangHref = (languageCode, routeName) => {
  if (!languageCode) return '#';
  if (languageCode === LanguageCode.English) return ROUTES[routeName?.defaultLang || RouteName.TrackMyParcelEn].path;
  return replacePath(routeName?.multiLang || RouteName.TrackMyParcelMultiLanguages, [languageCode]);
};

/**
 * Get full url from anchor tag's href
 * @param href string
 * @returns string
 */
export const getFullUrlProps = (link: string) => {
  const { publicRuntimeConfig } = getConfig();
  // check if linkUrl is text with no http or https at start, and no slash at start
  // e.g. localhost:3000, insights, insights/fr
  const isText = !link.match(/^(http|https):\/\//) && !link.match(/^\//);
  const href = isText ? '/' + link : link;

  const origin = (typeof window !== 'undefined' && window.location.host) || publicRuntimeConfig.webDomain;
  const protocol = origin.includes('localhost') ? 'http' : 'https';

  const isAbsoluteUrl = /^https?:\/\//.test(href);
  const fullUrl = isAbsoluteUrl ? href : `${protocol}://${origin}${href}`;

  return {
    url: fullUrl,
    hostname: getHostnameFromUrl(fullUrl),
    pathname: getPathnameFromURL(fullUrl),
  };
};

export const isTrackMyParcelLink = (link: string) => {
  const { pathname } = getFullUrlProps(link);
  const isTrackMyParcelMatch = pathname.match(TRACK_MY_PARCEL_REGEX);
  return !!isTrackMyParcelMatch;
};

export const isInternalPmcLink = (link: string) => {
  const { publicRuntimeConfig } = getConfig();
  const { hostname } = getFullUrlProps(link);

  // for cases of https://google.com/insights <= !isTrackMyParcel = true, isHostnameMatch = false
  const isHostnameMatch = hostname === (publicRuntimeConfig.webDomain || publicRuntimeConfig.hostname);
  return !isTrackMyParcelLink(link) && isHostnameMatch;
};

// Pages that can have LeadsWrapper: Individual Carrier, Company Directory, Contact Us
export const checkIsLeadsWrapperPages = (url: string) => {
  const pathname = getPathnameFromURL(url);
  const isLeadsWrapperPages = pathname.match(/^\/(companies\/carriers(\/[\d\w-_]*)?|contact-us)$/);
  return !!isLeadsWrapperPages;
};

export const getFileNameFromFileUrl = (fileUrl?: string) => fileUrl?.split('/').pop() ?? '';
