import type { FirestoreDataConverter } from 'firebase/firestore';
import type { IShipment } from '@/modules/pmt/interfaces';
import { mapShipment } from '@/modules/pmt/mappers';

export const shipmentConverter: FirestoreDataConverter<IShipment> = {
  fromFirestore(snapshot) {
    const data = snapshot.data();
    // TODO: Remove isDeleting
    return {
      isDeleting: false,
      data: mapShipment(snapshot.id, data),
    };
  },
  toFirestore(shipment) {
    return shipment;
  },
};
