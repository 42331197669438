import { useResponsive } from '@/hooks';
import { type Asset, ResponsiveCollection } from '@/interfaces';
import { GraphicImgContainer } from './GraphicImg.styles';
import Image from 'next/image';

interface GraphicImgProps {
  src: string;
  image?: Asset;
  imageMobile?: Asset;
  priority?: boolean;
  includedWigdet?: boolean;
}
const GraphicImg: React.FC<GraphicImgProps> = ({ src, image, imageMobile, priority, includedWigdet }) => {
  const { isMobile } = useResponsive([ResponsiveCollection.Mobile]);

  if (isMobile) {
    if (!imageMobile) return <></>;

    return (
      <GraphicImgContainer>
        {includedWigdet ? (
          <Image
            src={src}
            alt={imageMobile?.description || 'parcel monitor banner'}
            priority={priority}
            quality={100}
            fill
            sizes='100vw'
            style={{
              objectFit: 'contain',
              objectPosition: 'bottom',
            }}
          />
        ) : (
          <span style={{ float: 'right' }}>
            <Image
              src={src}
              alt={imageMobile?.description || 'parcel monitor banner'}
              priority={priority}
              quality={100}
              sizes='100vw'
              width={211}
              height={147}
              style={{
                objectFit: 'contain',
                objectPosition: 'top',
              }}
            />
          </span>
        )}
      </GraphicImgContainer>
    );
  }

  if (image) {
    return (
      <GraphicImgContainer>
        <Image
          src={src}
          alt={image?.description || 'parcel monitor banner'}
          priority={priority}
          quality={100}
          width={includedWigdet ? 720 : 500}
          height={includedWigdet ? 269 : 258.51}
          style={{
            objectFit: 'contain',
            objectPosition: 'top',
          }}
        />
      </GraphicImgContainer>
    );
  }

  return <></>;
};

export default GraphicImg;
