import styled from 'styled-components';
import { TextStyles } from '@/constants';
import { SectionThemeKey } from '@/interfaces';
import Typography from '../Typography';
import ArrowRight from '../../public/images/icons/arrow_down.svg';

export const BreadcrumbLabel = styled(Typography).attrs({
  variant: TextStyles['Category List'],
  type: 'span',
})`
  white-space: nowrap;
`;

export const Angle = styled(ArrowRight)`
  transform: rotate(-90deg);
  margin-left: -${({ theme }) => theme.spacing[4]};
  margin-right: -${({ theme }) => theme.spacing[4]};
`;

export const BreadcrumbItem = styled.div`
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-right: ${({ theme }) => theme.spacing[8]};

    ${BreadcrumbLabel} {
      text-decoration: underline;
      margin-right: ${({ theme }) => theme.spacing[8]};
    }
  }
`;

export const BreadcrumbsContainer = styled.div<{
  breadcrumbsTheme: SectionThemeKey;
}>`
  height: 20px;
  width: 100%;
  max-width: 335px;
  display: flex;
  align-items: center;
  white-space: nowrap;

  ${BreadcrumbLabel} {
    color: ${({ theme, breadcrumbsTheme }) =>
      breadcrumbsTheme === SectionThemeKey.Light ? theme.colors.inactiveWhite : theme.colors.inactiveBlack};

    :hover {
      color: ${({ theme, breadcrumbsTheme }) =>
        breadcrumbsTheme === SectionThemeKey.Light
          ? theme.colors.mediumEmphasisWhite
          : theme.colors.mediumEmphasisBlack};
    }
  }

  ${Angle} {
    fill: ${({ theme, breadcrumbsTheme }) =>
      breadcrumbsTheme === SectionThemeKey.Light ? theme.colors.inactiveWhite : theme.colors.inactiveBlack};
  }

  & > :last-child ${BreadcrumbLabel} {
    color: ${({ theme, breadcrumbsTheme }) =>
      breadcrumbsTheme === SectionThemeKey.Light ? theme.colors.highEmphasisWhite : theme.colors.darkBlue};
  }

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    max-width: 736px;
  }
`;

export const SectionWrapper = styled.div`
  ${({ theme }) => theme.mediaBreakpointUp.md} {
    max-width: 1160px;
    margin: 0 auto;
  }
`;

export const BreadcrumbsSectionContainer = styled.div<{
  isFixed?: boolean;
  hasContainer?: boolean;
}>`
  overflow: hidden;
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing[20]};
  margin-bottom: ${({ theme }) => theme.spacing[16]};
  padding: 0 ${({ theme }) => theme.spacing[20]};

  ${({ isFixed }) => (isFixed ? 'position: absolute; top: 20px; left: 0; margin: 0;' : '')}

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    margin-top: 0;
    padding: 0;

    ${({ isFixed }) => (isFixed ? 'top: 40px;' : '')}

    ${({ hasContainer, theme }) => (hasContainer ? `${SectionWrapper} { padding: 0 ${theme.spacing[20]}; }` : '')}
`;
