export enum CustomEventName {
  // PMC
  joinUsButtonClicks = 'join_us_button_clicks',
  LinkedInFollowSuccess = 'linkedin_follow_success',
  BasicDetailsViews = 'basic_details_views',
  BasicDetailsSuccess = 'basic_details_success',
  EmailButtonClicks = 'email_button_clicks',
  CustomFormSubmission = 'custom_form_submission',
  ContactFormSubmission = 'contact_form_submission',
  CustomButtonClick = 'custom_button_click',
  ParcelsTracked = 'parcels_tracked',
  PopUpViews = 'pop_up_views',
  SuccessfulEmailClicks = 'successful_email_clicks',
  VerificationPopupViews = 'verification_popup_views',
  BrowserVerificationClicks = 'browser_verification_clicks',
  LinkedinButtonClicks = 'linkedin_button_clicks',
  GoogleButtonClicks = 'google_button_clicks',
  PlaybackVideoButtonViews = 'playback_video_button_views',
  SubmissionLeadsForm = 'submission_leads_form',
  ViewLeadsForm = 'view_leads_form',
  InteractionLeadsForm = 'interaction_leads_form',
  ClickLeadsElements = 'click_leads_elements',
  ClickNavigationOpen = 'click_navigation_open',
  ClickNavigationItem = 'click_navigation_item',
  ClickSkipLinkedIn = 'click_skip_linkedin',
  ViewLinkedIn = 'view_linkedin',
  ViewBasicDetails = 'view_basic_details',
  ViewJoinUsPmc = 'view_join_us_pmc',
  SignUpSuccessPmc = 'sign_up_success_pmc',
  ClickJoinUsPmc = 'click_join_us_pmc',
  ClickRegistrationWall = 'click_registration_wall',
  ClickRegistrationBanner = 'click_registration_banner',
  ClickReportsSignups = 'click_reports_signups',
  ClickEventVideoSignups = 'click_event_video_signups',
  ClickReportDownloads = 'click_report_downloads',
  ClickHeaderSignups = 'click_header_signups',
  PMCSessions = 'pmc_sessions',
  ViewJoinUsReports = 'view_join_us_reports',
  ClickOverviewBannerSignups = 'click_overview_banner_signups',

  // PMT
  ClickCourierSite = 'click_courier_site',
  ImmediateFound = 'immediate_found',
  ImmediateNotFound = 'immediate_not_found',
  ParcelSearches = 'parcel_searches',
  InvalidParcelSearches = 'invalid_parcel_searches',
  ClickPmc = 'click_PMC',
  ClickParcel = 'click_parcel',
  UserSuggest = 'user_suggest',
  UserSuggestFound = 'user_suggest_found',
  ClickRemoveParcel = 'click_remove_parcel',
  RemoveParcelConfirm = 'remove_parcel_confirm',
  ClickPlus = 'click_plus',
  ClickFilter = 'click_filter',
  ClickSort = 'click_sort',
  ViewParcel = 'view_parcel',
  ViewJoinUs = 'view_join_us',
  ClickJoinUs = 'click_join_us',
  SignUpSuccess = 'sign_up_success',
  ViewNotified = 'view_notified',
  ClickNotified = 'click_notified',
  ClickUnsubAll = 'click_unsub_all',
  ClickUnsubAllConfirm = 'click_unsub_all_confirm',
  ViewUnsubAll = 'view_unsub_all',
  ClickUnsubEmail = 'click_unsub_email',
  ClickEmailCta = 'click_email_cta',
  ViewBrowserVerification = 'view_browser_verification',
  ClickBrowserVerification = 'click_browser_verification',
  PMTSessions = 'pmt_sessions',

  // Email Sync
  ViewLoginCta = 'view_login_cta',
  ClickLoginCta = 'click_login_cta',
  ClickEsyncDisconnect = 'click_esync_disconnect',
  ViewEsyncAccounts = 'view_esync_accounts',
  EsyncDisconnectConfirm = 'esync_disconnect_confirm',
  ConnectEmailSuccess = 'connect_email_success',
  ClickEsyncConnect = 'click_esync_connect',
  ViewEsyncCta = 'view_esync_cta',
  ClickEsyncCta = 'click_esync_cta',
  ViewEsyncAlerts = 'view_esync_alerts',
  ViewGrantAccessPopup = 'view_grant_access_popup',
  UnsubSummaryEmail = 'unsub_summary_email',
  ResubSummaryToggle = 'resub_summary_toggle',
}

export enum ParcelSearchesSource {
  PMC = 'PMC',
  PMT = 'PMT',
  AMP = 'AMP',
  EMAIL = 'EMAIL',
}
