import type { ISEOSection, ISingleItem } from '@/interfaces';
import { RichText, SectionTitle } from '@/components';
import SEOItem from './SEOItem';
import { SEOSectionContainer, SectionContainer, ListContainer, LongFormSectionWrapper } from './SEOSection.styles';

const SEOSection: React.FC<ISEOSection> = (props) => {
  const { title, seoList, width, longForm } = props;

  return (
    <SEOSectionContainer>
      <SectionContainer>
        <SectionTitle>{title}</SectionTitle>

        {seoList && seoList.length > 0 && (
          <ListContainer width={width}>
            {seoList.map((seoItem: ISingleItem, index: number) => (
              <SEOItem key={index} {...seoItem} />
            ))}
          </ListContainer>
        )}

        {longForm && (
          <LongFormSectionWrapper>
            <RichText richText={longForm} />
          </LongFormSectionWrapper>
        )}
      </SectionContainer>
    </SEOSectionContainer>
  );
};

export default SEOSection;
