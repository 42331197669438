import styled from 'styled-components';
import { Paragraph } from '@/components/RichText/RichText.styles';
import { BreadcrumbsSectionContainer } from '../Breadcrumbs/Breadcrumbs.styles';

export const EventWrapper = styled.div`
  ${BreadcrumbsSectionContainer} {
    margin-bottom: 0;
  }

  ${({ theme }) => `
    ${theme.mediaBreakpointUp.md} {
      padding-top: ${theme.spacing[40]};
      padding-bottom: ${theme.spacing[40]};
      ${BreadcrumbsSectionContainer} {
        margin-bottom: ${theme.spacing[16]};
      }
    }
  `}
`;

export const Content = styled.div`
  ${({ theme }) => theme.mediaBreakpointUp.md} {
    display: flex;
    justify-content: space-between;
  }

  &.custom__richtext ${Paragraph} {
    a {
      display: inline;
    }
  }

  &.custom__richtext h1 a,
  &.custom__richtext h2 a,
  &.custom__richtext h3 a,
  &.custom__richtext h4 a,
  &.custom__richtext h5 a,
  &.custom__richtext h6 a {
    font-weight: inherit;
    font-size: inherit;
  }
`;

export const LeftSection = styled.div`
  ${({ theme }) => theme.mediaBreakpointUp.md} {
    flex: 0 0 736px;
    max-width: 736px;
  }
`;
