import { ContentTypeName } from '@/constants';
import {
  type IIndividualCarrier,
  SectionItemType,
  type ThumbnailProps,
  type IThumbnailCarousel,
  type IDataSection,
  type IMinifiedCarrier,
  KeysCanBeCompared,
} from '@/interfaces';
import { mapChartQueryOutput, minifyQuery, pageDataModify } from '@/utils';
import { rightSectionList, tagsAndMarketsQuery } from '@/fetchers';
import callContentful from './base.fetchers';
import { needEventOrArticleData, utils_getEventThumbnailsByDate, utils_getThumbnailsByTag } from '../utils';
import callPmAnalytics from '../pmAnalyticsAPI/base.fetchers';

const THUMBNAIL_CAROUSEL_MAX_ITEMS = 30;

type GetIndividualCarrierPage = (args: {
  isPreviewMode: boolean;
  slug?: string;
  allEventsList: ThumbnailProps[];
  allArticlesList: ThumbnailProps[];
  accessToken?: string;
}) => Promise<IIndividualCarrier | null>;

export const getIndividualCarrierPage: GetIndividualCarrierPage = async ({
  isPreviewMode,
  slug,
  allEventsList,
  allArticlesList,
  accessToken,
}) => {
  const query = /* GraphQL */ `
    {
      carrierCollection(
        where: {
          ${slug ? `slug: "${slug}"` : 'slug_exists: false'}
        }
        preview: ${isPreviewMode}
        limit: 1
      ) {
        items {
          sys {
            id
          }
          breadcrumbsTheme
          desktopBannerImage {
            contentType
            url(transform: { width: 956, resizeStrategy: FIT })
            description
          }
          mobileBannerImage {
            contentType
            url(transform: { width: 487, resizeStrategy: FIT })
            description
          }
          carrierLogo {
            contentType
            url(transform: { width: 156, resizeStrategy: FIT })
            description
          }
          carrierName
          carrierDisplayName
          seoDescription {
            json
          }
          button {
            primaryButtonText
            function
            email
            buttonLink
            buttonEventLabel
            openInNewTab
          }
          overviewTitle
          headquarters
          northAmerica
          europe
          oceania
          foundedDate
          industry
          employees
          website
          linkedin
          sectionList: sectionListCollection(
            preview: ${isPreviewMode}
            limit: 11
          ) {
            items {
              __typename
              ... on LeadGenBanner {
                title
                titleMobile
                image {
                  contentType
                  url(transform: { width: 696, resizeStrategy: FIT })
                  description
                }
                imageMobile {
                  contentType
                  url(transform: { width: 696, resizeStrategy: FIT })
                  description
                }
                subCopy
                subCopyMobile
                buttons: buttonsCollection(limit: 3) {
                  items {
                    sys {
                      id
                    }
                    primaryButtonText
                    secondaryButtonText
                    function
                    color
                    email
                    buttonLink
                    buttonEventLabel
                    openInNewTab
                  }
                }
                sectionTheme: theme
                isLinkedInBanner
              }
              ... on SeoSection {
                title
                seoList
                width
                longForm {
                  json
                }
              }
              ... on ThumbnailCarousel {
                tag {
                  title
                  type
                  slug
                  hide
                }
                itemType
              }
              ... on CarrierSection {
                title
                desktopSubTitle
                mobileSubTitle
                sectionLabel
                carrierListTitle
                chartList: chartListCollection(
                  preview: ${isPreviewMode}
                  limit: 5
                ) {
                  items {
                    title
                    showDescription
                    type
                    minimumRecord
                    query
                    registrationWall {
                      __typename
                      type
                      sectionHeader
                      image {
                        contentType
                        url(transform: { width: 520, resizeStrategy: FIT })
                        description
                      }
                      mobileImage {
                        contentType
                        url(transform: { width: 520, resizeStrategy: FIT })
                        description
                      }
                      title
                      subTitle
                      button {
                        buttonLink
                        buttonEventLabel
                        primaryButtonText
                        function
                        email
                      }
                      subText
                    }
                  }
                }
                peopleList: peopleListCollection(
                  preview: ${isPreviewMode}
                  limit: 6
                ) {
                  items {
                    image {
                      contentType
                      url(transform: { width: 130, resizeStrategy: FIT })
                      description
                    }
                    fullName
                    jobPosition
                    profileLink
                  }
                }
                carrierList: carrierListCollection(
                  preview: ${isPreviewMode}
                  limit: 5
                ) {
                  items {
                    slug
                    carrierLogo {
                      contentType
                      url
                      description
                    }
                    carrierName
                    carrierDisplayName
                    featured
                    headquarters
                    featured
                  }
                }
                wordCloud {
                  title
                  subText
                  layout
                  buttonLink
                  wordBubbles: wordBubblesCollection {
                    items {
                      ${tagsAndMarketsQuery()}
                    }
                  }
                }
                referenceId
                targetId
                buttonLabel
              }
              ... on LeadsWrapper {
                title
                theme
                backgroundImage {
                  contentType
                  url
                  description
                }
                form {
                  sys {
                    id
                  }
                  title
                  subText
                  button {
                    primaryButtonText
                    secondaryButtonText
                    function
                    color
                    buttonLink
                    openInNewTab
                  }
                  formFieldList: formFieldListCollection(
                    preview: ${isPreviewMode}
                    limit: 10
                  ) {
                    items {
                      ... on FormField {
                        name
                        label
                        placeholder
                        isMandatory
                        type
                        width
                        mandatoryCheckErrorMessage
                        customErrorMessage
                        dropdownOptions
                      }
                    }
                  }
                }
                valuePropositionList: valuePropositionListCollection(
                  preview: ${isPreviewMode}
                  limit: 3
                ) {
                  items {
                    sys {
                      id
                    }
                    title
                    contentType
                    url
                    description
                  }
                }
                customerLogoTitle
                customerLogoList: customerLogoListCollection(
                  preview: ${isPreviewMode}
                  limit: 8
                ) {
                  items {
                    sys {
                      id
                    }
                    contentType
                    url
                    description
                  }
                }
                zapierHookId
              }
            }
          }
          seo {
            title
            description
            ogType
            ogUrl
            ogImage {
              contentType
              url
            }
            ogTitle
            ogDescription
            robots
          }
          ${rightSectionList(isPreviewMode)}
        }
      }
    }
  `;

  const response = await callContentful(
    minifyQuery(query),
    isPreviewMode,
    `/companies/carriers/${slug}` || '/at-carrier-fetcher',
    'carrier.fetchers.getIndividualCarrierPage',
  );
  const pageData = pageDataModify<IIndividualCarrier>(response.data?.carrierCollection?.items?.filter(Boolean)?.[0]);

  if (!pageData) return null;

  const foundThumbnailCarousel = pageData?.sectionList?.filter(
    (section) => section.__typename === ContentTypeName.ThumbnailCarousel,
  ) as IThumbnailCarousel[];

  const foundDataSection = pageData?.sectionList?.filter(
    (section) => section.__typename === ContentTypeName.CarrierSection,
  ) as IDataSection[];

  const { hasEvents, hasArticles } = needEventOrArticleData({
    foundThumbnailCarousel,
  });

  const allEvents = hasEvents ? allEventsList || [] : [];
  const allArticles = hasArticles ? allArticlesList || [] : [];

  if (foundThumbnailCarousel?.length > 0) {
    foundThumbnailCarousel.map((item) => {
      const el =
        item.itemType === SectionItemType.Event
          ? utils_getEventThumbnailsByDate({
              events: allEvents,
              slug: item.tag?.slug,
              type: item.tag?.type,
            })
          : utils_getThumbnailsByTag({
              articles: allArticles,
              type: item.tag?.type,
              slug: item.tag?.slug,
            });
      item.thumbnails = el.slice(0, THUMBNAIL_CAROUSEL_MAX_ITEMS);
    });
  }

  if (foundDataSection?.length > 0) {
    await Promise.all(
      foundDataSection.map(async (dataSection) => {
        if (dataSection.chartList?.length) {
          dataSection.chartList = await Promise.all(
            (dataSection.chartList || []).map(async (chart) => {
              const query = mapChartQueryOutput(chart.type, chart.query);
              const analyticsQuery = {
                ...query,
                input: [
                  ...query.input,
                  {
                    name: KeysCanBeCompared.name,
                    value: pageData.carrierName,
                  },
                ],
              };

              return {
                ...chart,
                query,
                data: await callPmAnalytics({
                  query: analyticsQuery,
                  accessToken,
                }),
              };
            }),
          );
        }
      }),
    );
  }

  return pageData;
};

type GetMinifiedCarriers = (args: {
  isPreviewMode: boolean;
  limit?: number;
  skip?: number;
}) => Promise<IMinifiedCarrier[] | null>;

export const getMinifiedCarriers: GetMinifiedCarriers = async ({ isPreviewMode, limit = 1300, skip = 0 }) => {
  // width: 76 * 1.5 = 114
  const query = `
    {
      carrierCollection(
        skip: ${skip},
        limit: ${limit},
        order: sys_publishedAt_DESC,
        preview: ${isPreviewMode}
      ) {
        items {
          sys {
            id
            publishedAt
          }
          slug
          carrierLogo {
            contentType
            url(transform: { width: 114, resizeStrategy: FIT })
            description
          }
          featured
          carrierName
          carrierDisplayName
          headquarters
        }
      }
    }
  `;

  const response = await callContentful(query, isPreviewMode, '/api/firestore', 'carrier.fetchers.getMinifiedCarriers'); // Not care which page
  return response.data?.carrierCollection?.items?.filter(Boolean) || null;
};

type GetMinifiedCarrier = (args: {
  isPreviewMode: boolean;
  id: string;
}) => Promise<IMinifiedCarrier | null>;

export const getMinifiedCarrier: GetMinifiedCarrier = async ({ isPreviewMode, id }) => {
  // width: 76 * 1.5 = 114
  const query = `
    {
      carrier(id: "${id}", preview: ${isPreviewMode}) {
        sys {
          id
          publishedAt
        }
        slug
        carrierLogo {
          contentType
          url(transform: { width: 114, resizeStrategy: FIT })
          description
        }
        featured
        carrierName
        carrierDisplayName
        headquarters
      }
    }
  `;

  const response = await callContentful(
    query,
    isPreviewMode,
    '/api/redis/webhook',
    'carrier.fetchers.getMinifiedCarrier',
  ); // not care which page
  return response.data?.carrier;
};
