import styled from 'styled-components';
import { theme, chartColors, TextStyles } from '@/constants';
import { ChartType } from '@/interfaces';
import Typography from '@/components/Typography';
import { PaywallBackgroundContainer } from '@/components/RegistrationWall/RegistrationWall.styles';
import { ButtonPaywallContainer } from '@/components/RegistrationWall/templates/ButtonPaywall/ButtonPaywall.styles';

const TREND_CHART_MOBILE_HEIGHT = 256;
const CHART_PADDING = 16;
const NEGATIVE_CHART_PADDING = `-${theme.spacing[CHART_PADDING]}`;

export const ChartWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing[16]};
  border: 1px solid ${({ theme }) => theme.colors.pressedBlack};
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.highEmphasisWhite};
`;

export const ChartHeader = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: -${({ theme }) => theme.spacing[8]};
`;

export const LabelColor = styled.div<{ chartType?: ChartType }>(({ theme, chartType }) => ({
  position: 'relative',
  width: 24,
  height: 8,
  borderRadius: 4,
  marginRight: theme.spacing[8],
  background: theme.colors.midnight,

  ...(chartType === ChartType.TrendMetrics && {
    width: 8,
  }),

  ...(chartType === ChartType.BarMetrics && {
    width: 4,
    height: 16,
    borderRadius: '1px 1px 0px 0px',
    marginLeft: theme.spacing[12],

    ':after, :before': {
      position: 'absolute',
      content: '""',
      borderRadius: '1px 1px 0px 0px',
      background: theme.colors.midnight,
      bottom: 0,
    },

    ':after': {
      left: -12,
      width: 4,
      height: 8,
    },

    ':before': {
      left: -6,
      width: 4,
      height: 12,
    },
  }),
}));

export const LabelText = styled(Typography).attrs({
  variant: TextStyles['Body Text Small'],
})`
  color: ${({ theme }) => theme.colors.highEmphasisBlack};
`;

export const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${({ theme }) => theme.spacing[8]};

  &:not(:last-child) {
    margin-right: ${({ theme }) => theme.spacing[24]};
  }

  &:last-child ${LabelColor} {
    &, :after, :before {
      background: ${chartColors[1]};
    }
  }

  &:first-child ${LabelColor} {
    &, :after, :before {
      background: ${chartColors[0]};
    }
  }
`;

export const TitleWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing[16]};
`;

export const Title = styled(Typography).attrs({
  variant: TextStyles['Heading 3'],
})`
  color: ${({ theme }) => theme.colors.highEmphasisBlack};
`;

export const CanvasContainer = styled.div<{
  hasInnerChild: boolean;
  chartDesktopHeight: number;
}>(({ theme, hasInnerChild, chartDesktopHeight }) => ({
  position: 'relative',
  margin: '0 -10.5px',
  height: TREND_CHART_MOBILE_HEIGHT,

  ...(hasInnerChild && {
    position: 'absolute',
    width: '100%',
    margin: 0,
  }),

  [`${theme.mediaBreakpointUp.md}`]: {
    height: chartDesktopHeight,
    margin: 0,
    top: 'unset',

    ...(hasInnerChild && {
      top: '50%',
      transform: 'translateY(-50%)',
    }),
  },
}));

export const ChildOuterContainer = styled.div(({ theme }) => ({
  position: 'relative',
  left: NEGATIVE_CHART_PADDING,
  bottom: NEGATIVE_CHART_PADDING,
  width: `calc(100% + ${theme.spacing[CHART_PADDING * 2]})`,
  minHeight: TREND_CHART_MOBILE_HEIGHT,

  [`${theme.mediaBreakpointUp.md}`]: {
    marginTop: NEGATIVE_CHART_PADDING,
    width: `calc(100% + ${theme.spacing[CHART_PADDING * 2]})`,
  },
}));

export const ChildContainer = styled.div<{ wallDesktopHeight: number }>(({ theme, wallDesktopHeight }) => ({
  position: 'relative',
  display: 'flex',
  borderRadius: 3,
  minHeight: wallDesktopHeight,
  background: theme.colors.backgroundOpacity03,
  backdropFilter: 'blur(6px)',

  '> div > div': {
    borderRadius: 8,
    maxWidth: 550,
  },

  [`${PaywallBackgroundContainer}`]: {
    paddingTop: theme.spacing[20],
    paddingBottom: theme.spacing[20],
  },

  [`${theme.mediaBreakpointDown.sm}`]: {
    minHeight: TREND_CHART_MOBILE_HEIGHT, // using TREND_CHART_MOBILE_HEIGHT because of mobile layout
    paddingTop: theme.spacing[20],
    alignItems: 'end',
    height: '100%',

    '> div > div': {
      borderRadius: 4,
      maxWidth: 'unset !important',
    },

    [`${PaywallBackgroundContainer}`]: {
      padding: 0,
    },

    [`${ButtonPaywallContainer}`]: {
      paddingBottom: theme.spacing[20],
    },
  },
}));

export const OuterContainer = styled.div(({ theme }) => ({
  position: 'relative',
  width: '100%',
  minHeight: TREND_CHART_MOBILE_HEIGHT,

  [`${theme.mediaBreakpointUp.md}`]: {
    minHeight: 'unset',
  },
}));
