import { createContext, useContext, useMemo } from 'react';
import { useTrackingOnboardingPopUp } from '@/modules/pmt/components/TrackingOnboardingPopup/hooks';
import { useDelayedShipmentPopUp } from '@/modules/pmt/components/DelayedShipmentPopup/hooks';
import { useEmailSyncContext } from './EmailSync.context';
import { useMyParcelsContext, useShipmentDetailsContext } from '@/modules/pmt/context';
import { useGlobalState, usePreventBodyScroll } from '@/hooks';
import { DelayTime } from '@/constants';

interface IPopUpsContext {
  onboardingPopUpDelayTime: DelayTime;
  onCloseOnboardingPopUp: () => void;
  showDelayedShipmentPopUp: boolean;
  onCloseDelayedShipmentPopUp: () => void;
}

const PopUpsContext = createContext<IPopUpsContext>({
  onboardingPopUpDelayTime: DelayTime['10s'],
  onCloseOnboardingPopUp: () => null,
  showDelayedShipmentPopUp: false,
  onCloseDelayedShipmentPopUp: () => null,
});

export const PopUpsProvider = ({ children }: { children: React.ReactNode }) => {
  const { showPMTPopUp } = useGlobalState();
  const { isEmailSyncAllowed } = useEmailSyncContext();
  const { pmtEmailSyncOnboardingPopUp, pmtOnboardingPopUp } = useMyParcelsContext();
  const { showDetailsView, currentShipment } = useShipmentDetailsContext();

  const { delayTime, closePopUp, pauseOpenOnboardingPopup } = useTrackingOnboardingPopUp({
    pmtPopUp: isEmailSyncAllowed ? pmtEmailSyncOnboardingPopUp : pmtOnboardingPopUp,
  });

  const { showDelayedShipmentPopUp, onCloseDelayedShipmentPopUp } = useDelayedShipmentPopUp({
    showDetailsView,
    pauseOpenOnboardingPopup,
    currentShipment: currentShipment?.data,
  });

  usePreventBodyScroll(showDelayedShipmentPopUp || showPMTPopUp);

  const ctxValue = useMemo(
    () => ({
      onboardingPopUpDelayTime: delayTime,
      onCloseOnboardingPopUp: closePopUp,
      showDelayedShipmentPopUp,
      onCloseDelayedShipmentPopUp,
    }),
    [closePopUp, delayTime, onCloseDelayedShipmentPopUp, showDelayedShipmentPopUp],
  );

  return <PopUpsContext.Provider value={ctxValue}>{children}</PopUpsContext.Provider>;
};

export const usePopUpsContext = () => {
  const ctx = useContext(PopUpsContext);

  if (!ctx) {
    throw new Error('usePopUpsContext must be used within a PopUpsProvider');
  }

  return ctx;
};
