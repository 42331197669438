import type React from 'react';
import { Icons, Badge, SectionTitle } from '@/components';
import { Container, ViewMoreButton } from './Header.styles';

interface DataSectionHeaderProps {
  title?: string;
  sectionLabel?: string;
  href?: string;
}

const Header: React.FC<DataSectionHeaderProps> = ({ title, sectionLabel, href }) => {
  return (
    <>
      {sectionLabel && (
        <Container>
          <Badge leftIcon={Icons.StarBoldIcon} label={sectionLabel} />
          {title && <ViewMoreButton href={href}>VIEW MORE</ViewMoreButton>}
        </Container>
      )}

      <SectionTitle>{title}</SectionTitle>
    </>
  );
};

export default Header;
