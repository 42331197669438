import callContentful from './base.fetchers';
import type { IOnboardingPopup } from '@/interfaces';

const getBasePopUpQuery = (isPreviewMode?: boolean) => {
  return `
    onboardingPopup: onboardingPopupCollection(
      preview: ${isPreviewMode}
      limit: 5
    ) {
      items {
        sys {
          id
        }
        newUserTriggerTimes
        returnUserTriggerTimes
        joinUsHeader
        joinUsValuePropositionList: joinUsValuePropositionListCollection {
          items {
            sys {
              id
            }
            title
            contentType
            url
            description
          }
        }
        joinUsTitle
        joinUsMobileTitle
        joinUsSubtext
        joinUsMobileSubtext
        followUsHeader
        followUsValuePropositionList: followUsValuePropositionListCollection {
          items {
            sys {
              id
            }
            title
            contentType
            url
            description
          }
        }
        followUsTitle
        followUsMobileTitle
        followUsSubtext
        followUsMobileSubtext
        followUsImage {
          title
          contentType
          url
          description
        }
        aboutYouHeader
        aboutYouValuePropositionList: aboutYouValuePropositionListCollection {
          items {
            sys {
              id
            }
            title
            contentType
            url
            description
          }
        }
        aboutYouTitle
        aboutYouSubtext
        aboutYouForm {
          ... on Form {
            sys {
              id
            }
            title
            subText
            buttonText
            formFieldList: formFieldListCollection(preview: ${isPreviewMode}, limit: 10) {
              items {
                ... on FormField {
                  name
                  label
                  placeholder
                  isMandatory
                  type
                  width
                  mandatoryCheckErrorMessage
                  customErrorMessage
                  dropdownOptions
                }
              }
            }
          }
        }
        thankYouHeader
        thankYouValuePropositionList: thankYouValuePropositionListCollection {
          items {
            sys {
              id
            }
            title
            contentType
            url
            description
          }
        }
        thankYouTitle
        thankYouSubtext
        thankYouImage {
          title
          contentType
          url
          description
        }
        variant
        zapierHookId
        aboutYouClicksLabel
        progressIndicator
      }
    }
  `;
};

type GetOnboardingPopUps = (args: {
  isPreviewMode: boolean;
  slug: string;
}) => Promise<IOnboardingPopup>;

const getOnboardingPopUps: GetOnboardingPopUps = async ({ isPreviewMode, slug }) => {
  const query = `{ ${getBasePopUpQuery(isPreviewMode)} }`;

  const response = await callContentful(query, isPreviewMode, slug, 'onboardingPopup.fetchers.getOnboardingPopUps');
  const data = response.data?.onboardingPopup?.items;

  return data;
};

export { getOnboardingPopUps };
