import dynamic from 'next/dynamic';
import {
  PeopleVariant,
  DataSectionChildren,
  RightPanelType,
  CarrierListTemplate,
  ChartType,
  WordCloudLayout,
} from '@/interfaces';

export const PeopleComponentMapping: {
  [key in PeopleVariant]?: React.ComponentType<any>;
} = {
  [PeopleVariant.Default]: dynamic(() => import('../components/People/templates/Default')),
  [PeopleVariant.WithQuotes]: dynamic(() => import('../components/People/templates/WithQuotes')),
  [PeopleVariant.Border]: dynamic(() => import('../components/People/templates/Border')),
};

export const DataSectionMapping: {
  [key in DataSectionChildren]?: React.ComponentType<any>;
} = {
  [DataSectionChildren.retailerList]: dynamic(() => import('../components/DataSection/components/RetailerList')),
  [DataSectionChildren.carrierList]: dynamic(() => import('../components/DataSection/components/CarrierList')),
  [DataSectionChildren.peopleList]: dynamic(() => import('../components/DataSection/components/PeopleList')),
  [DataSectionChildren.chartList]: dynamic(() => import('../components/DataSection/components/ChartList')),
};

export const CarrierListMapping: {
  [key in CarrierListTemplate]: React.ComponentType<any>;
} = {
  [CarrierListTemplate.ItemList]: dynamic(() => import('../components/DataSection/components/CarrierList/ItemList')),
  [CarrierListTemplate.CardGrid]: dynamic(() => import('../components/DataSection/components/CarrierList/CardGrid')),
};

export const ChartComponentMapping: {
  [key in ChartType]?: React.ComponentType<any>;
} = {
  [ChartType.LineMetrics]: dynamic(() => import('../components/Chart/LineMetrics')),
  [ChartType.TableMetrics]: dynamic(() => import('../components/Chart/TableMetrics')),
  [ChartType.BarMetrics]: dynamic(() => import('../components/Chart/BarMetrics')),
  [ChartType.TrendMetrics]: dynamic(() => import('../components/Chart/TrendMetrics')),
};

export const RightSectionListComponentMapping: {
  [key in RightPanelType]?: React.ComponentType<any>;
} = {
  [RightPanelType.TrackingWidget]: dynamic(() => import('../components/TrackingWidget')),
  [RightPanelType.EventHighlights]: dynamic(() => import('../components/RightSection/EventHighlights')),
  [RightPanelType.LeadGenBanner]: dynamic(() => import('../components/LeadGenBanner')),
  [RightPanelType.FeaturedCarrierSection]: dynamic(() => import('../components/RightSection/FeaturedCarriersSection')),
  [RightPanelType.DataInsightsSection]: dynamic(() => import('../components/RightSection/DataInsightsSection')),
  [RightPanelType.ReportSection]: dynamic(() => import('../components/RightSection/ReportSection')),
};

export const WordCloudMapping: {
  [key in WordCloudLayout]: React.ComponentType<any>;
} = {
  [WordCloudLayout.Slider]: dynamic(() => import('../components/WordCloud/layout/Slider')),
  [WordCloudLayout.Row]: dynamic(() => import('../components/WordCloud/layout/Row')),
};
