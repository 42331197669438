import { type IParcelEvent, EventPhaseKey } from '../interfaces';
import { HTMLEntitiesConvert } from '../utils';
import { mapCarrier } from '.';

export const mapParcelEvent = (event: any): IParcelEvent | undefined => {
  const isHiddenEvent =
    event?.event_type_master_data?.is_consumer_visible === false ||
    event?.event_type_master_data?.event_phase?.key === EventPhaseKey.Internals;

  if (event && !isHiddenEvent) {
    return {
      id: event.id,
      type: event.event_type,
      date: event.event_time || event.imported_date,
      location: HTMLEntitiesConvert(event.event_location || '') || null,
      // carrier
      carrier: mapCarrier(event.carrier),
      // event_type_master_data
      key: event.event_type_master_data?.key,
      phaseKey: event.event_type_master_data?.event_phase?.key,
      analytics: event.event_type_master_data?.analytics,
      isFinalEvent: event.event_type_master_data?.is_final_event,
      isFixedAddress: event.event_type_master_data?.is_fixed_address,
    };
  }
};
