import type { Theme, Breakpoints, BreakpointNames } from '@/interfaces';

export const breakpointKeys = {
  xs: 'xs' as BreakpointNames,
  sm: 'sm' as BreakpointNames,
  md: 'md' as BreakpointNames,
  lg: 'lg' as BreakpointNames,
  xl: 'xl' as BreakpointNames,
  xxl: 'xxl' as BreakpointNames,
};

export const breakpoints: Breakpoints = {
  xs: '0',
  sm: '375px',
  md: '600px',
  // lg: '905px',
  lg: '800px',
  xl: '1160px',
  xxl: '1440px',
};

export const downBreakpoints: Breakpoints = {
  xs: '374.98px',
  sm: '599.98px',
  // md: '904.98px',
  md: '799.98px',
  lg: '1239.98px',
  xl: '1439.98px',
};

const mediaBreakpointUp = Object.keys(breakpoints).reduce<Breakpoints>((accumulator, currentBreakpointName) => {
  const key = currentBreakpointName as BreakpointNames;

  accumulator[key] = `@media (min-width: ${breakpoints[key]})`;

  return accumulator;
}, {});

const mediaBreakpointDown = Object.keys(downBreakpoints).reduce<Breakpoints>((accumulator, currentBreakpointName) => {
  const key = currentBreakpointName as BreakpointNames;

  accumulator[key] = `@media (max-width: ${downBreakpoints[key]})`;

  return accumulator;
}, {});

const theme: Theme = {
  colors: {
    gray01: '#DFE3E8',
    gray300: '#D9DBE1',
    blue: '#275CDE',
    blueHover: '#1D4CBE',
    yellow: '#FFC03F',
    yellowHover: '#ECAA02',
    orange: '#FFA300',
    darkOrange: '#EA580C',
    darkOrangeHover: '#CA4A07',
    darkBlue: '#001B3A',
    regal: '#01456C',
    midnight: '#0E2F56',
    hyperlink: '#1B70EF',
    hyperlinkVisited: '#6507BA', //no name yet
    surface01: '#F7F9FF',
    surface02: '#F2F4F8',
    surface03: '#EBEFF2',
    surface04: '#DCE3E9',
    surface05: '#E5E6E8',
    pastelBlue: '#ADBBD4',
    silverBlue: '#5C83C4',
    cyanBlue: '#2C599D',
    indigoBlue: '#1A3B70',
    lightSilver: '#D6D6D6',
    highEmphasisBlack: 'rgba(0, 0, 0, 0.87)',
    mediumEmphasisBlack: 'rgba(0, 0, 0, 0.6)',
    disabledBlack: 'rgba(0, 0, 0, 0.38)',
    focusedBlack: 'rgba(0, 0, 0, 0.12)',
    inactiveBlack: 'rgba(0, 0, 0, 0.54)',
    pressedBlack: 'rgba(0, 0, 0, 0.16)',
    hoverBlack: 'rgba(0, 0, 0, 0.08)',
    overlay: 'rgba(0, 0, 0, 0.25)',
    darkGray: '#333333',
    darkCharcoal: '#323232',
    highEmphasisWhite: '#FFFFFF',
    mediumEmphasisWhite: 'rgba(255, 255, 255, 0.7)',
    disabledWhite: 'rgba(255, 255, 255, 0.5)',
    focusedWhite: 'rgba(255, 255, 255, 0.12)',
    inactiveWhite: 'rgba(255, 255, 255, 0.54)',
    pressedWhite: 'rgba(255, 255, 255, 0.32)',
    hoverWhite: 'rgba(255, 255, 255, 0.08)',
    pageBackground: '#F9F9F9',
    adBackground: '#F2F2F2',
    error: '#FB2424',
    darkError: '#E20A0A',
    scrollBarThumb: '#C4C4C4',
    backgroundOpacity: 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 21.35%)',
    backgroundOpacity02: 'linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0.9) 21.35%)',
    backgroundOpacity03: 'linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, rgba(255, 255, 255, 0.90) 96.05%)',
    boxShadow: '0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16)',
  },
  fontWeight: {
    extraBold: 800,
    bold: 700,
    semiBold: 600,
    medium: 500,
    regular: 400,
  },
  fontSizes: {
    10: '0.625rem',
    12: '0.75rem',
    14: '0.875rem',
    16: '1rem',
    18: '1.125rem',
    20: '1.25rem',
    24: '1.5rem',
    28: '1.75rem',
    30: '1.875rem',
    32: '2rem',
  },
  lineHeight: {
    16: '16px',
    18: '18px',
    20: '20px',
    22: '22px',
    24: '24px',
    26: '26px',
    30: '30px',
    36: '36px',
    42: '42px',
    48: '48px',
  },
  spacing: {
    2: '0.125rem',
    4: '0.25rem',
    6: '0.375rem',
    8: '0.5rem',
    10: '0.625rem',
    12: '0.75rem',
    14: '0.875rem',
    16: '1rem',
    18: '1.125rem',
    20: '1.25rem',
    22: '1.375rem',
    24: '1.5rem',
    26: '1.625rem',
    28: '1.75rem',
    30: '1.875rem',
    32: '2rem',
    34: '2.125rem',
    36: '2.25rem',
    38: '2.375rem',
    40: '2.5rem',
    44: '2.75rem',
    46: '2.875rem',
    48: '3rem',
    56: '3.5rem',
    64: '4rem',
    76: '4.75rem',
    80: '5rem',
    82: '5.125rem',
  },
  letterSpacing: {
    1: '0.01em',
    2: '0.02em',
    5: '0.05em',
    75: '0.0075em',
  },
  elevations: {
    2: '0px 2px 4px 0px rgba(96, 97, 112, 0.16), 0px 0px 1px 0px rgba(40, 41, 61, 0.04)',
    3: '0px 4px 8px 0px rgba(96, 97, 112, 0.16), 0px 0px 2px 0px rgba(40, 41, 61, 0.04)',
    4: '0px 2px 8px rgba(40, 41, 61, 0.04), 0px 16px 24px rgba(96, 97, 112, 0.16)',
    5: '0px 16px 24px 0px rgba(96, 97, 112, 0.16), 0px 2px 8px 0px rgba(40, 41, 61, 0.04)',
  },
  mediaBreakpointUp,
  mediaBreakpointDown,
};

export default theme;
