import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { useGlobalState } from '@/hooks';
import { DelayTime, CookieKey, ON_PAGE_DURATION_BY_SECOND } from '@/constants';
import { type IPmtOnboardingPopup, PmtSignUpStep } from '@/modules/pmt/interfaces';
import { useShipmentDetailsContext } from '@/modules/pmt/context';

interface ITrackingOnboardingPopUpProps {
  pmtPopUp?: IPmtOnboardingPopup;
}

const DEFAULT_TIMERS = {
  newUserTimer: [DelayTime['10s'], DelayTime['30s'], DelayTime['2mins']],
  returnUserTimer: [DelayTime['20s'], DelayTime['2mins']],
};

export const useTrackingOnboardingPopUp = ({ pmtPopUp }: ITrackingOnboardingPopUpProps) => {
  const {
    isLoggedIn,
    showPMTPopUp,
    pmtSignUpStep,
    setShowPMTPopUp,
    setIsFromGetNotifiedBtn,
    setIsFromEmailSyncBtn,
    setIsFromDelayedShipmentBtn,
  } = useGlobalState();
  const { showDeleteShipment } = useShipmentDetailsContext();

  const isSignUpScreen = pmtSignUpStep === PmtSignUpStep.SignUp;

  const [timers, setTimers] = useState(() => {
    const isReturner = Cookies.get(CookieKey.IsPmtReturner);
    if (isSignUpScreen) {
      return isReturner ? DEFAULT_TIMERS.returnUserTimer : DEFAULT_TIMERS.newUserTimer;
    }
    return [];
  });
  const [isPaused, setIsPaused] = useState<boolean>(false);

  useEffect(() => {
    if (isLoggedIn) setTimers([]);
  }, [isLoggedIn]);

  const willShowPopUp =
    !isLoggedIn &&
    !isPaused &&
    !showPMTPopUp &&
    !showDeleteShipment &&
    pmtPopUp &&
    timers.length &&
    pmtSignUpStep !== PmtSignUpStep.Success;

  const delayTime = timers[0];

  useEffect(() => {
    if (!willShowPopUp) return;

    const timer = setTimeout(() => {
      setIsFromGetNotifiedBtn(false);
      setIsFromEmailSyncBtn(false);
      setIsFromDelayedShipmentBtn(false);
      setShowPMTPopUp(true);
    }, delayTime * 1000);

    if (!isSignUpScreen) {
      clearTimeout(timer);
    }
    return () => clearTimeout(timer);
    // Missing deps are state setters, which aren't needed here
  }, [isSignUpScreen, delayTime, willShowPopUp, pmtSignUpStep]);

  useEffect(() => {
    if (Cookies.get(CookieKey.IsPmtReturner)) return;

    const pmtPageTimerValue = Cookies.get(CookieKey.OnPmtPageTimer);
    let onPmtPageTimer = isNaN(pmtPageTimerValue) ? 0 : +pmtPageTimerValue;

    const interval = setInterval(() => {
      Cookies.set(CookieKey.OnPmtPageTimer, ++onPmtPageTimer);
      if (onPmtPageTimer >= ON_PAGE_DURATION_BY_SECOND) {
        Cookies.set(CookieKey.IsPmtReturner, true);
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const closePopUp = () => {
    setIsFromGetNotifiedBtn(false);
    setIsFromEmailSyncBtn(false);
    setShowPMTPopUp(false);

    if (!isSignUpScreen) return;

    setTimers((prev) => prev.slice(1));
  };

  return {
    delayTime,
    closePopUp,
    pauseOpenOnboardingPopup: setIsPaused,
  };
};
