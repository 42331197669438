import { type UserType, MessageField, type SignUpSource, ErrorMessages } from '@/constants';
import type { IUserInfo, FailureResponse, IAuthenticateSuccessfulResponse } from '@/interfaces';
import { userInfoModify } from '@/utils';
import { umsFetcher } from './base.fetchers';

type Authenticate = (args: {
  mediumOrigin: 'google' | 'linkedin';
  userType?: UserType;
  code: string;
  redirectUri?: string;
  country?: string;
  signUpSource: SignUpSource;
  newsletterOptIn: boolean;
}) => Promise<IAuthenticateSuccessfulResponse | FailureResponse>;

export const authenticate: Authenticate = async ({
  mediumOrigin,
  userType = 'Professional',
  code,
  redirectUri,
  country,
  signUpSource,
  newsletterOptIn,
}) => {
  const payload = {
    medium_origin: mediumOrigin,
    user_type: userType,
    code,
    redirect_uri: redirectUri,
    country,
    sign_up_source: signUpSource,
    newsletter_opt_in: newsletterOptIn,
  };

  try {
    const res = await umsFetcher.post('authenticate', payload);

    const data = await res.json();

    const isTokenValid = !!data.access_token;

    if (!isTokenValid) {
      throw ErrorMessages[MessageField.AUTHENTICATE_ERROR];
    }

    return data;
  } catch (error: any) {
    console.error('authenticate ', error);
    return ErrorMessages[MessageField.AUTHENTICATE_ERROR];
  }
};

export interface IGetUserInfoSuccessfulResponse {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  profile_picture_url: string | null;
  user_type: UserType;
  company_name: string;
  authentication_method: string;
  opt_in_date: string | null;
  default_opt_in: boolean;
  email_sync_opt_in: boolean;
}

type GetUserInfo = (accessToken?: string) => Promise<IUserInfo | FailureResponse | null>;

export const getUserInfo: GetUserInfo = async (accessToken) => {
  if (!accessToken) return null;

  try {
    const res = await umsFetcher.get('users/info', { accessToken });

    const data = await res.json();

    const isValidUser = !!data.id;

    if (!isValidUser) {
      throw ErrorMessages[MessageField.GET_USER_INFO_ERROR];
    }

    return userInfoModify(data);
  } catch (error: any) {
    console.error('users/info ', error);
    return ErrorMessages[MessageField.GET_USER_INFO_ERROR];
  }
};

type UpdateUserInfo = {
  accessToken: string;
  formData: any;
};

export const updateUserInfo = async ({
  accessToken,
  formData,
}: UpdateUserInfo): Promise<IGetUserInfoSuccessfulResponse | FailureResponse> => {
  try {
    const res = await umsFetcher.put('users/update-info', formData, {
      accessToken,
    });
    const data = await res.json();

    const isValidUser = !!data.id;

    if (!isValidUser) {
      return ErrorMessages[MessageField.UPDATE_USER_INFO_ERROR];
    }

    return data;
  } catch (error: any) {
    console.error('users/update-info ', error);
    return ErrorMessages[MessageField.UPDATE_USER_INFO_ERROR];
  }
};

type UpdateUserNewsletterOptIn = {
  accessToken: string;
  newsletterOptIn: boolean;
};

export const updateNewsletterOptIn = async ({ accessToken, newsletterOptIn }: UpdateUserNewsletterOptIn) => {
  try {
    const res = await umsFetcher.patch(
      'users/update-newsletter-opt-in',
      { newsletter_opt_in: newsletterOptIn },
      {
        accessToken,
      },
    );
    const data = await res.json();

    if (!data?.id) {
      return ErrorMessages[MessageField.UPDATE_USER_NEWSLETTER_OPT_IN_ERROR];
    }

    return data;
  } catch (error: any) {
    console.error('users/update-newsletter-opt-in', error);
  }
};

export type UpdateUserProfilePayload = {
  userId: string;
  lang: string;
  subscribeToEmailSummary: boolean;
  accessToken: string;
};

export const updateEmailSummarySubscription = async ({
  lang,
  userId,
  accessToken,
  subscribeToEmailSummary,
}: UpdateUserProfilePayload) => {
  try {
    const response = await umsFetcher.patch(
      `users/${userId}/profile`,
      {
        language: lang,
        subscribe_to_email_summary: subscribeToEmailSummary,
      },
      { accessToken },
    );

    const data = await response.json();

    if (response.ok) {
      return Promise.resolve(data);
    }

    return Promise.reject(data);
  } catch (error) {
    console.error(error);
    throw error;
  }
};
