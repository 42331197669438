import {
  DefaultBannerWrapper,
  BannerContainer,
  SubText,
  SubTitle,
  Title,
  MobileSubText,
  ButtonGroupWrapper,
  BannerInnerContainer,
  MobileTitle,
} from './DefaultBanner.styles';
import { type IPageOverviewBanner, SectionThemeKey } from '@/interfaces';
import { useGlobalState } from '@/hooks';
import ButtonGroup from '../ButtonGroup';
import GraphicImg from '../GraphicImg';

const DefaultBanner: React.FC<IPageOverviewBanner> = ({
  title,
  titleMobile,
  subTitle,
  subText,
  subTextMobile,
  priority,
  buttons,
  theme,
  image,
  imageMobile,
}) => {
  const { getProperImageUrl } = useGlobalState();

  return (
    <DefaultBannerWrapper sectionTheme={theme || SectionThemeKey.Light}>
      <GraphicImg src={getProperImageUrl(image)} image={image} imageMobile={imageMobile} priority={priority} />
      <BannerContainer>
        <BannerInnerContainer>
          {subTitle && <SubTitle>{subTitle}</SubTitle>}
          <Title>{title}</Title>
          <MobileTitle>{titleMobile || title}</MobileTitle>
          <SubText>{subText?.trim()}</SubText>
          <MobileSubText>{subTextMobile?.trim()}</MobileSubText>
          <ButtonGroupWrapper>
            <ButtonGroup buttons={buttons} />
          </ButtonGroupWrapper>
        </BannerInnerContainer>
      </BannerContainer>
    </DefaultBannerWrapper>
  );
};

export default DefaultBanner;
