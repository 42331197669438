import { SEOSection, Container, RightSection, PageOverviewBanner } from '@/components';
import { LeftSection, SectionListWrapper } from '../SectionListRenderer.styles';

import { ContentTypeName } from '@/constants';
import type { IRightPanel } from '@/interfaces';
import { capitalize } from '@/utils';

import SectionListRenderer from '../SectionListRenderer';

interface IHomepageRendererProps {
  sectionList?: any[];
  rightSectionList: Array<IRightPanel>;
}

const HomepageRenderer: React.FC<IHomepageRendererProps> = ({ sectionList = [], rightSectionList }) => {
  const foundPageOverviewBanner = sectionList.find(
    (section: any) => capitalize(section.__typename) === ContentTypeName.PageOverviewBanner,
  );
  const foundSeoSection = sectionList.find(
    (section: any) => capitalize(section.__typename) === ContentTypeName.SeoSection,
  );
  const filteredSectionList = sectionList
    .filter((section: any) => section !== foundPageOverviewBanner)
    .filter((section: any) => section !== foundSeoSection);

  return (
    <>
      {foundPageOverviewBanner && <PageOverviewBanner priority {...foundPageOverviewBanner} />}
      <Container>
        <SectionListWrapper>
          <LeftSection>
            <SectionListRenderer sectionList={filteredSectionList} />
          </LeftSection>
          {rightSectionList && (
            <RightSection haveTrackingBanner={!!foundPageOverviewBanner} rightSectionList={rightSectionList} />
          )}
        </SectionListWrapper>
      </Container>
      {foundSeoSection && <SEOSection {...foundSeoSection} />}
    </>
  );
};

export default HomepageRenderer;
