import { type Dispatch, type SetStateAction, type ReactNode, createContext, useMemo, useState, useContext, useEffect } from 'react';
import type { IShipment } from '@/modules/pmt/interfaces';
import { useMyParcelsContext, useShipmentsOverviewContext } from '@/modules/pmt/context';
import { useRouter } from 'next/router';

interface IShipmentDetailsContext {
  showDeleteShipment: boolean;
  setShowDeleteShipment: Dispatch<SetStateAction<boolean>>;
  showDetailsView: boolean;
  setShowDetailsView: Dispatch<SetStateAction<boolean>>;
  currentShipment: IShipment | undefined;
  currentShipmentDocId: string | undefined;
  setCurrentShipmentDocId: Dispatch<SetStateAction<string | undefined>>;
}

const defaultState = {
  showDeleteShipment: false,
  setShowDeleteShipment: () => null,
  showDetailsView: false,
  setShowDetailsView: () => null,
  currentShipment: undefined,
  currentShipmentDocId: undefined,
  setCurrentShipmentDocId: () => null,
};

const ShipmentDetailsContext = createContext<IShipmentDetailsContext>(defaultState);

export const ShipmentDetailsProvider = ({ children }: { children: ReactNode }) => {
  const router = useRouter();
  const [showDetailsView, setShowDetailsView] = useState(false);
  const [showDeleteShipment, setShowDeleteShipment] = useState(false);
  const [currentShipmentDocId, setCurrentShipmentDocId] = useState<string>();

  const { focusFirstShipmentRef } = useMyParcelsContext();
  const { allShipments, shipments, shipmentsLoaded, setShipmentsLoaded } = useShipmentsOverviewContext();

  const currentShipment = allShipments.find((s) => s?.data.firestoreDocId === currentShipmentDocId);

  useEffect(() => {
    if (!shipments.length || currentShipmentDocId) return;
    setCurrentShipmentDocId(shipments[0].data.firestoreDocId);
  }, [shipments, currentShipmentDocId]);

  useEffect(() => {
    // reactive state must be checked separately from ref, since ref cannot trigger deps array change
    if (!shipmentsLoaded || !allShipments.length) return;

    // focus first shipment when shipments are loaded and filter has changed
    if (focusFirstShipmentRef.current) {
      focusFirstShipmentRef.current = false;
      setCurrentShipmentDocId(shipments[0]?.data.firestoreDocId);
    }
    setShipmentsLoaded(false);
  }, [allShipments, focusFirstShipmentRef, setShipmentsLoaded, shipments, shipmentsLoaded]);

  useEffect(() => {
    const { query, pathname } = router;
    const gtmParams = ['shipmentFsId', 'section', 'milestone', 'showEdd'];
    const hasGtmParams = gtmParams.every((val) => !!query[val]);
    if (shipmentsLoaded && hasGtmParams) {
      // shipment in url is bumped in server, so should be on top of shipments list in Firestore
      const emailShipment = shipments[0];
      if (!emailShipment) {
        console.error("Error! Shipment from email can't be found");
        return;
      }
      // set shipment from email as current shipment
      setCurrentShipmentDocId(emailShipment.data.firestoreDocId);
      setShowDetailsView(true);
      // clear params
      gtmParams.forEach((param) => delete query[`${param}`]);
      router.push({ query, pathname }, undefined, { shallow: true });
    }
  }, [router.query, router.pathname, shipments, shipmentsLoaded]);

  const ctxValue = useMemo(
    () => ({
      showDeleteShipment,
      setShowDeleteShipment,
      showDetailsView,
      setShowDetailsView,
      currentShipment,
      currentShipmentDocId,
      setCurrentShipmentDocId,
    }),
    [currentShipment, currentShipmentDocId, showDeleteShipment, showDetailsView],
  );

  return <ShipmentDetailsContext.Provider value={ctxValue}>{children}</ShipmentDetailsContext.Provider>;
};

export const useShipmentDetailsContext = () => useContext(ShipmentDetailsContext);
