import type { IParcel } from '../interfaces';
import { getShipmentTime } from '../utils';
import { mapCarrier, mapParcelEvent } from '.';
import type { IParcelEvent } from '@/modules/pmt/interfaces/tracking';

export const mapParcel = (parcel: any): IParcel | undefined => {
  if (!parcel) return undefined;

  return {
    id: parcel.parcel_id,
    parcelId: parcel.parcel_id,
    carrier: mapCarrier(parcel.carrier),
    status: parcel.status,
    events:
      parcel.events?.reduce((acc: IParcelEvent[], event: IParcelEvent) => {
        const mappedEvent = mapParcelEvent(event);
        if (mappedEvent) acc.push(mappedEvent);
        return acc;
      }, []) ?? [],
    lastEvent: mapParcelEvent(parcel.last_event),
    lastValidEvent: mapParcelEvent(parcel.last_valid_event),
    parcelExpectedTimeFirstStart: getShipmentTime(parcel.parcel_expected_time_first_start),
    parcelExpectedTimeFirstEnd: getShipmentTime(parcel.parcel_expected_time_first_end),
    parcelExpectedTimeLatestStart: getShipmentTime(parcel.parcel_expected_time_latest_start),
    parcelExpectedTimeLatestEnd: getShipmentTime(parcel.parcel_expected_time_latest_end),
  };
};
