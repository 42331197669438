/* eslint-disable no-fallthrough */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { type IGetUserInfoSuccessfulResponse, updateUserInfo } from '@/fetchers/umsAPI/user.fetchers';
import { useForm, type SubmitHandler } from 'react-hook-form';
import Cookies from 'js-cookie';
import { useState } from 'react';
import { CookieKey, EmailStatusCode, EMAIL_REGEX, type SignUpSource, EmailLoginError } from '@/constants';
import { authenticateEmail, postSubmitForm, sendVerificationEmail, updateTrackedShipments } from '@/fetchers';
import { useGeo, useGlobalState, useReCaptcha } from '@/hooks';
import { userInfoModify } from '@/utils';
import { LoginMethod, OnboardingStepName, type IFormField } from '@/interfaces';
import type { PmtOnboardingPopupCta } from '@/interfaces/tracking';
import { useMyParcelsContext } from '@/modules/pmt/context';

interface BaseFormProps {
  cta?: PmtOnboardingPopupCta;
  isEmailForm?: boolean;
  emailField?: IFormField;
  signUpSource: SignUpSource;
}

const useBaseForm = ({ cta, isEmailForm, emailField, signUpSource }: BaseFormProps) => {
  const { notificationTranslation, pmtOnboardingPopUp } = useMyParcelsContext();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isOtherAuthMethodExist, setIsOtherAuthMethodExist] = useState(false);
  const [AuthMethod, setAuthMethod] = useState('');
  const [formValue, setFormValue] = useState({});
  const umsAccessToken = Cookies.get(CookieKey.UmsAccessToken);

  const { geoInfo } = useGeo();

  const { setCurrentStep, setIsLoggedIn, setUserInfo, isLoggedIn, userInfo, pmtCurrentLanguageCode, setFirestoreUuid } =
    useGlobalState();

  const { handleSubmit, reset, setError, ...restUseFormReturn } = useForm({
    mode: 'all',
  });

  const { handleReCaptchaVerify } = useReCaptcha();

  const handlePostFormSubmit = async () => {
    try {
      const captchaCode = await handleReCaptchaVerify();

      if (!captchaCode) {
        throw 'reCAPTCHA verify failed';
      }

      const captchaValidation = await postSubmitForm(captchaCode);

      if (captchaValidation?.status === 200) {
        return true;
      } else {
        throw 'Unprocessable request: Invalid captcha code';
      }
    } catch (error: any) {
      console.error(error);
      return false;
    }
  };

  const handleFormSubmit: SubmitHandler<any> = async (formData, e) => {
    e?.preventDefault();

    try {
      const isUserSubmitFormValid = await handlePostFormSubmit();

      if (!isUserSubmitFormValid) {
        setIsSubmitting(false);
        return;
      }

      setIsSubmitting(true);
      const isLoginByEmail = userInfo?.authenticationMethod === LoginMethod.Email;
      const browserUuid = Cookies.get(CookieKey.BrowserUuid);
      const accessToken = isLoginByEmail ? browserUuid : umsAccessToken;

      const res = await updateUserInfo({
        accessToken,
        formData,
      });

      if ((res as IGetUserInfoSuccessfulResponse).id) {
        setFormValue(res);
        setIsSubmitted(true);

        const email = userInfo?.email;
        const isUnverified = !isLoggedIn && browserUuid;
        if (isLoginByEmail && email && isUnverified) {
          setCurrentStep(OnboardingStepName.accountVerification);
        }
      }
    } finally {
      setIsSubmitting(false);
      reset();
    }
  };

  const handleEmailFormSubmit: SubmitHandler<any> = async (formData, e) => {
    e?.preventDefault();
    setIsSubmitting(true);
    const browserUuid = Cookies.get(CookieKey.BrowserUuid);
    const regexEmail = EMAIL_REGEX;
    const isValidEmail = formData.email.match(regexEmail);

    if (!isValidEmail) {
      emailField &&
        setError(emailField.name, {
          type: 'focus',
          message: emailField?.customErrorMessage,
        });
      setIsSubmitting(false);
      return;
    }

    try {
      const isUserSubmitFormValid = await handlePostFormSubmit();

      if (!isUserSubmitFormValid) {
        return;
      }

      const response = await authenticateEmail({
        email: formData.email,
        browserUuid,
        country: userInfo?.country ?? geoInfo?.country,
        signUpSource,
        newsletterOptIn: formData.newsletterOptIn ?? false,
      });

      const hasError = 'error' in response;
      if (hasError) {
        return;
      }

      if (response.user_info) {
        Cookies.set(CookieKey.UserId, response.user_info.id, { expires: 365 });
        setFirestoreUuid(response.user_info.id);

        updateTrackedShipments({
          language: pmtCurrentLanguageCode,
          accessToken: response.browser_uuid,
        });
      }

      const status = response.status;
      switch (status) {
        case EmailStatusCode.Verified:
          setIsLoggedIn(true);
          Cookies.set(CookieKey.UmsAccessToken, response.browser_uuid, {
            expires: 365,
          });
        case EmailStatusCode.Unverified:
          Cookies.set(CookieKey.BrowserUuid, response.browser_uuid, {
            expires: 365,
          });
          if (!isLoggedIn) {
            sendVerificationEmail({
              email: formData.email,
              browserUuid: response.browser_uuid,
              language: pmtCurrentLanguageCode,
              source: signUpSource,
              cta,
              variant: notificationTranslation?.identifier,
              popupVariant: pmtOnboardingPopUp?.identifier,
            });
          }
          setIsSubmitted(true);
          setUserInfo(userInfoModify(response.user_info));
          break;
        case EmailStatusCode.ExistOtherIssues:
          setIsOtherAuthMethodExist(true);
          setAuthMethod(EmailLoginError[response.error_code]);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return {
    handleSubmit: handleSubmit(isEmailForm ? handleEmailFormSubmit : handleFormSubmit),
    isOtherAuthMethodExist,
    isSubmitted,
    isSubmitting,
    formValue,
    AuthMethod,
    setIsOtherAuthMethodExist,
    ...restUseFormReturn,
  };
};

export default useBaseForm;
