import styled from 'styled-components';
import { LayoutHierarchy } from '@/constants';
import { PMTAdsense } from '@/modules/pmt/components/PMTAdsense';
import PMCAdsense from '../PMCAdsense';

export const LayoutWrapper = styled.div`
  width: 100%;
`;

export const CustomPMCAdsense = styled(PMCAdsense)`
  border-top: none;
  padding: ${({ theme }) => theme.spacing['8']} 0;
  margin-bottom: 0 !important;
`;
export const CustomPMTAdsense = styled(PMTAdsense)(({ theme }) => ({
  borderTop: 'none',
  padding: `${theme.spacing['8']} 0`,
  marginBottom: '0 !important',
  backgroundColor: `${theme.colors.pageBackground} !important`,

  [`${theme.mediaBreakpointDown.md}`]: {
    backgroundColor: `${theme.colors.highEmphasisWhite} !important`,
  },
}));

export const Main = styled.div`
  position: relative;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    background: ${({ theme }) => theme.colors.pageBackground};
  }
`;

const HEADER_HEIGHT = 67;

export const StickyCollapseMenu = styled.div<{ top?: number }>`
  position: fixed;
  top: ${({ top }) => top || HEADER_HEIGHT}px;
  width: 100%;
  z-index: ${LayoutHierarchy.Third};
`;
