import type { IMarketAndTag } from './common.interfaces';

export enum WordCloudLayout {
  Slider = 'Slider',
  Row = 'Row',
}

export interface IWordCloud {
  title: string;
  subText?: string;
  layout?: WordCloudLayout;
  buttonLink?: string;
  wordBubbles: IMarketAndTag[];
}
