import type { IChart } from './chart.interfaces';
import type { IPeople } from './people.interfaces';
import type { IIndividualCarrier } from './individualCarrier.interfaces';
import type { IPmInsightsRetailer } from './retailer.interfaces';

// TODO: Refractor/Update Data Section content type to have references (new app or new content type) to retailer/chart/people/carrier instead of containing all the data
export interface IDataSection {
  title?: string;
  desktopSubTitle?: string;
  mobileSubTitle?: string;
  sectionLabel?: string;
  href?: string;
  retailerCompetitorListTitle?: string;
  retailerList?: IPmInsightsRetailer[];
  chartList?: IChart[];
  peopleList?: IPeople[];
  carrierList?: Partial<IIndividualCarrier>[];
  carrierListTitle?: string;
  referenceId?: string;
  targetId?: string;
  buttonLabel?: string;
}

export enum DataSectionChildren {
  retailerList = 'retailerList',
  chartList = 'chartList',
  peopleList = 'peopleList',
  carrierList = 'carrierList',
}

export enum CarrierListTemplate {
  ItemList = 'ItemList',
  CardGrid = 'CardGrid',
}
