import { Breadcrumbs, Container, PageOverviewBanner, RightSection } from '@/components';
import { LeftSection, SectionListWrapper } from '../SectionListRenderer.styles';
import { ContentTypeName } from '@/constants';
import type { Asset, IRightPanel, SectionThemeKey } from '@/interfaces';
import { capitalize } from '@/utils';
import SectionListRenderer from '../SectionListRenderer';
import HeaderSection from '../common/HeaderSection/HeaderSection';

interface ICompanyPageRendererProps {
  sectionList?: any[];
  title?: string;
  subTitle?: string;
  icon?: Asset;
  rightSectionList: Array<IRightPanel>;
  breadcrumbsTheme?: SectionThemeKey;
}

const FULL_WIDTH_SECTION_LIST = [ContentTypeName.SeoSection, ContentTypeName.LeadsWrapper];

const CompanyPageRenderer: React.FC<ICompanyPageRendererProps> = ({
  sectionList = [],
  title = '',
  subTitle = '',
  icon,
  rightSectionList,
  breadcrumbsTheme,
}) => {
  const foundPageOverviewBanner = sectionList.find(
    (section: any) => capitalize(section.__typename) === ContentTypeName.PageOverviewBanner,
  );

  const sections = sectionList?.reduce<{
    fullWidthSectionList: any[];
    leftSectionList: any[];
  }>(
    (result, section) => {
      const contentType = capitalize(section.__typename) as ContentTypeName;
      const isIncludedPageOverViewBanner = contentType === ContentTypeName.PageOverviewBanner;
      if (isIncludedPageOverViewBanner) {
        return result;
      }

      const isFullWidthSection = FULL_WIDTH_SECTION_LIST.includes(contentType);

      if (isFullWidthSection) {
        result.fullWidthSectionList.push(section);
      } else {
        result.leftSectionList.push(section);
      }

      return result;
    },
    {
      fullWidthSectionList: [],
      leftSectionList: [],
    },
  );

  return (
    <>
      {foundPageOverviewBanner && (
        <>
          <PageOverviewBanner priority {...foundPageOverviewBanner} />
          <Breadcrumbs title={title} breadcrumbsTheme={breadcrumbsTheme} hasContainer isFixed />
        </>
      )}
      <Container>
        <SectionListWrapper>
          <LeftSection>
            {!foundPageOverviewBanner && <Breadcrumbs title={title} breadcrumbsTheme={breadcrumbsTheme} />}
            <HeaderSection title={title} icon={icon} description={subTitle} />
            <SectionListRenderer sectionList={sections.leftSectionList} />
          </LeftSection>
          {rightSectionList && <RightSection rightSectionList={rightSectionList} />}
        </SectionListWrapper>
      </Container>

      <SectionListRenderer sectionList={sections.fullWidthSectionList} pageTitle={title} />
    </>
  );
};

export default CompanyPageRenderer;
