import type { DelayTime } from '@/constants';
import type { Asset } from '@/interfaces';
export interface ITrackingLeftPanel {
  joinUsHeader?: string;
  joinUsValuePropositionList?: Array<Asset>;
}
export interface ITrackingRightPanel {
  joinUsDesktopMainText?: string;
  joinUsMobileMainText?: string;
  joinUsSubtext?: string;
  desktopGoogleCta?: string;
  mobileGoogleCta?: string;
  popupVariant?: string;
  subPmcNewsletterText?: string;
  newsletterSubText?: string;
  heroImage?: Asset;
  sys?: { id: string };
  zapierHookId?: string;
  identifier?: string;
  onNext?: () => void;
  onClose?: () => void;

  // For GTM
  delayTime?: DelayTime;
}

export type IPmtOnboardingPopup = ITrackingLeftPanel & ITrackingRightPanel;

export enum PmtSignUpStep {
  SignUp = 'Sign Up',
  VerifyEmail = 'Verify Email',
  Success = 'Success',
}

export enum PmtOnboardingPopupCta {
  Noti = 'noti',
  Login = 'login',
  DelayedShipment = 'delayed shipment',
}
