import Image from 'next/image';
import ReactCountryFlag from 'react-country-flag';
import styled from 'styled-components';
import { TextStyles } from '@/constants';
import { Typography } from '@/components';

export const TagBubble = styled(Typography).attrs({
  variant: TextStyles['Overline'],
})(({ theme }) => ({
  padding: `${theme.spacing[8]} ${theme.spacing[12]}`,
  borderRadius: 50,
  backgroundColor: theme.colors.surface03,
  color: theme.colors.blue,
  display: 'inline-flex',

  maxWidth: '100%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',

  ['&:hover, &:focus']: {
    backgroundColor: theme.colors.surface04,
    transition: 'background-color .3s ease-in-out',
  },
}));

export const MarketBubble = styled.div(({ theme }) => ({
  maxWidth: '100%',
  height: 32, // Note: hardcode due to Design inconsistency
  alignItems: 'center',
  padding: `${theme.spacing[6]} ${theme.spacing[10]}`,
  borderRadius: 4,
  backgroundColor: theme.colors.surface03,
  color: theme.colors.blue,
  display: 'inline-flex',

  ['&:hover, &:focus']: {
    backgroundColor: theme.colors.surface04,
    transition: 'background-color .3s ease-in-out',
  },
}));

export const MarketBubbleText = styled(Typography).attrs({
  variant: TextStyles['Button'],
})(({ theme }) => ({
  letterSpacing: theme.letterSpacing[1],
  textTransform: 'uppercase',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}));

export const CountryFlag = styled(ReactCountryFlag)(({ theme }) => ({
  fontSize: '22px !important',
  lineHeight: '15px !important',
  marginRight: theme.spacing[4],
}));

export const Flag = styled(Image).attrs({
  width: 20,
  height: 20,
})({
  display: 'block',
  objectFit: 'contain',
});

export const FlagContainer = styled.div(({ theme }) => ({
  marginRight: theme.spacing[4],
}));

export const Anchor = styled.a({
  display: 'inline-block',
  maxWidth: '100%',
});
