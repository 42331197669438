import dayjs from 'dayjs';
import {
  type IShipmentData,
  type IParcelEvent,
  ParcelStatus,
  ShipmentSearchStatus,
  type ICarrierWithFirstFoundEvent,
  TrackingMilestone,
  type IProgressStage,
} from '@/modules/pmt/interfaces';
import { MILESTONE_MATCHERS, STAGE_MATCHERS, PENDING_STAGE, analyticsWithIssue } from '@/modules/pmt/constants';
import { isEmailSyncShipment } from './tracking.utils';

export const buildTranslateEventsObj: (rawParcel: any) => null | Record<string, unknown> = (rawParcel: any) => {
  if (!rawParcel || typeof rawParcel !== 'object') return null;

  const allowKeys = [
    'origin_locality',
    'destination_locality',
    'last_event',
    'events',
    'origin_country',
    'destination_country',
    'event_location',
    'has_location_mapped', // boolean
  ];

  const outputObject: Record<string, unknown> = {};
  allowKeys.map((key) => {
    if (rawParcel[key] !== null || typeof rawParcel[key] !== 'undefined') {
      outputObject[key] = rawParcel[key];
    }
  });

  return Object.keys(outputObject).length > 0 ? outputObject : null;
};

export type EventsById = {
  [eventId: string]: IParcelEvent;
};

export const sortEventList = (eventList: EventsById): IParcelEvent[] => {
  return Object.values(eventList).sort((firstEvent: IParcelEvent, secondEvent: IParcelEvent) => {
    const isSameYearMonthAndDay: boolean =
      dayjs(firstEvent.date).format('DD MM YY') === dayjs(secondEvent.date).format('DD MM YY');

    if (isSameYearMonthAndDay) {
      if (firstEvent.isFinalEvent) return -1;
      if (secondEvent.isFinalEvent) return 1;
    }

    const firstEventDate = new Date(firstEvent.date as string).getTime();
    const secondEventDate = new Date(secondEvent.date as string).getTime();

    return secondEventDate - firstEventDate;
  });
};

export const generateCarrierListFromEventList = (inputEventList: IParcelEvent[]) =>
  [...inputEventList].reverse().reduce<ICarrierWithFirstFoundEvent[]>((foundCarrierList, event) => {
    const isCarrierFound = foundCarrierList.find((carrier) => carrier.id === event.carrier.id);

    if (!isCarrierFound) {
      foundCarrierList.unshift({
        ...event.carrier,
        firstFoundInEvent: event,
      });
    }

    return foundCarrierList;
  }, []);

/**
 * This util is created to support cases where shipments don't have search_status, e.g: email-sync shipments, shipment.search_status is introduced in Horus-0
 */
export const getShipmentSearchStatus = (shipment?: IShipmentData) => {
  if (!shipment) return ShipmentSearchStatus.Timeout;

  // Email sync shipments
  if (isEmailSyncShipment(shipment)) {
    return shipment.parcel?.events.length ? ShipmentSearchStatus.Fetched : ShipmentSearchStatus.Timeout;
  }

  // Old shipments that don't have search_status
  if (shipment.parcel?.status !== ParcelStatus.NotFound && shipment.parcel?.events.length) {
    return ShipmentSearchStatus.Fetched;
  }

  // New shipments from Horus-0 that have search_status
  return shipment.searchStatus ?? ShipmentSearchStatus.Timeout;
};

export const HTMLEntitiesConvert = (text: string) => {
  if (typeof window === 'undefined') {
    return;
  }

  const txt = new DOMParser().parseFromString(text, 'text/html');

  return txt.documentElement.textContent;
};

export const getShipmentStage = (shipment: IShipmentData): IProgressStage => {
  const matched = STAGE_MATCHERS.find((condition) => condition.matcher(shipment));

  if (!matched) {
    console.error('Invariant Violation: Could not match shipment with any stage! Defaulted to Pending stage.');
    return PENDING_STAGE;
  }

  return matched.stage;
};

type GetMilestone = (shipment: IShipmentData) => TrackingMilestone;

export const getShipmentMilestone: GetMilestone = (shipment) => {
  const matched = MILESTONE_MATCHERS.find((condition) => condition.matcher(shipment));

  if (!matched) {
    console.error('Invariant Violation: Could not match shipment with any milestone! Defaulted to Pending milestone.');
    return TrackingMilestone.Pending;
  }

  return matched.milestone;
};

export const getShipmentIssue = (shipment: IShipmentData): boolean => {
  return shipment.parcel.lastEvent ? analyticsWithIssue.includes(shipment.parcel.lastEvent.analytics) : false;
};
