import {
  type Dispatch,
  type MutableRefObject,
  type ReactNode,
  type SetStateAction,
  createContext,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import type { IShipment } from '@/modules/pmt/interfaces';
import { useShipments, useDeliveredShipments } from '@/modules/pmt/hooks';
import { useShipmentFiltersContext } from './ShipmentFilters.context';

interface IShipmentsOverviewContext {
  allShipments: IShipment[];

  // overview
  shipments: IShipment[];

  /**
   * No shipments in an account
   */
  noShipmentsAdded: boolean;
  /**
   * No shipments found for the current filter
   */
  noShipmentsFound: boolean;
  /**
   * If there are more shipments to load
   */
  hasMoreShipments: boolean;
  /**
   * Total number of shipments at first load PMT
   * This is currently used for GA4 only (click_filter, click_sort)
   */
  shipmentsQty: number | null;
  shipmentsLoaded: boolean;
  setShipmentsLoaded: Dispatch<SetStateAction<boolean>>;

  // delivered
  deliveredShipments: IShipment[];
  deliveredQueryLimit: number;
  hasMoreDelivered: boolean;
  fetchMoreDelivered: () => void;
  noDeliveredShipmentsFound: boolean;

  setShipments: Dispatch<SetStateAction<{ overview: IShipment[]; delivered: IShipment[] }>>;
  deletingShipmentsRef: MutableRefObject<Set<string>>;
}

const defaultState = {
  allShipments: [],
  shipments: [],
  noShipmentsAdded: true,
  noShipmentsFound: true,
  hasMoreShipments: false,
  shipmentsQty: null,
  shipmentsLoaded: false,
  setShipmentsLoaded: () => null,
  deliveredShipments: [],
  deliveredQueryLimit: 3,
  hasMoreDelivered: false,
  fetchMoreDelivered: () => null,
  noDeliveredShipmentsFound: true,
  setShipments: () => null,
  deletingShipmentsRef: { current: new Set<string>() },
};

const ShipmentsOverviewContext = createContext<IShipmentsOverviewContext>(defaultState);

export const ShipmentsOverviewProvider = ({ children }: { children: ReactNode }) => {
  const [shipments, setShipments] = useState<{ overview: IShipment[]; delivered: IShipment[] }>({
    overview: [],
    delivered: [],
  });

  const { filterConfig } = useShipmentFiltersContext();

  const deletingShipmentsRef = useRef<Set<string>>(new Set());

  const { shipmentsQty, noShipmentsAdded, noShipmentsFound, hasMoreShipments, shipmentsLoaded, setShipmentsLoaded } =
    useShipments(deletingShipmentsRef, shipments, setShipments);

  const allShipments = useMemo(() => [...shipments.delivered, ...shipments.overview], [shipments]);

  const {
    queryLimit: deliveredQueryLimit,
    fetchMore: fetchMoreDelivered,
    noDeliveredShipmentsFound,
    hasMore: hasMoreDelivered,
  } = useDeliveredShipments(deletingShipmentsRef, shipments, setShipments);

  const ctxValue = useMemo(
    () => ({
      allShipments,
      // overview
      shipments: shipments.overview.slice(0, filterConfig.currentLimit),
      noShipmentsAdded,
      noShipmentsFound,
      hasMoreShipments,
      shipmentsQty,
      shipmentsLoaded,
      setShipmentsLoaded,
      // delivered
      deliveredShipments: shipments.delivered.slice(0, deliveredQueryLimit),
      deliveredQueryLimit,
      hasMoreDelivered,
      fetchMoreDelivered,
      noDeliveredShipmentsFound,
      //
      setShipments,
      deletingShipmentsRef,
    }),
    [
      allShipments,
      shipments.overview,
      shipments.delivered,
      filterConfig.currentLimit,
      noShipmentsAdded,
      noShipmentsFound,
      hasMoreShipments,
      shipmentsQty,
      shipmentsLoaded,
      setShipmentsLoaded,
      deliveredQueryLimit,
      hasMoreDelivered,
      fetchMoreDelivered,
      noDeliveredShipmentsFound,
    ],
  );

  return <ShipmentsOverviewContext.Provider value={ctxValue}>{children}</ShipmentsOverviewContext.Provider>;
};

export const useShipmentsOverviewContext = () => useContext(ShipmentsOverviewContext);
