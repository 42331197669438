import styled from 'styled-components';
import { SectionTitle, SectionWrapper } from '@/components';
import { SectionSubTitle } from '../DataSection/DataSection.styles';

export const WordCloudSectionWrapper = styled(SectionWrapper)(({ theme }) => ({
  position: 'relative',
  padding: `${theme.spacing[20]} 0`,
}));

export const WordCloudSectionHeader = styled.div(({ theme }) => ({
  margin: `0 ${theme.spacing[20]}`,
}));

export const WordCloudSectionTitle = styled(SectionTitle)(({ theme }) => ({
  [`${theme.mediaBreakpointUp.md}`]: {
    marginBottom: theme.spacing[18],
  },
}));

export const WordCloudSectionSubText = styled(SectionSubTitle)(({ theme }) => ({
  marginBottom: theme.spacing[16],

  [`${theme.mediaBreakpointUp.md}`]: {
    marginBottom: theme.spacing[24],
  },
}));
