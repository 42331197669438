import { useEffect, useState } from 'react';
import type { IIndividualCarrier } from '@/interfaces';
import { useGlobalState } from '@/hooks';
import { sortCarrierList } from '@/utils';

type UseCarrierList = (initialCarrierList?: Partial<IIndividualCarrier>[]) => {
  allCarrierList: Partial<IIndividualCarrier>[];
  handleLoadMore: () => void;
  truncatedCarrierList: Partial<IIndividualCarrier>[];
  truncatedCarrierListLength: number;
};

const MAX_CARRIER_LIST_COUNTER = 5;

const useCarrierList: UseCarrierList = (initialCarrierList) => {
  if (!initialCarrierList)
    return {
      allCarrierList: [],
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      handleLoadMore: () => {},
      truncatedCarrierList: [],
      truncatedCarrierListLength: 0,
    };

  const { carriers } = useGlobalState();
  const carrierList =
    sortCarrierList({
      allCarriers: (carriers && Object.values(carriers)) || [],
      manuallyAddedCarriers: initialCarrierList,
    }) || [];
  const [currentLength, setCurrentLength] = useState(MAX_CARRIER_LIST_COUNTER);

  const clonedCarrierList = [...carrierList];
  const slicedList = clonedCarrierList.slice(0, currentLength);
  const [truncatedCarrierList, setCarrierList] = useState(slicedList);

  const handleLoadMore = () => {
    setCurrentLength(currentLength + MAX_CARRIER_LIST_COUNTER);
  };

  useEffect(() => {
    setCarrierList(slicedList);
  }, [currentLength]);

  return {
    allCarrierList: carrierList,
    handleLoadMore,
    truncatedCarrierList,
    truncatedCarrierListLength: currentLength,
  };
};

export default useCarrierList;
