import { useEffect, useState } from 'react';
import type { IUserInfo } from '@/interfaces';
import { useGeo, useGlobalState } from '@/hooks';
import { getCountryConfig } from '@/modules/pmt/fetchers';
import type { IEmailSyncAccount } from '@/modules/pmt/interfaces';

const WHITE_LISTED_EMAILS = [
  'bcmjtest@gmail.com',
  'bianca.jimeno@u.nus.edu',
  'cwitte@gmail.com',
  'catta.babay@gmail.com',
  'lamquynhnhu96@gmail.com',
];

const checkIsESAllowed = (userInfo: IUserInfo) => {
  const isPpUser = userInfo?.email?.includes('@parcelperform.com');
  const isWhitelisted = WHITE_LISTED_EMAILS.includes(userInfo?.email);
  return (isPpUser || isWhitelisted) && !userInfo?.emailSyncOptIn;
};

export const useIsEmailSyncAllowed = ({
  emailSyncData,
  hasEmailAccounts,
}: {
  emailSyncData?: IEmailSyncAccount;
  hasEmailAccounts?: boolean;
}) => {
  const { geoInfo } = useGeo();
  const { userInfo, isLoggedIn } = useGlobalState();
  const [isEmailSyncAllowed, setIsEmailSyncAllowed] = useState(false);
  const [isCountryAllowed, setIsCountryAllowed] = useState(false);

  useEffect(() => {
    (async () => {
      if (!geoInfo.fetched) return;
      const countryCode = geoInfo.country;
      const isCountryAllowed = await getCountryConfig({ countryCode });
      setIsCountryAllowed(!!isCountryAllowed);
    })();
  }, [geoInfo.country, geoInfo.fetched]);

  useEffect(() => {
    // 1. If user is not logged in or unverified
    // ==> Show the email sync CTA based on the country config from UMS when
    if (!isLoggedIn) {
      setIsEmailSyncAllowed(!!isCountryAllowed);
      return;
    }

    // 2. If user logged in or PP user or is disconnected email:
    // ==> Show the email sync CTA
    if (!userInfo) return;

    if (!isCountryAllowed) {
      const isEsyncAllowed = checkIsESAllowed(userInfo);
      setIsEmailSyncAllowed(isEsyncAllowed || !!hasEmailAccounts);
      // setIsCountryAllowed(!!hasEmailAccounts);
      return;
    }

    // 3. If total connected account > maximumAccountAllowed
    // => Hide the email sync CTA
    if (!emailSyncData) return;
    setIsEmailSyncAllowed(emailSyncData.total < emailSyncData.maximumAccountAllowed);
  }, [emailSyncData, geoInfo.country, hasEmailAccounts, isCountryAllowed, isLoggedIn, userInfo]);

  return { isEmailSyncAllowed };
};
