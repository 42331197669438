import type { ISingleItem } from '@/interfaces';
import { renderHTML } from '@/utils';
import { ItemWrapper, Description, Title } from './SEOItem.styles';

const SEOItem: React.FC<ISingleItem> = (props) => {
  const { title, description } = props;

  const addTargetBlankToAnchor = (str: string) => {
    return str.replace('href', 'target="_blank" href');
  };

  return (
    <ItemWrapper>
      <Title>{renderHTML(addTargetBlankToAnchor(title || ''), 'span')}</Title>
      <Description>{renderHTML(addTargetBlankToAnchor(description || ''), 'span')}</Description>
    </ItemWrapper>
  );
};

export default SEOItem;
