import styled from 'styled-components';
import { TextStyles } from '@/constants';
import { Typography } from '@/components';
import { styledTextAsObj } from '@/components/Typography/Typography.styles';
import { DefaultText } from '@/components/Tooltip/components/TooltipWrapper/TooltipWrapper.styles';

export const Name = styled(Typography).attrs({
  variant: TextStyles.Overline,
})(({ theme }) => ({
  color: theme.colors.highEmphasisBlack,
}));

export const TextContent = styled(DefaultText)(({ theme }) => ({
  marginTop: theme.spacing[8],
  color: theme.colors.mediumEmphasisBlack,
}));

// TODO-Post-24: Change back to Link after figuring out why Link to 404 in SPA not work
export const LinkContent = styled.a(({ theme }) => ({
  wordBreak: 'break-word',
  display: '-webkit-box',
  overflow: 'hidden',
  WebkitBoxOrient: 'vertical',
  marginTop: theme.spacing[8],
  textDecoration: 'underline',
  color: theme.colors.hyperlink,
  ...styledTextAsObj[TextStyles['Body Text Small']],

  ':hover': {
    color: theme.colors.blue,
  },
}));
