/* eslint-disable no-console */
import getConfig from 'next/config';
import Cookies from 'js-cookie';
import { initializeApp, getApps, type FirebaseApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';
import {
  getFirestore,
  initializeFirestore,
  persistentLocalCache,
  persistentMultipleTabManager,
} from 'firebase/firestore';
import { getAuth, signInAnonymously } from 'firebase/auth';
import { CookieKey, MessageField, ErrorMessages } from '@/constants';

const { publicRuntimeConfig } = getConfig();

const firebaseConfig = {
  apiKey: publicRuntimeConfig.firebaseApiKey,
  authDomain: publicRuntimeConfig.firebaseAuthDomain,
  databaseURL: publicRuntimeConfig.firebaseDatabaseUrl,
  projectId: publicRuntimeConfig.firebaseProjectId,
  storageBucket: publicRuntimeConfig.firebaseStorageBucket,
  messagingSenderId: publicRuntimeConfig.firebaseMessagingSenderId,
  appId: publicRuntimeConfig.firebaseAppId,
};

const firebaseApp = !getApps().length ? initializeApp(firebaseConfig) : undefined;

const getFirebaseLocalCache = () => {
  return initializeFirestore(firebaseApp as FirebaseApp, {
    localCache: persistentLocalCache({
      tabManager: persistentMultipleTabManager(),
    }),
  });
};

export const db = firebaseApp ? getFirebaseLocalCache() : getFirestore(firebaseApp as any);

const performanceInit = async () => {
  if (!firebaseApp) return;

  const { getPerformance } = await import('firebase/performance');
  return getPerformance(firebaseApp);
};

const handleReceiveMessage = (event: MessageEvent) => {
  const browserUuid = event?.data?.data?.browser_uuid;
  const isUmsAccessToken = Cookies.get(CookieKey.UmsAccessToken);
  if (browserUuid && !isUmsAccessToken) {
    Cookies.set(CookieKey.UmsAccessToken, browserUuid, { expires: 365 });
    location.reload();
  }
};

const listenForegroundMessage = () => {
  navigator.serviceWorker.addEventListener('message', handleReceiveMessage);
};

const listenBackgroundMessage = () => {
  const channel = new BroadcastChannel('fcm-background');
  channel.addEventListener('message', handleReceiveMessage);
};

const firebaseMessageInit = () => {
  const isSupported = 'Notification' in window && 'serviceWorker' in navigator && 'PushManager' in window;

  if (!isSupported) {
    console.error(ErrorMessages[MessageField.WEB_PUSH_NOTIFICATION_IS_NOT_SUPPORTED].error);
    return;
  }

  Notification?.requestPermission()
    ?.then((permission) => {
      if (permission === 'granted') {
        const messaging = getMessaging(firebaseApp);
        getToken(messaging, { vapidKey: publicRuntimeConfig.firebaseVapidKey })
          .then((currentToken) => {
            if (currentToken) {
              Cookies.set(CookieKey.FirebaseMessageToken, currentToken, {
                expires: 365,
              });
            } else {
              throw new Error(ErrorMessages[MessageField.FIREBASE_FCM_TOKEN_ERROR].error);
            }
          })
          .catch((err) => {
            console.error(ErrorMessages[MessageField.FIREBASE_FCM_TOKEN_ERROR].error, err);
          });
      }
    })
    .catch((err) => {
      console.error(ErrorMessages[MessageField.NOTIFICATION_PERMISSION_NOT_GRANTED].error, err);
    });

  listenForegroundMessage();
  listenBackgroundMessage();
};

export const firebaseInit = () => {
  if (typeof window === 'undefined') {
    return;
  }

  performanceInit();
  firebaseMessageInit();
};

export const AnonymousSignIn = async () => {
  const auth = getAuth();

  try {
    await signInAnonymously(auth);
  } catch (error) {
    console.error(error);
  }
};
