import { ChartType } from '@/interfaces';
import { poppins } from '@/components/GlobalStyles';
import theme from './theme.constants';

export const LIST_FIELD_NOT_RENDER_TO_LINE = [
  'tradelane',
  'pParcelFromCountryIso',
  'pParcelToCountryIso',
  'cCarrierId',
  'name',
  'logoLink',
  'timeframe',
  'lowerTransitTime',
  'upperTransitTime',
  'isTtQualified',
  // Market table
  'regionOrigin',
  'regionDestination',
  'countryOrigin',
  'countryOriginName',
  'countryDestination',
  'countryDestinationName',
  'tradelaneName',
  'quarter',
  'carrierName',
  'carrierId',
  'carrierLogo',
  'year',
  'unit',
  // Tradelane table
  'carrierHeadquarter',
  'carrierServiceType',
];

export const trendAndBarMockData: {
  [key in ChartType]?: {
    numberValues: number[];
    percentageValues: number[];
  };
} = {
  [ChartType.TrendMetrics]: {
    numberValues: [3.5, 1.9, 3.0, 2.0],
    percentageValues: [90, 40, 75, 45],
  },
  [ChartType.BarMetrics]: {
    numberValues: [299, 499, 200, 399, 560, 180],
    percentageValues: [25, 40, 75, 45, 90, 30],
  },
};

export const trendAndBarHeight: {
  [key in ChartType]?: {
    chartDesktopHeight: number;
    wallDesktopHeight: number;
  };
} = {
  [ChartType.TrendMetrics]: {
    chartDesktopHeight: 336 + 24,
    wallDesktopHeight: 378,
  },
  [ChartType.BarMetrics]: {
    chartDesktopHeight: 439 + 24,
    wallDesktopHeight: 478,
  },
};

export const chartColors = [theme.colors.midnight, '#D2DCE3'];

const font = {
  family: poppins.style.fontFamily,
  size: 14,
};

export const chartJsStyles = {
  responsive: true,
  maintainAspectRatio: false,
  layout: {
    padding: {
      right: 0,
      left: -26,
      top: 42,
      bottom: -12,
    },
  },
  plugins: {
    datalabels: {
      font: {
        ...font,
        weight: 'bold',
      },
      backgroundColor: theme.colors.darkBlue,
      color: theme.colors.highEmphasisWhite,
      borderRadius: 50,
      padding: {
        top: 6,
        bottom: 4,
        right: 8,
        left: 8,
      },
      align: 'end',
      offset: 10, // actual is a 5px gap between label and dot
    },
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
  },
  scales: {
    x: {
      border: {
        color: '#DFDFDF', // hardcode to match the bottom x-axis border-color (original color if rgba)
        display: true,
      },
      grid: {
        // remove vertical grid
        display: false,
      },
      ticks: {
        // x-axis label
        color: theme.colors.inactiveBlack,
        font: {
          ...font,
          lineHeight: 1.5,
        },
        padding: 10, // actual is 16px between bottom-x-border to ticks
      },
    },
    y: {
      suggestedMax: 0,
      border: {
        dash: [5, 6],
        display: false,
      },
      grid: {
        color: theme.colors.focusedBlack,
        drawOnChartArea: true,
        drawTicks: false,
      },
      beginAtZero: true,
      ticks: {
        crossAlign: 'near',
        precision: 1,
        count: 0,
        stepSize: 0,
        color: theme.colors.inactiveBlack,
        font: {
          ...font,
          lineHeight: 1.5,
        },
        padding: 44,
      },
    },
  },
};

function getGradient(ctx: any, chartArea: any) {
  const gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
  gradient.addColorStop(0.8323, 'rgba(235, 239, 242, 1)');
  gradient.addColorStop(0.2964, 'rgba(235, 239, 242, 0.10)');
  return gradient;
}

export const lineChartStyles = {
  backgroundColor: (context: any) => {
    const chart = context.chart;
    const { ctx, chartArea } = chart;

    // This case happens on initial chart load
    if (!chartArea) return;
    return getGradient(ctx, chartArea);
  },
  pointRadius: 5,
  pointHoverRadius: 5,
  pointBorderWidth: 3,
  pointHoverBorderWidth: 3,
  pointBackgroundColor: theme.colors.highEmphasisWhite,
  pointHoverBackgroundColor: theme.colors.highEmphasisWhite,
  fill: {
    target: 'start',
  },
  tension: 0.3,
  borderColor: theme.colors.midnight,
};

export const barChartStyles = {
  borderColor: theme.colors.midnight,
  backgroundColor: theme.colors.midnight,
  borderRadius: 8,
};
