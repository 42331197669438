import { useEffect, useState } from 'react';
import { getShipments } from '@/modules/pmt/fetchers';
import { useGlobalState } from '@/hooks';
import { gtmInvalidParcelSearches } from '@/utils';

const useFetchShipments = () => {
  const { pmtCurrentLanguageCode, firestoreUuid, isLoggedIn } = useGlobalState();
  const [parcelIds, setParcelIds] = useState<string[]>([]);

  useEffect(() => {
    if (!parcelIds.length || !firestoreUuid) return;
    (async () => {
      const searchPromises = parcelIds.map(async (parcelId) => {
        return getShipments({
          uuid: firestoreUuid,
          parcelId,
          language: pmtCurrentLanguageCode,
          isLoggedIn,
        });
      });

      try {
        const statuses = await Promise.all(searchPromises);
        statuses.map((result) => {
          if (result?.status === 400) {
            gtmInvalidParcelSearches();
          }
        });
      } catch (err) {
        console.error(err);
      }
    })();
    setParcelIds([]);
  }, [firestoreUuid, parcelIds, pmtCurrentLanguageCode, isLoggedIn]);

  return { fetchShipments: setParcelIds };
};

export { useFetchShipments };
