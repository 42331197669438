import { type FormEventHandler, useRef } from 'react';
import getConfig from 'next/config';
import { TRACKING_PARAM_PARCEL_ID, ROUTES, RouteName } from '@/constants';

import { SectionTitleSmall } from '@/components';
import {
  ButtonColorKey,
  ButtonSize,
  ButtonVariant,
  IconAlignment,
  type ISectionWrapper,
  SectionWrapperVariant,
} from '@/interfaces';
import { gtmTrackingWidget, setLocalStorageByKey } from '@/utils';

import LocalShipping from '../../public/images/icons/local_shipping.svg';
import { FormButton, FormWrapper, Input, WidgetSectionWrapper } from './TrackingWidget.styles';

const TrackingWidget: React.FC<ISectionWrapper> = (props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { publicRuntimeConfig } = getConfig();
  const protocol = publicRuntimeConfig.webDomain.includes('localhost') ? 'http' : 'https';
  const domain =
    publicRuntimeConfig.contentfulEnvironment === 'master'
      ? publicRuntimeConfig.hostname
      : publicRuntimeConfig.webDomain;

  const { variant } = props;

  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();

    const trackingId = inputRef.current?.value;

    if (!trackingId) {
      return;
    }

    gtmTrackingWidget();
    setLocalStorageByKey(TRACKING_PARAM_PARCEL_ID, trackingId);
    window.location.href = `${protocol}://${domain}${ROUTES[RouteName.TrackMyParcelEn].path}`;
  };

  return (
    <WidgetSectionWrapper variant={variant}>
      <SectionTitleSmall>Track your parcel here</SectionTitleSmall>
      <FormWrapper onSubmit={handleSubmit}>
        <Input
          ref={inputRef}
          name={TRACKING_PARAM_PARCEL_ID}
          autoComplete='off'
          placeholder='Enter tracking ID'
          data-lpignore='true' //remove extension icon
        />
        <FormButton
          type='submit'
          variant={ButtonVariant.Primary}
          color={ButtonColorKey.Yellow}
          icon={LocalShipping}
          size={ButtonSize.Medium}
          mobileSize={ButtonSize.Large}
          alignment={IconAlignment.Left}
        >
          {variant === SectionWrapperVariant.RowDisplay ? 'TRACK PARCEL' : 'TRACK'}
        </FormButton>
      </FormWrapper>
    </WidgetSectionWrapper>
  );
};

export default TrackingWidget;
