import styled, { css } from 'styled-components';
import { DESKTOP_HEADER_HEIGHT, MOBILE_HEADER_HEIGHT, TextStyles } from '@/constants';
import { ButtonVariant } from '@/interfaces';
import { Button, SectionWrapper, Typography } from '@/components';
import ArrowForward from '../../public/images/icons/arrow_forward.svg';

export const HeadingWrapper = styled.div(({ theme }) => ({
  marginBottom: theme.spacing[32],
}));

export const DataRendererSection = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing[32],
}));

export const SectionSubTitle = styled(Typography).attrs({
  variant: TextStyles['Body Text Small'],
})<{ isDesktop: boolean }>`
  word-break: break-word;
  color: ${({ theme }) => theme.colors.mediumEmphasisBlack};
  margin-top: -${({ theme }) => theme.spacing[8]};
  display: ${({ isDesktop }) => (isDesktop ? 'none' : '-webkit-box')};
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 3;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    -webkit-line-clamp: 2;
    display: ${({ isDesktop }) => (isDesktop ? '-webkit-box' : 'none')};
  }
`;

export const SectionButton = styled(Button).attrs({
  variant: ButtonVariant.Link,
  icon: ArrowForward,
})`
  margin-top: ${({ theme }) => theme.spacing[16]};
  text-transform: uppercase;
`;

export const DataSectionWrapper = styled(SectionWrapper)<{
  $benchmarked?: boolean;
}>`
  scroll-margin-top: ${MOBILE_HEADER_HEIGHT}px;
  scroll-snap-margin-top: ${MOBILE_HEADER_HEIGHT}px;

  ${({ $benchmarked }) =>
    $benchmarked &&
    css`
      background-color: #fcf7f5;
    `}

  > h2 {
    margin-bottom: ${({ theme }) => theme.spacing[18]} !important;
  }

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    scroll-margin-top: ${DESKTOP_HEADER_HEIGHT}px;
    scroll-snap-margin-top: ${DESKTOP_HEADER_HEIGHT}px;
  }
`;
