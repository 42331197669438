import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { CookieKey, ON_PAGE_DURATION_BY_SECOND, DEFAULT_POPUP_TIME_TRIGGERS } from '@/constants';
import { OnboardingStepName, type IOnboardingPopup, UserSessionType } from '@/interfaces';
import { useGlobalState } from '@/hooks';
import { gtmOnboardingPopUpDelay } from '@/utils';

// TODO: Separate into multiple hooks/files
const useOnboardingPopUp = ({ popUp }: { popUp?: IOnboardingPopup }) => {
  // STATES
  const {
    isLoggedIn,
    currentStep,
    setCurrentStep,
    isFromJoinUsBtn,
    setIsFromJoinUsBtn,
    isFromBasicDetailsBtn,
    setIsFromBasicDetailsBtn,
    setShowPopUp,
    showPopUp,
    isFromReportsPopup,
    setIsFromReportsPopup,
  } = useGlobalState();
  const [isFullScreen, setIsFullScreen] = useState(false);
  const isIntroScreen = [OnboardingStepName.intro, OnboardingStepName.joinUs].includes(currentStep);

  const [timers, setTimers] = useState(() => {
    if (!isIntroScreen) return [];

    const isReturner = Cookies.get(CookieKey.IsReturner);
    const userType = isReturner ? UserSessionType.Return : UserSessionType.New;
    const timer = isReturner ? popUp?.returnUserTriggerTimes : popUp?.newUserTriggerTimes;
    return timer?.length ? timer : DEFAULT_POPUP_TIME_TRIGGERS[userType];
  });
  const delayTime = timers[0];

  const willShowOnClickPopUp = !!(popUp && currentStep !== OnboardingStepName.finish);

  const willShowTimeBasedPopUp = !!(!showPopUp && !isLoggedIn && timers.length && willShowOnClickPopUp);
  // UTIL FUNCTIONS
  const closePopUp = () => {
    setShowPopUp(false);
    setIsFromJoinUsBtn(false);
    if (!isIntroScreen) {
      return;
    }

    setTimers((prev) => prev.slice(1));
  };

  const removePopUpTimer = () => {
    setTimers([]);
  };

  // USE EFFECT
  useEffect(() => {
    if (Cookies.get(CookieKey.IsReturner)) return;
    const pageTimerValue = Cookies.get(CookieKey.OnPageTimer);
    let onPageTimer = isNaN(pageTimerValue) ? 0 : Number(pageTimerValue);

    const interval = setInterval(() => {
      Cookies.set(CookieKey.OnPageTimer, ++onPageTimer);
      if (onPageTimer >= ON_PAGE_DURATION_BY_SECOND) {
        Cookies.set(CookieKey.IsReturner, true);
        clearInterval(interval);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (isLoggedIn) setTimers([]);
  }, [isLoggedIn]);

  useEffect(() => {
    document.documentElement.style.overflow = showPopUp ? 'hidden' : 'scroll';
  }, [showPopUp]);

  useEffect(() => {
    if (!isFromReportsPopup) return;
    closePopUp();
    setCurrentStep(OnboardingStepName.followUs);
    removePopUpTimer();
    setIsFromReportsPopup(false);
  }, [isFromReportsPopup]);

  useEffect(() => {
    if (!willShowTimeBasedPopUp) return;

    const timer = setTimeout(() => {
      setCurrentStep(OnboardingStepName.joinUs);
      gtmOnboardingPopUpDelay(delayTime);
      setIsFullScreen(false);
      setShowPopUp(true);
    }, delayTime * 1000);

    if (!isIntroScreen) {
      clearTimeout(timer);
    }
    return () => clearTimeout(timer);
  }, [timers.length, isIntroScreen, currentStep, isFromBasicDetailsBtn]);

  useEffect(() => {
    if (willShowOnClickPopUp && isFromJoinUsBtn) {
      setCurrentStep(OnboardingStepName.joinUs);
      setIsFullScreen(true);
      setShowPopUp(true);
    }
  }, [isFromJoinUsBtn]);

  useEffect(() => {
    if (willShowOnClickPopUp && isFromBasicDetailsBtn) {
      setCurrentStep(OnboardingStepName.aboutYou);
      setIsFullScreen(true);
      setShowPopUp(true);
      setIsFromBasicDetailsBtn(false);
    }
  }, [isFromBasicDetailsBtn]);

  return {
    showPopUp,
    popUp,
    closePopUp,
    removePopUpTimer,
    isFullScreen,
    delayTime,
  };
};

export default useOnboardingPopUp;
