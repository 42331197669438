import styled, { css } from 'styled-components';
import { LeadGenBannerVariant, SectionThemeKey } from '@/interfaces';
import { styledText } from '@/components/Typography/Typography.styles';
import { theme } from '@/constants';

const clampText = `
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-break: break-word;
`;

export const LeadGenTitle = styled.div<{ sectionTheme: SectionThemeKey }>`
  ${styledText['Heading 2']}
  margin-bottom: 4px;
  color: ${({ sectionTheme, theme }) => {
    return sectionTheme === SectionThemeKey.Light ? theme.colors.highEmphasisBlack : theme.colors.highEmphasisWhite;
  }};

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    display: none;
  }
`;

export const DesktopLeadGenTitle = styled(LeadGenTitle)<{
  variant: LeadGenBannerVariant;
}>`
  ${({ theme }) => theme.mediaBreakpointUp.md} {
    display: block;
    margin-bottom: 8px;
    ${({ variant }) => variant === LeadGenBannerVariant.SMALL && styledText['Heading 5']}
  }
`;

export const LeadGenSubCopy = styled.div<{
  sectionTheme: SectionThemeKey;
  hasButtons?: boolean;
}>`
  ${styledText['Body Text Large']}
  margin-bottom: 18px;
  color: ${({ sectionTheme, theme }) => {
    return sectionTheme === SectionThemeKey.Light ? theme.colors.highEmphasisBlack : theme.colors.highEmphasisWhite;
  }};

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    display: none;
  }

  ${({ hasButtons }) => !hasButtons && 'margin-bottom: 0 !important;'}
`;

export const DesktopLeadGenSubCopy = styled(LeadGenSubCopy)<{
  variant: LeadGenBannerVariant;
}>`
  ${({ theme }) => theme.mediaBreakpointUp.md} {
    display: block;
    ${({ variant }) => variant === LeadGenBannerVariant.SMALL && styledText['Body Text Small']}
  }
`;

export const LeadGenButtonWrapper = styled.div`
  display: flex;
  align-items: center;

  > *:not(:last-child) {
    margin-right: 15px;
  }
`;

export const LeadGenContent = styled.div`
  position: relative;
  max-width: 305px;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    max-width: 425px;
  }
`;

const SmallStyles = css`
  ${DesktopLeadGenTitle}, ${DesktopLeadGenSubCopy} {
    ${clampText}
    -webkit-line-clamp: 2;
  }

  ${LeadGenTitle} {
    ${styledText['Heading 3']}
  }

  ${LeadGenSubCopy} {
    ${styledText['Body Text Small']}
  }
`;

const FullWidthStyles = css`
  display: flex;
  align-items: center;
  padding-top: 62px !important;
  padding-bottom: 62px !important;
  min-height: 462px;

  ${theme.mediaBreakpointUp.md} {
    padding-top: 64px !important;
    padding-bottom: 64px !important;
    min-height: 324px;
  }

  border-radius: 0 !important;
  box-shadow: none !important;

  ${LeadGenContent} {
    max-width: unset;
    padding: 0 ${theme.spacing[20]};

    ${({ theme }) => theme.mediaBreakpointUp.md} {
      max-width: 736px;
      padding: unset;
    }
  }

  ${LeadGenTitle} {
    ${clampText}
    -webkit-line-clamp: 2;
    ${styledText['Large Text Display']}
    margin-bottom: ${theme.spacing[16]};
  }

  ${LeadGenSubCopy} {
    ${clampText}
    -webkit-line-clamp: 5;
    ${styledText['Subheading']}
    margin-bottom: ${theme.spacing[24]};
  }

  ${DesktopLeadGenTitle} {
    ${clampText}
    -webkit-line-clamp: 1;
  }

  ${DesktopLeadGenSubCopy} {
    ${clampText}
    -webkit-line-clamp: 2;
  }
`;

export const LeadGenBannerWrapper = styled.div<{
  isLinkedIn?: boolean;
  variant?: LeadGenBannerVariant;
}>`
  width: 100%;
  border-radius: 8px;
  padding: 16px;
  position: relative;
  box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.04),
    0px 2px 4px rgba(96, 97, 112, 0.16);

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    padding: 20px;
  }

  ${({ isLinkedIn, theme }) =>
    isLinkedIn &&
    `
    box-shadow: none;
    border-radius: 0;

    ${theme.mediaBreakpointUp.md} {
      max-width: 352px;
      padding: 16px;
      box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16);
      border-radius: 8px;
    }

    ${LeadGenTitle} {
      ${styledText['Heading 3']}
      margin-bottom: 8px;

      ${theme.mediaBreakpointUp.md} {
        margin-bottom: 4px;
      }
    }

    ${LeadGenSubCopy} {
      ${styledText['Body Text Small']}
      margin-bottom: 12px;
    }

    ${LeadGenContent} {
      ${theme.mediaBreakpointUp.md} {
        min-height: 90px;
      }
    }
  `}

  ${({ variant }) => variant === LeadGenBannerVariant.SMALL && SmallStyles}
  ${({ variant }) => variant === LeadGenBannerVariant.FULL_WIDTH && FullWidthStyles}
`;

export const Iframe = styled.iframe`
  vertical-align: bottom;
  height: 22px;
  border: 0;
  background-color: transparent;
`;
