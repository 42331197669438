import type { NextApiResponse } from 'next';
import getConfig from 'next/config';
import { ErrorPageCodes } from '@/constants';

const { publicRuntimeConfig } = getConfig();

export const apiErrorPageReturn = async (res: NextApiResponse, errorCode = ErrorPageCodes.ERROR_404) => {
  const protocol = publicRuntimeConfig.webDomain.includes('localhost') ? 'http' : 'https';
  const domain =
    publicRuntimeConfig.contentfulEnvironment === 'master'
      ? publicRuntimeConfig.hostname
      : publicRuntimeConfig.webDomain;
  const fetchRes = await fetch(`${protocol}://${domain}/${errorCode}`);
  const notFoundPage = await fetchRes.text();
  const notFoundPageWithBodyTag = notFoundPage.replace(/<body(.*?)>/g, '<body$1 style="margin: 0">');

  res.setHeader('Content-Type', 'text/html');
  return res.status(404).send(notFoundPageWithBodyTag);
};
