import type { FC } from 'react';
import Link from 'next/link';
import { theme } from '@/constants';
import { getCountryName } from '@/utils';
import { CountryFlag, CountryCode, CountryCodeWrapper } from './CountryOption.styles';

const CountryOption: FC<{
  showName?: boolean;
  onlyFlag?: boolean;
  code: string;
  flagSize?: number;
  href?: string;
}> = ({ showName, onlyFlag, code, flagSize, href }) => {
  const countryName = getCountryName(code);

  const renderText = () => {
    if (onlyFlag) return null;

    if (href) {
      return (
        <Link href={href}>
          <CountryCode>{code}</CountryCode>
        </Link>
      );
    }

    return showName ? countryName : <CountryCode>{code}</CountryCode>;
  };

  return (
    <CountryCodeWrapper align='center'>
      <CountryFlag style={{ fontSize: theme.fontSizes[flagSize || 30] }} countryCode={code} alt={countryName} />
      {renderText()}
    </CountryCodeWrapper>
  );
};

export default CountryOption;
