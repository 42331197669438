import type { IShipment } from '@/modules/pmt/interfaces';
import { MasterTranslation } from '@/constants';
import { useGlobalState } from '@/hooks';

export const useShipmentInfo = () => {
  const { t } = useGlobalState();

  const getShipmentLabel = (shipment: IShipment | undefined) => {
    if (!shipment?.data) return '';
    const { additionalInfo, lineItems, orderTracking, parcel } = shipment.data;

    if (additionalInfo?.label) return additionalInfo.label;

    if (lineItems?.length) return lineItems[0].productName;

    if (orderTracking?.orderId) return `${t(MasterTranslation.ParcelCardOrderIdText)} ${orderTracking.orderId}`;

    if (parcel?.parcelId) return `${t(MasterTranslation.ParcelCardTrackingIdText)} ${parcel.parcelId}`;

    return '';
  };

  return { getShipmentLabel };
};
