import { createContext, useContext, useMemo, useRef } from 'react';
import { useSearchShipments } from '@/modules/pmt/hooks';
import type { IPmtOnboardingPopup } from '@/interfaces/tracking';
import type { ParcelSearchesSource } from '@/constants';
import type { IPmtPage, IMainTranslation, ITrackingTranslation, INotificationTranslation } from '@/modules/pmt/interfaces';

interface IMyParcelsProps {
  pmtPageData: IPmtPage | null;
  mainTranslation: IMainTranslation;
  trackingTranslation: ITrackingTranslation;
  notificationTranslation: INotificationTranslation;
  pmtOnboardingPopUp: IPmtOnboardingPopup;
  pmtEmailSyncOnboardingPopUp: IPmtOnboardingPopup;
}

const defaultProps: IMyParcelsProps = {
  pmtPageData: null,
  mainTranslation: {},
  trackingTranslation: {},
  notificationTranslation: {},
  pmtOnboardingPopUp: {},
  pmtEmailSyncOnboardingPopUp: {},
};

interface IMyParcelsState {
  inputParcelList: (parcelIdList: string[], source: ParcelSearchesSource) => void;
  focusFirstShipmentRef: React.MutableRefObject<boolean>;
}

const initialState: IMyParcelsState = {
  inputParcelList: () => null,
  focusFirstShipmentRef: { current: false },
};

type IMyParcelsContext = IMyParcelsProps & IMyParcelsState;

const MyParcelsContext = createContext<IMyParcelsContext>({
  ...defaultProps,
  ...initialState,
});

export const MyParcelsProvider: React.FC<{
  pmtProps: IMyParcelsProps;
}> = ({ children, pmtProps }) => {
  const focusFirstShipmentRef = useRef(false);

  const { inputParcelList } = useSearchShipments();

  const ctxValue: IMyParcelsContext = useMemo(
    () => ({
      ...pmtProps,
      inputParcelList,
      focusFirstShipmentRef,
    }),
    [inputParcelList, pmtProps],
  );

  return <MyParcelsContext.Provider value={ctxValue}>{children}</MyParcelsContext.Provider>;
};

export const useMyParcelsContext = () => useContext(MyParcelsContext);
