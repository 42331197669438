import type React from 'react';
import { useEffect } from 'react';
import getConfig from 'next/config';
import { AdsenseSectionWrapper } from './Adsense.styles';
import { ErrorMessages, MessageField } from '@/constants';

const { publicRuntimeConfig } = getConfig();

export interface AdsenseProps {
  id?: string;
  className?: string;
  dataAdSlot: string;
}

const Adsense: React.FC<AdsenseProps> = ({ id, className, dataAdSlot }) => {
  const loadAds = () => {
    try {
      if (typeof window !== 'undefined') {
        ((window as any).adsbygoogle = (window as any).adsbygoogle || []).push({});
      }
    } catch (error: any) {
      // eslint-disable-next-line no-console
      console.log(ErrorMessages[MessageField.ADSENSE_ERROR], error.message);
    }
  };

  useEffect(() => {
    loadAds();
  }, []);

  return (
    <AdsenseSectionWrapper id={id} className={className}>
      <ins
        className='adsbygoogle'
        data-ad-client={publicRuntimeConfig.googleAdsenseClient}
        data-ad-slot={dataAdSlot}
        style={{ display: 'block' }}
      />
    </AdsenseSectionWrapper>
  );
};

export default Adsense;
