import { WordCloudMapping } from '@/constants';
import { type IWordCloud, WordCloudLayout } from '@/interfaces';
import {
  WordCloudSectionTitle,
  WordCloudSectionHeader,
  WordCloudSectionSubText,
  WordCloudSectionWrapper,
} from './WordCloud.styles';

const WordCloud: React.FC<IWordCloud> = ({ title, layout, subText, buttonLink, wordBubbles }) => {
  const props = { wordBubbles, buttonLink };

  const renderLayout = () => {
    const WordCloudLayoutComponent = WordCloudMapping[layout || WordCloudLayout.Slider];
    return <WordCloudLayoutComponent {...props} />;
  };

  const renderSectionSubText = (subText?: IWordCloud['subText'], isDesktop = false) => {
    if (!subText) return null;

    return <WordCloudSectionSubText isDesktop={isDesktop}>{subText}</WordCloudSectionSubText>;
  };

  return (
    <WordCloudSectionWrapper>
      <WordCloudSectionHeader>
        <WordCloudSectionTitle>{title}</WordCloudSectionTitle>
        {renderSectionSubText(subText)}
        {renderSectionSubText(subText, true)}
      </WordCloudSectionHeader>
      {renderLayout()}
    </WordCloudSectionWrapper>
  );
};

export default WordCloud;
