import { useCallback, useEffect, useMemo, useState } from 'react';

import { FALLBACK_PMT_LANGUAGE_CODE } from '@/constants';

import type { LanguageCode } from '@/interfaces';

import { getPmtTranslations } from '@/fetchers';

import { getMasterTranslationLanguageCode } from '@/utils';

type UseTranslation = (languageCode: LanguageCode) => (key: string) => string;

const INITIAL_DEFAULT_TRANSLATIONS = {};
const INITIAL_TRANSLATIONS = {};

const useTranslations: UseTranslation = (languageCode) => {
  const [defaultTranslations, setDefaultTranslations] = useState<Record<string, string>>(INITIAL_DEFAULT_TRANSLATIONS);
  const [translations, setTranslations] = useState<Record<string, string>>(INITIAL_TRANSLATIONS);

  const isLanguageTheSameAsFallback = useMemo(() => languageCode === FALLBACK_PMT_LANGUAGE_CODE, [languageCode]);

  const isTranslationsFetched = useMemo(() => {
    const isCurrentTranslationsFetched = translations !== INITIAL_TRANSLATIONS;

    if (isLanguageTheSameAsFallback) return isCurrentTranslationsFetched;

    return defaultTranslations !== INITIAL_DEFAULT_TRANSLATIONS && isCurrentTranslationsFetched;
  }, [isLanguageTheSameAsFallback, defaultTranslations, translations]);

  const t = useCallback(
    (key: string) => {
      if (!isTranslationsFetched) return '';

      return translations[key] || defaultTranslations[key] || key;
    },
    [defaultTranslations, isTranslationsFetched, translations],
  );

  useEffect(() => {
    if (isLanguageTheSameAsFallback) return;

    getPmtTranslations(FALLBACK_PMT_LANGUAGE_CODE).then(setDefaultTranslations);
  }, [isLanguageTheSameAsFallback]);

  useEffect(() => {
    const modifiedLanguageCode = getMasterTranslationLanguageCode(languageCode);
    getPmtTranslations(modifiedLanguageCode).then(setTranslations);
  }, [languageCode]);

  return t;
};

export default useTranslations;
